import { apiEndpointsV2 } from '@/config/api/endpoints.v2'
import type { RentalContractRepository } from '@/modules/rentalContract/domain/rentalContractRepository'
import { fromDto, type RentalContractFileDto } from '@/modules/rentalContract/infrastructure/apiDto'
import type { ApiService } from '@/modules/shared/domain/api/apiService'
import type { Uuid } from '@/modules/shared/domain/uuid/uuid'

export function apiRentalContractRepositoryBuilder(apiService: ApiService): RentalContractRepository {
  return {
    async getByPropertyId(propertyId: Uuid) {
      try {
        const { id } = await apiService.get<{ id: Uuid }>(
          apiEndpointsV2.landlords().properties().rentalContract(propertyId).find()
        )
        const fileDtos = await apiService.getList<RentalContractFileDto>(
          apiEndpointsV2.landlords().properties().rentalContract(propertyId).files(id).list()
        )
        return fromDto(id, fileDtos)
      } catch {
        return null
      }
    }
  }
}
