<template>
  <div
    class="commercialization"
    :class="{ selected: hasCommercialization, disabled }"
    :data-test-id="`commercializationSelector`"
  >
    <div>
      <h3>{{ t('property.payment.commercialization.name') }}</h3>
      <p class="description">{{ t('property.payment.commercialization.description') }}</p>
      <div class="price">
        {{ t('property.payment.commercialization.price') }}
        <span>{{ t('property.payment.plusIva') }}</span>
      </div>
      <Button v-if="hasCommercialization" class="mt-2" :disabled="disabled" @click="emit('unselect')">
        {{ t('property.payment.unselect') }}
      </Button>
      <Button v-else class="mt-2" :disabled="disabled" @click="emit('select')">
        {{ t('property.payment.select') }}
      </Button>
    </div>
    <div class="details" v-if="opened">
      <div v-for="feature in features">
        <img :src="icons.checkGreen" />
        <p>{{ t(`property.payment.features.${feature}`) }}</p>
      </div>
    </div>
    <div class="more-details" @click="emit('toggle')">
      <span v-if="opened">{{ t('property.payment.lessDetails') }}</span>
      <span v-else>{{ t('property.payment.moreDetails') }}</span>
      <img v-if="opened" :src="icons.chevronUp" />
      <img v-else :src="icons.chevronDown" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { Button } from '@/components'
import { icons } from '@/config/assets/icons'
import { servicesPricingPlanFeatures } from '@/modules/property/domain/propertyPaymentInformation'
import { useI18n } from 'vue-i18n'

defineProps<{
  hasCommercialization: Nullable<boolean>
  opened: boolean
  disabled: boolean
}>()

const emit = defineEmits(['select', 'toggle', 'unselect'])

const { t } = useI18n()

const features = servicesPricingPlanFeatures
</script>
<style lang="sass" scoped>
@keyframes appear
  from
    opacity: 0
    transform: scale(0.95)
  to
    opacity: 1
    transform: scale(1)

.commercialization
  position: relative
  z-index: 1
  padding: 2rem 1.25rem
  padding-bottom: 1.25rem
  border-radius: 1.25rem
  border: 1px solid #F0F0F0
  background-color: white

  &:not(.disabled):hover
    border-color: #0B0B0B

  .description
    font-size: 1rem
    margin-top: 0.5rem

  .price
    font-size: 1.125rem
    font-weight: 500
    margin-top: 1.5rem

    span
      font-size: 0.75rem
      font-weight: 400

  .details
    margin-top: 2rem
    animation: appear 0.25s ease

    > div
      display: flex
      gap: 1rem
      align-items: center

      p
        font-size: 1rem

  .more-details
    margin-top: 1rem
    padding-top: 1.5rem
    cursor: pointer
    text-align: center
    font-size: 0.875rem
    border-top: 1px solid #E6E6E6

    span
      margin-right: 0.5rem

    img
      transform: translateY(-1px)

    &:hover
      text-decoration: underline

  &.disabled
    h3, .description, .price
      color: #9F9F9F

  &.selected
    background-color: #F6F3A0
    border: 1px solid #0B0B0B

    .check::after
      content: ''
      width: 0.6rem
      height: 0.6rem
      border-radius: 50%
      background-color: #0B0B0B
      display: block
      position: relative
      top: 0.2rem
      left: 0.2rem

    .title, .more-details
      border-color: #0B0B0B
</style>
