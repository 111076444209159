import { findCurrentUser } from '@/modules/user/application'
import { Properties } from '@/views'
import type { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'

export const propertiesRoutes: RouteRecordRaw = {
  path: '/properties',
  name: 'properties',
  component: Properties,
  beforeEnter: propertiesGuard,
  meta: { landlord: true, zapierChatbot: true }
}

export async function propertiesGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const user = await findCurrentUser()
  return !user.name ? next({ name: 'personalInformation' }) : next()
}
