<template>
  <transition
    appear
    mode="out-in"
    name="content"
    @after-leave="() => (data.secondEnter = true)"
    @after-enter="finishLoading()"
  >
    <tbody v-if="props.loading" v-bind="attrs">
      <tr class="row">
        <transition-group
          data-test-id="table-loader_transition"
          appear
          class="content-loader"
          :css="false"
          tag="td"
          colspan="100%"
          @enter="enter"
        >
          <content-loader
            v-for="index in 3"
            :key="index"
            :data-index="index"
            :speed="2"
            viewBox="0 0 1000 50"
            primary-color="#EDEDED"
            secondary-color="#FFFFFF"
          >
            <rect x="20" y="20" width="121" height="14" />
            <rect x="180" y="20" width="299" height="14" />
            <rect x="520" y="20" width="141" height="14" />
            <rect x="700" y="20" width="150" height="14" />
            <circle cx="899" cy="27" r="10" />
            <circle cx="964" cy="27" r="10" />
            <rect x="0" y="64" width="1000" height="1" />
          </content-loader>
        </transition-group>
      </tr>
    </tbody>

    <tbody v-else v-bind="attrs">
      <slot />
    </tbody>
  </transition>
</template>

<script setup lang="ts">
import { reactive, useAttrs } from 'vue'
import { ContentLoader } from 'vue-content-loader'

export interface TableLoaderProps {
  loading?: boolean
}

export type TableLoaderEmits = (event: 'loading-finish') => void

const emit = defineEmits<TableLoaderEmits>()
const attrs = useAttrs()
const props = withDefaults(defineProps<TableLoaderProps>(), {
  loading: false
})

const data = reactive({
  secondEnter: false
})

function finishLoading() {
  data.secondEnter && emit('loading-finish')
  data.secondEnter = false
}

function enter(element: Element) {
  const delay = Number(element.getAttribute('data-index')) * 180
  setTimeout(() => {
    element.classList.add('appear')
  }, delay)
}
</script>

<style scoped lang="sass">
.content-leave-to,
.content-enter-from
  opacity: 0

.content-leave-active,
.content-enter-active
  transition: all 350ms ease-in

.content-loader svg
  transition: all 0.15s ease-in
  transform: translate(0, 8px)
  opacity: 0

  &.appear
    opacity: 1
    transform: translate(0, 0)

.row,
.content-loader
  display: block
  width: 100%
  padding: 0
</style>
