import { apiEndpointsV1 as v1 } from '@/config/api/endpoints.v1'
import { apiEndpointsV2 as v2 } from '@/config/api/endpoints.v2'
import type { ApiService } from '@/modules/shared/domain/api/apiService'
import type { Storage } from '@/modules/shared/domain/storage/storage'
import { asyncForEach } from '@/utils/array'
import type { Landlord } from '../domain/landlord'
import type { LandlordRepository } from '../domain/landlordRepository'
import type { LandlordInvoice, LandlordLiquidation, LandlordConcept } from '../domain/landlordBillingDocuments'
import {
  fromDto,
  fromLiquidationDtoToLandlordLiquidation,
  fromLiquidationDtoToLandlordInvoice,
  fromLiquidationConceptDtoToLandlordLiquidationConcept,
  type LandlordApiDto,
  type LandlordLiquidationDto,
  type LandlordLiquidationConceptDto
} from './apiDto'
import type { DatabaseService } from '@/modules/shared/domain/database/apiService'
import type { Property } from '@/modules/property/domain/property'

export function apiLandlordRepositoryBuilder(
  apiService: ApiService,
  apiV2Service: ApiService,
  dateStorage: Storage<Date>,
  basicPlanVisibilityStorage: Storage<boolean>,
  databaseService: DatabaseService
): LandlordRepository {
  let cache: Nullable<Landlord> = null
  let cacheId: Nullable<string> = null

  return {
    async deleteDocument(userId: string, documentId: string) {
      cache = cacheId = null
      await apiService.delete(v1.landlords().documents(userId).delete(documentId))
    },
    async get(userId: string) {
      if (userId === cacheId && cache !== null) {
        return cache
      }

      const dto = await apiService.get<LandlordApiDto>(v1.landlords().find(userId))
      cache = fromDto(dto, dateStorage.get())
      cacheId = userId
      return cache
    },
    async update(landlord: Landlord) {
      cache = cacheId = null

      dateStorage.set(landlord.acceptedTermsAndConditionsAt)

      const filesToUpload = landlord.documents.id
        .filter(({ source }) => source !== null)
        .map(({ source }) => source as File)

      await asyncForEach(filesToUpload, async (file) =>
        apiV2Service.upload(v2.landlords().documents().upload('personal-id'), file)
      )
    },
    async getBasicPlanVisibility() {
      return Boolean(basicPlanVisibilityStorage.get())
    },
    async setBasicPlanVisibility(visible: boolean) {
      basicPlanVisibilityStorage.set(visible)
    },
    async getBillingDocumentConcepts(liquidationId: string): Promise<LandlordConcept[]> {
      const results = await databaseService.select<LandlordLiquidationConceptDto>(
        'landlord_liquidation_concept',
        'liquidation_id',
        liquidationId
      )
      return results ? fromLiquidationConceptDtoToLandlordLiquidationConcept(results) : []
    },
    async getBillingDocuments(property: Property): Promise<(LandlordInvoice | LandlordLiquidation)[]> {
      const results = await databaseService.select<LandlordLiquidationDto>(
        'landlord_liquidation',
        'property_id',
        property.id
      )
      return (
        results?.flatMap((liquidation) => [
          fromLiquidationDtoToLandlordInvoice(liquidation, property),
          fromLiquidationDtoToLandlordLiquidation(liquidation, property)
        ]) || []
      )
    }
  }
}
