<template>
  <PartnerReferralCreationModal v-if="showCreationModal" @close="showCreationModal = false" @updated="loadReferrals" />
  <div class="container">
    <div class="top flex justify-between align-center align-end gap-05 mb-2">
      <h1>{{ t('partnerReferral.title') }}</h1>
      <Button @click="handleCreateReferral">{{ t('partnerReferral.callToAction.action') }}</Button>
    </div>
    <hr />
    <div class="flex justify-between wrap gap-1 mt-1" v-if="referrals.length > 0">
      <div class="flex">
        <p class="label">{{ referrals.length }}</p>
        &nbsp;
        <p class="light">{{ t('partnerReferral.referrals', referrals.length) }}</p>
      </div>
      <div class="flex">
        <p class="label">{{ t('partnerReferral.sortedBy') }}</p>
        &nbsp;
        <p class="light">{{ t('partnerReferral.sentDate') }}</p>
      </div>
    </div>
    <div class="card mt-1">
      <div class="header-table">
        <h3>{{ t('partnerReferral.list.title') }}</h3>
        <p class="light">{{ t('partnerReferral.list.description') }}</p>
      </div>
      <div class="has-overflow-auto">
        <Table :loading="loading" :options="{ columns, emptyState }">
          <TableRow v-for="referral in referrals">
            <TableRowItem>
              <p>{{ formatDate(referral.createdAt) }}</p>
            </TableRowItem>
            <TableRowItem>
              <p>{{ referral.name }} {{ referral.surname }}</p>
            </TableRowItem>
            <TableRowItem>
              <p>{{ referral.email }}</p>
            </TableRowItem>
            <TableRowItem>
              <p>{{ referral.phone }}</p>
            </TableRowItem>
            <TableRowItem>
              <div class="flex align-center gap-05">
                <div class="badge" :class="referral.status">
                  {{ t(`partnerReferral.status.${referral.status}`) }}
                </div>
                <Tooltip position="left" width="300px" v-if="referral.koReason">
                  <template #handle>
                    <img :src="icons.help" />
                  </template>
                  <template #content>
                    <p>{{ referral.koReason }}</p>
                  </template>
                </Tooltip>
              </div>
            </TableRowItem>
          </TableRow>
        </Table>
      </div>
    </div>
  </div>
  <PartnerReferralCallToActionMobile @updated="loadReferrals" />
</template>
<script lang="ts" setup>
import {
  Button,
  PartnerReferralCallToActionMobile,
  PartnerReferralCreationModal,
  Table,
  TableRow,
  TableRowItem,
  Tooltip
} from '@/components'
import { icons } from '@/config/assets/icons'
import { useDates } from '@/hooks/useDates'
import { findAllPartnerReferrals } from '@/modules/partnerReferral/application'
import type { PartnerReferral } from '@/modules/partnerReferral/domain/partnerReferral'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { useAnalytics } from '@/hooks/useAnalytics'

const { t } = useI18n()
const { formatDate } = useDates()
const { track } = useAnalytics()

const referrals = ref<PartnerReferral[]>([])
const loading = ref(true)
const showCreationModal = ref(false)
const columns = computed(() => [
  {
    name: t('partnerReferral.list.columns.createdAt'),
    width: 120
  },
  {
    name: t('partnerReferral.list.columns.landlord'),
    width: 400
  },
  {
    name: t('partnerReferral.list.columns.email'),
    minWidth: 300
  },
  {
    name: t('partnerReferral.list.columns.phone'),
    width: 200
  },
  {
    name: t('partnerReferral.list.columns.status'),
    width: 140
  }
])

const emptyState = computed(() => ({
  icon: icons.lead,
  title: t('partnerReferral.list.empty.title'),
  description: t('partnerReferral.list.empty.description')
}))

async function loadReferrals() {
  loading.value = true
  referrals.value = await findAllPartnerReferrals()
  loading.value = false
}

async function handleCreateReferral() {
  showCreationModal.value = true
  track(AnalyticsEvent.PartnerLeadsCreateLeadButton)
}

onMounted(() => {
  loadReferrals()
  track(AnalyticsEvent.PartnerLeadsLeadsList)
})
</script>
<style lang="sass" scoped>
.top .button
  padding: 0.75rem 2rem

.card
  padding: 0

.header-table
  padding: 1.25rem

.has-overflow-auto
  overflow: auto

.badge
  font-size: 0.75rem
  font-weight: 500
  padding: 0.5rem
  border-radius: 2rem

  &.activated
    background-color: $pink400
  &.contacted
    background-color: $blue100
  &.discarded
    background-color: $orange300
  &.sent
    background-color: $yellow500
</style>
