<template>
  <div class="container">
    <h1 class="mb-2">{{ t('landlordBilling.title') }}</h1>
    <hr />

    <div class="card p-0 mt-2">
      <div class="header-table">
        <div>
          <h4>{{ t('landlordBilling.invoice.title') }}</h4>
          <p class="light">{{ t('landlordBilling.invoice.subtitle') }}</p>
        </div>
        <div>
          <Tooltip position="left" width="300px">
            <template #handle>
              <img :src="icons.help" height="24" class="tooltip" />
            </template>
            <template #content>
              <p>
                <b>{{ t('landlordBilling.tooltip.title') }}</b>
              </p>
              <p>{{ t('landlordBilling.tooltip.liquidation') }}</p>
              <p class="mt-1">{{ t('landlordBilling.tooltip.invoice') }}</p>
            </template>
          </Tooltip>
        </div>
      </div>

      <div class="has-overflow-auto">
        <Table :loading="loading" :options="data.options">
          <TableRow v-for="(document, key) in data.documents" :key="key">
            <TableRowItem>
              <p>{{ document.issuedDate }}</p>
            </TableRowItem>
            <TableRowItem>
              <p>{{ document.propertyAddress }}</p>
            </TableRowItem>
            <TableRowItem>
              <div class="pill" :role="document.type">
                <span>{{ getTypeLabel(document.type) }}</span>
              </div>
            </TableRowItem>
            <TableRowItem>
              <p>{{ toLocaleCurrencySymbol(document.finalAmount, locale) }}</p>
            </TableRowItem>
            <TableRowItem>
              <div v-if="hasNullProperty(document)">
                <p class="light">{{ t('landlordBilling.table.documentNotAvailable') }}</p>
              </div>
              <div
                v-else
                class="flex align-center gap-05 pointer has-text-underline-hover"
                @click="handlePreviewPDF(document)"
              >
                <img :src="images.iconPdf" height="24" />
                <p>{{ document.fileName }}</p>
              </div>
            </TableRowItem>
            <TableRowItem>
              <div v-if="!hasNullProperty(document)" class="flex align-center gap-05">
                <IconButton :icon="icons.view" @click="handlePreviewPDF(document)" small class="pointer" />
                <IconButton :icon="icons.cloudDownload" @click="handleDownloadPDF(document)" small class="pointer" />
              </div>
            </TableRowItem>
          </TableRow>
        </Table>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, reactive, onMounted, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { Table, TableRow, TableRowItem, IconButton, Tooltip } from '@/components'
import { icons } from '@/config/assets/icons'
import { images } from '@/config/assets/images'
import { previewPDF, downloadPDF } from '@/utils/billing/pdfLibUtils'
import { findAllProperties } from '@/modules/property/application'
import { findBillingDocuments, findBillingDocumentConcepts } from '@/modules/landlord/application'
import type { Property } from '@/modules/property/domain/property'
import type { LandlordLiquidation, LandlordInvoice } from '@/modules/landlord/domain/landlordBillingDocuments'
import { toLocaleCurrencySymbol } from '@/utils/numbers'
import { hasNullProperty } from '@/utils/objects'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { useAnalytics } from '@/hooks/useAnalytics'

const { t, locale } = useI18n()
const { track } = useAnalytics()
const propertiesCompleted = ref<Property[]>([])
const loading = ref(true)

interface DataType {
  documents: (LandlordInvoice | LandlordLiquidation)[]
  options: (typeof Table)['options']
}

const columns = computed(() => [
  {
    key: 'date',
    name: t('landlordBilling.table.columns.date'),
    width: 150
  },
  {
    key: 'property',
    name: t('landlordBilling.table.columns.property'),
    minWidth: 300
  },
  {
    key: 'type',
    name: t('landlordBilling.table.columns.type'),
    width: 120
  },
  {
    key: 'amount',
    name: t('landlordBilling.table.columns.amount'),
    width: 150
  },
  {
    key: 'document',
    name: t('landlordBilling.table.columns.document'),
    minWidth: 150
  },
  {
    key: 'action',
    name: '',
    align: 'right',
    width: 120
  }
])

const emptyState = computed(() => ({
  icon: icons.billing,
  title: t('landlordBilling.table.emptyState.title'),
  description: t('landlordBilling.table.emptyState.description')
}))

const data: DataType = reactive({
  documents: [],
  options: {
    get columns() {
      return columns.value
    },
    get emptyState() {
      return emptyState.value
    }
  }
})

function getTypeLabel(key: 'invoice' | 'liquidation') {
  const labels = {
    liquidation: t('landlordBilling.type.liquidation'),
    invoice: t('landlordBilling.type.invoice')
  }

  return labels[key] || null
}

async function handlePreviewPDF(document: LandlordInvoice | LandlordLiquidation) {
  const concepts = await findBillingDocumentConcepts(document.liquidationId)
  previewPDF(document, concepts)
}

async function handleDownloadPDF(document: LandlordInvoice | LandlordLiquidation) {
  const concepts = await findBillingDocumentConcepts(document.liquidationId)
  downloadPDF(document, concepts)

  if (document.type === 'invoice') {
    track(AnalyticsEvent.LandlordBillingInvoicesListDownloadInvoice)
  } else {
    track(AnalyticsEvent.LandlordBillingInvoicesListDownloadLiquidation)
  }
}

onMounted(async () => {
  data.documents = []
  const properties = await findAllProperties()
  propertiesCompleted.value = properties.filter((property) => property.completed)
  for (const property of propertiesCompleted.value) {
    const propertyDocuments = await findBillingDocuments(property)
    data.documents = [...data.documents, ...propertyDocuments]
  }
  loading.value = false
  track(AnalyticsEvent.LandlordBillingInvoicesList)
})
</script>

<style scoped lang="sass">
.header-table
  display: grid
  grid-template-columns: auto min-content
  gap: 1rem
  align-items: center
  padding: 1.25rem 1.5rem

  .tooltip
    opacity: 0.3

.card.p-0
  padding: 0 !important

.has-overflow-auto
  overflow: auto

.pill
  display: flex
  align-items: center
  box-sizing: border-box
  border-radius: 20px
  padding: 6px 8px
  width: max-content

  &[role="invoice"]
    background: $yellow500

  &[role="liquidation"]
    background: $pink400

  span
    white-space: nowrap
    font-size: 12px
    font-weight: 500
</style>
