<template>
  <section class="emptyStateContainer">
    <div class="emptyStateContent">
      <slot name="image">
        <img v-if="props.image" class="image" data-test-id="empty-state-image" :src="props.image" />
      </slot>

      <h1 v-if="props.title" data-test-id="empty-state-title" class="title" v-text="props.title" />

      <slot name="subheading" />

      <h2
        v-if="props.description"
        data-test-id="empty-state-description"
        class="description"
        v-text="props.description"
      />
      <slot />
    </div>
  </section>
</template>

<script setup lang="ts">
export interface EmptyStateProps {
  image?: string
  title?: string
  description?: string
}

const props = withDefaults(defineProps<EmptyStateProps>(), {
  image: '',
  title: '',
  description: ''
})
</script>

<style scoped lang="sass">
.emptyStateContainer
  width: 100%
  height: 100%

.emptyStateContent
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  text-align: center
  height: 100%
  max-width: 48rem
  margin: 0 auto
  padding: 4rem 0

.image
  width: 40px
  padding: 18px
  background: #F3F3F3
  border-radius: 50%
  opacity: 0.4

.title
  font-size: 18px
  margin-top: 1.2rem
  font-weight: 500
  color: $grey400

.description
  font-size: 16px
  font-weight: 400
  color: $grey400
</style>
