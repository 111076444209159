<template>
  <div class="separator">
    <div class="label">
      <slot></slot>
    </div>
  </div>
</template>
<style scoped lang="sass">
.separator
  width: 100%
  height: 1px
  background-color: #E6E6E6
  position: relative

  .label
    font-weight: 500
    background-color: #F9F9F9
    padding: 0.5rem
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
</style>
