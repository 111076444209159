<template>
  <div class="status" :class="status">
    {{ t(`common.property.status.${status}`) }}
  </div>
</template>
<script lang="ts" setup>
import type { PropertyStatus } from '@/modules/property/domain/propertyStatus'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

defineProps<{ status: PropertyStatus }>()
</script>
<style lang="sass" scoped>
.status
  width: fit-content
  padding: 0.4rem 0.6rem
  border-radius: 2rem
  font-size: 0.875rem
  font-weight: 500

  &.registered
    background-color: #F6F3A0
  &.in-visits
    background-color: #9ABBD2
  &.reserved
    background-color: #FFB99B
  &.rented
    background-color: #F1C5F9
</style>
