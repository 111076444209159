<template>
  <div class="wrapper" :class="{ mobile }">
    <div class="summary" :class="{ mobile }" @click="onToggle($event, true)">
      <h3 class="mb-1">{{ t('property.payment.summary.title') }}</h3>
      <div class="section" v-if="paymentInformation.plan" :class="{ opened }">
        <p class="title">{{ t(`property.payment.${paymentInformation.plan}.name`) }}</p>
        <p class="label" v-if="paymentInformation.nonPaymentCoverage">
          <span>
            {{ t(`property.payment.nonPaymentCoverage.${paymentInformation.nonPaymentCoverage}months`) }}
            {{ t('property.payment.nonPaymentCoverage.description') }}
          </span>
        </p>
        <p class="label">
          {{ paymentInformation.plan === PricingPlan.Basic ? t('property.payment.summary.free') : formatPrice() }}
        </p>
      </div>
      <div class="section" v-if="paymentInformation.hasCommercialization" :class="{ opened }">
        <p class="title">{{ t('property.payment.commercialization.name') }}</p>
        <p class="label">{{ t('property.payment.commercialization.price') }}</p>
      </div>
      <div
        class="section"
        v-if="!paymentInformation.plan && !paymentInformation.hasCommercialization"
        :class="{ opened }"
      >
        <p>{{ t('property.payment.summary.empty') }}</p>
      </div>
      <div class="section total">
        <p class="label">{{ t('property.payment.summary.total') }}</p>
        <div v-if="paymentInformation.plan || paymentInformation.hasCommercialization">
          <p class="label right">
            <template v-if="paymentInformation.hasCommercialization">
              {{ t('property.payment.commercialization.shortPrice') }} +
            </template>
            {{ formatPrice() }}
            <span v-if="paymentInformation.plan !== PricingPlan.Basic">{{ t('property.payment.plusIva') }}</span>
          </p>
          <div v-if="mobile" class="toggle" @click="onToggle($event, !opened)">
            <img v-if="opened" :src="icons.chevronUp" />
            <img v-else :src="icons.chevronDown" />
          </div>
        </div>
        <p v-else>-</p>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { icons } from '@/config/assets/icons'
import type { PropertyPaymentInformation } from '@/modules/property/domain/propertyPaymentInformation'
import { getPricingPlanMonthlyRentPercentage, PricingPlan } from '@/modules/property/domain/propertyPaymentInformation'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps<{
  paymentInformation: PropertyPaymentInformation
  mobile?: boolean
}>()

const opened = ref(false)

function onToggle(event: MouseEvent, value: boolean) {
  event.stopPropagation()
  opened.value = value
  if (value) {
    document.addEventListener('click', onClickOutside)
  } else {
    document.removeEventListener('click', onClickOutside)
  }
}

function onClickOutside() {
  opened.value = false
  document.removeEventListener('click', onClickOutside)
}

function formatPrice() {
  if (!props.paymentInformation.plan) return ''

  const percentage = getPricingPlanMonthlyRentPercentage(
    props.paymentInformation.plan,
    props.paymentInformation.nonPaymentCoverage
  )

  return t(`property.payment.${props.paymentInformation.plan}.price`, {
    percentage
  })
}
</script>
<style lang="sass" scoped>
@use "@/assets/styles/responsive.sass" as *

.wrapper
  position: sticky
  top: 4.25rem
  z-index: 2

.wrapper.mobile
  top: 0rem
  height: 4rem

  @include md
    height: auto

.summary
  background-color: #F1C5F9
  border: 1px solid #D295DE
  border-radius: 1rem
  padding: 1rem
  display: none

  @include lg
    display: block

  .section
    margin-top: 1rem
    padding-top: 1rem
    border-top: 1px solid #D295DE

    &.total
      display: flex
      gap: 1rem
      justify-content: space-between

    &:first-of-type
      border-top: none
      margin-top: 0
      padding-top: 0

    .label > span
      font-weight: 400
      font-size: 0.75rem

    .label.right
      text-align: right

.summary.mobile
  border-top-left-radius: 0rem
  border-top-right-radius: 0rem
  border: 0
  box-shadow: 0px 2px 0px 0px #D295DE
  display: flex
  flex-direction: column

  h3
    display: none

  .total
    order: -1

  .toggle
    transform: translateY(0.25rem)

  @include md
    flex-direction: row
    justify-content: space-between

    .total
      order: 2

    .toggle
      display: none

  @include lg
    display: none

  .section
    gap: 1rem
    margin-top: 1rem
    padding-top: 1rem
    border-top: 1px solid #D295DE
    display: none

    &.opened
      display: block

    &.total
      display: flex
      justify-content: space-between

      > div
        display: flex
        gap: 0.5rem

    &:last-of-type
      border-top: none
      margin-top: 0
      padding-top: 0

    @include md
      display: block
      margin-top: 0
      padding-top: 0
      border-top: none
      border-left: 1px solid #D295DE
      padding-left: 1rem

      &.total
        display: block

        > div
          display: block

      &:first-of-type
        border-left: none
        margin-left: 0

    @include lg
      margin-top: 1rem
      padding-top: 1rem
      border-top: 1px solid #D295DE
      border-left: none
      padding-left: 0rem

    .label > span
      font-weight: 400
      font-size: 0.75rem

    .label.right
      text-align: right
</style>
