import { zodGenericSchemaValidatorBuilder } from '@/modules/shared/infrastructure/zod/zodGenericSchemaValidator'
import type { UpdatePasswordForm } from '@/modules/user/domain/updatePasswordForm'
import { z } from 'zod'

export const updatePasswordFormSchema = z
  .object({
    currentPassword: z.string().regex(/^.{6,20}$/, 'validationError.formatPassword'),
    password: z.string().regex(/^.{6,20}$/, 'validationError.formatPassword'),
    passwordConfirmation: z.string().regex(/^.{6,20}$/, 'validationError.formatPassword')
  })
  .refine(({ password, passwordConfirmation }) => password === passwordConfirmation, {
    message: 'validationError.passwordsDoNotMatch',
    path: ['passwordConfirmation']
  })

export function zodUpdatePasswordFormValidatorBuilder() {
  return zodGenericSchemaValidatorBuilder<UpdatePasswordForm>(updatePasswordFormSchema)
}
