<template>
  <div class="card">
    <div class="information">
      <div class="flex wrap justify-between gap-2">
        <div class="flex-grow">
          <div class="flex wrap justify-between gap-1 flex-grow">
            <h3>{{ property.street }}, {{ property.city }}</h3>
            <div class="flex column property">
              <PropertyStatusBadge class="mb-1" :status="property.status" />
              <p v-if="property.pricingPlan" class="plan">{{ t(`property.payment.${property.pricingPlan}.name`) }}</p>
              <template v-else>
                <p class="mb-0">{{ t('partnerProperty.information.planOrService') }}</p>
                <p class="mt-0 light">{{ t('partnerProperty.information.planOrServiceNotSelected') }}</p>
              </template>
              <template v-if="property.monthlyPayment && property.rent">
                <p class="title mt-1 mb-0">{{ property.monthlyPayment }} €/{{ t('common.month') }}</p>
                <p>{{ t('partnerProperty.information.rent') }} {{ property.rent }} €/{{ t('common.month') }}</p>
              </template>
              <template v-else>
                <p class="mb-0 mt-1">{{ t('partnerProperty.information.monthlyAndRent') }}</p>
                <p class="mt-0 light">{{ t('partnerProperty.information.monthlyAndRentNotDefined') }}</p>
              </template>
            </div>
          </div>
          <hr class="mt-1 mb-2" />
          <div class="flex wrap gap-3">
            <div class="landlord">
              <div class="flex align-end gap-05 mb-1">
                <img :src="icons.house" />
                <h4>{{ t('partner.landlord') }}</h4>
              </div>
              <p class="landlord-name title mb-0">{{ property.landlord.name }}</p>
              <p class="landlord-email mb-0 mt-0">{{ property.landlord.email }}</p>
              <p class="landlord-phone mt-0">{{ property.landlord.phone }}</p>
            </div>
            <div class="tenant">
              <div class="flex align-end gap-05 mb-1">
                <img :src="icons.group" />
                <h4>{{ t('partner.tenant') }}</h4>
              </div>
              <template v-if="property.tenants.length">
                <template v-for="tenant in property.tenants">
                  <p class="tenant-name title mb-0">{{ tenant }}</p>
                  <p class="mb-0 mt-0">
                    {{ t('partner.solvency') }}
                    <img :src="icons.checkGreen" />
                  </p>
                </template>
              </template>
              <p class="light" v-else>{{ t('partner.tenantsEmpty') }}</p>
            </div>
          </div>
        </div>
        <div class="images" v-if="property.images.length">
          <img v-for="(image, index) in property.images" :src="image" :class="{ active: index === imageIndex }" />
          <div class="left" @click="previousImage"><img :src="icons.chevronLeft" /></div>
          <div class="right" @click="nextImage"><img :src="icons.chevronRight" /></div>
          <div class="counter">
            <img :src="icons.camera" />
            <p>{{ imageIndex + 1 }}/{{ property.images.length }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { PropertyStatusBadge } from '@/components'
import { icons } from '@/config/assets/icons'
import { type PartnerProperty } from '@/modules/partnerProperty/domain/partnerProperty'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps<{ property: PartnerProperty }>()

const imageIndex = ref(0)

function previousImage() {
  imageIndex.value = imageIndex.value === 0 ? props.property.images.length - 1 : imageIndex.value - 1
}

function nextImage() {
  imageIndex.value = (imageIndex.value + 1) % props.property.images.length
}
</script>
<style scoped lang="sass">
@use "@/assets/styles/responsive.sass" as *

.information
  padding: 0.5rem

h3
  width: 18rem
  max-width: 18rem
  @include sm
    width: auto

.property
  @include sm
    align-items: flex-end

.landlord, .tenant
  p
    max-width: 11rem
    word-break: break-word

.tenant
  p
    position: relative
    img
      position: absolute
      right: -1.5rem

.images
  width: 100%
  height: 20rem
  border-radius: 1.5rem
  overflow: hidden
  position: relative

  > img
    position: absolute
    left: 0
    right: 0
    width: 100%
    height: 100%
    object-fit: cover
    opacity: 0
    transition: opacity 0.25s

    &.active
      opacity: 1
      z-index: 1

  .counter, .left, .right
    display: flex
    align-items: center
    position: absolute
    bottom: 1rem
    left: 1rem
    background-color: #FFFFFFD9
    border-radius: 2rem
    padding: 0.5rem
    gap: 0.25rem
    z-index: 2

  .counter
    min-width: 3.25rem

    p
      margin: 0
      padding-right: 0.25rem

  .left, .right
    bottom: 50%
    transform: translateY(50%)
    cursor: pointer

  .left
    left: 1rem
    bottom: 50%

  .right
    left: auto
    right: 1rem


  @include xl
    width: 25rem
    height: 18.25rem
</style>
