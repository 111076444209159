export function toLocaleNumber(
  number = 0,
  locale = 'es-ES',
  maximumFractionDigits = 2,
  minimumFractionDigits?: number
): string {
  return new Intl.NumberFormat(locale, { maximumFractionDigits, minimumFractionDigits, useGrouping: true }).format(
    number
  )
}

export function toLocaleCurrencySymbol(number = 0, locale = 'es-ES', currency = 'EUR'): string {
  const hasDecimals = number % 1 !== 0
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: hasDecimals ? 2 : 0,
    maximumFractionDigits: hasDecimals ? 2 : 0,
    useGrouping: true
  })

  return formatter.format(number)
}

export function toLocalePercent(number = 0, locale = 'es-ES', maximumFractionDigits = 2): string {
  const numberFormat = new Intl.NumberFormat(locale, {
    style: 'percent',
    maximumFractionDigits,
    useGrouping: true
  })

  const percentToFormat = number / 100

  return numberFormat.format(percentToFormat)
}

export function toDecimal(
  number = 0,
  locale = 'es-ES',
  maximumFractionDigits = 2,
  minimumFractionDigits?: number
): string {
  const numberFormat = new Intl.NumberFormat(locale, {
    style: 'decimal',
    maximumFractionDigits,
    minimumFractionDigits
  })
  return numberFormat.format(number)
}
