import { apiEndpointsV2 as v2 } from '@/config/api/endpoints.v2'
import type { ApiService } from '@/modules/shared/domain/api/apiService'
import type { Uuid } from '@/modules/shared/domain/uuid/uuid'
import { asyncMap } from '@/utils/array'
import type { ApplicationStatus } from '../domain/application'
import type { ApplicationRepository } from '../domain/applicationRepository'
import { fromDto, type ApplicationApiDto, type PropertyApiDto, type PropertyImageDto } from './apiDto'

export function apiApplicationRepositoryBuilder(apiV2Service: ApiService): ApplicationRepository {
  return {
    async create(applicationId: Uuid, propertyId: string) {
      await apiV2Service.post(v2.applications().create(), { id: applicationId, propertyId })
    },
    async getAll() {
      const results = await apiV2Service.getList<ApplicationApiDto>(v2.applications().list())
      return asyncMap(results, async (dto) => {
        const propertyDto = await apiV2Service.get<PropertyApiDto>(v2.tenants().properties().find(dto.propertyId))
        const imagesDtos = await apiV2Service.getList<PropertyImageDto>(v2.properties().images(dto.propertyId).list())
        return fromDto(dto, propertyDto, imagesDtos)
      })
    },
    async getStatusById(applicationId: Uuid) {
      const dto = await apiV2Service.get<ApplicationApiDto>(v2.applications().find(applicationId))
      return dto.status as ApplicationStatus
    },
    async linkTenant(applicationId: Uuid, tenantId: Uuid) {
      await apiV2Service.put(v2.applications().tenants(applicationId).link(tenantId))
    },
    async unlinkTenant(applicationId: Uuid, tenantId: Uuid) {
      return apiV2Service.delete(v2.applications().tenants(applicationId).unlink(tenantId))
    },
    async submit(applicationId: Uuid) {
      await apiV2Service.put(v2.applications().submit(applicationId))
    }
  }
}
