import addNotes from '@/assets/icons/add_notes.svg'
import addPhoto from '@/assets/icons/add_photo.svg'
import applications from '@/assets/icons/applications.svg'
import arrowBack from '@/assets/icons/arrow_back.svg'
import arrowOutward from '@/assets/icons/arrow_outward.svg'
import arrowRight from '@/assets/icons/arrow_right.svg'
import billing from '@/assets/icons/billing.svg'
import camera from '@/assets/icons/camera.svg'
import cancelRed from '@/assets/icons/cancel_red.svg'
import check from '@/assets/icons/check.svg'
import checkCircleFilled from '@/assets/icons/check_circle_filled.svg'
import checkCircleFilledGreen from '@/assets/icons/check_circle_filled_green.svg'
import checkCircleOutline from '@/assets/icons/check_circle_outline.svg'
import checkGreen from '@/assets/icons/check_green.svg'
import checkWhite from '@/assets/icons/check_white.svg'
import chevronDown from '@/assets/icons/chevron_down.svg'
import chevronLeft from '@/assets/icons/chevron_left.svg'
import chevronRight from '@/assets/icons/chevron_right.svg'
import chevronUp from '@/assets/icons/chevron_up.svg'
import close from '@/assets/icons/close.svg'
import cloudDownload from '@/assets/icons/cloud_download.svg'
import deleteIcon from '@/assets/icons/delete.svg'
import document from '@/assets/icons/document.svg'
import documentLight from '@/assets/icons/document_light.svg'
import fileJpg from '@/assets/icons/file_jpg.svg'
import fileJpeg from '@/assets/icons/file_jpeg.svg'
import filePdf from '@/assets/icons/file_pdf.svg'
import filePng from '@/assets/icons/file_png.svg'
import group from '@/assets/icons/group.svg'
import help from '@/assets/icons/help.svg'
import house from '@/assets/icons/house.svg'
import infoCircle from '@/assets/icons/info_circle.svg'
import key from '@/assets/icons/key.svg'
import lead from '@/assets/icons/lead.svg'
import logout from '@/assets/icons/logout.svg'
import menu from '@/assets/icons/menu.svg'
import profit from '@/assets/icons/profit.svg'
import properties from '@/assets/icons/properties.svg'
import propertyInVisits from '@/assets/icons/property_in_visits.svg'
import propertyListed from '@/assets/icons/property_listed.svg'
import propertyRegistered from '@/assets/icons/property_registered.svg'
import propertyRented from '@/assets/icons/property_rented.svg'
import propertyReserved from '@/assets/icons/property_reserved.svg'
import propertyTypeAttachedHouse from '@/assets/icons/property_type_attached_house.svg'
import propertyTypeDetachedHouse from '@/assets/icons/property_type_detached_house.svg'
import propertyTypeDuplex from '@/assets/icons/property_type_duplex.svg'
import propertyTypeFlat from '@/assets/icons/property_type_flat.svg'
import propertyTypePenthouse from '@/assets/icons/property_type_penthouse.svg'
import sendLight from '@/assets/icons/send_light.svg'
import travelExplore from '@/assets/icons/travel_explore.svg'
import view from '@/assets/icons/view.svg'

export const icons = {
  addNotes,
  addPhoto,
  applications,
  arrowBack,
  arrowOutward,
  arrowRight,
  billing,
  camera,
  cancelRed,
  check,
  checkCircleFilled,
  checkCircleFilledGreen,
  checkCircleOutline,
  checkGreen,
  checkWhite,
  chevronDown,
  chevronLeft,
  chevronRight,
  chevronUp,
  close,
  cloudDownload,
  delete: deleteIcon,
  document,
  documentLight,
  fileJpg,
  fileJpeg,
  filePdf,
  filePng,
  group,
  help,
  house,
  infoCircle,
  key,
  lead,
  logout,
  menu,
  profit,
  properties,
  propertyInVisits,
  propertyListed,
  propertyRegistered,
  propertyRented,
  propertyReserved,
  propertyTypeAttachedHouse,
  propertyTypeDetachedHouse,
  propertyTypeDuplex,
  propertyTypeFlat,
  propertyTypePenthouse,
  sendLight,
  travelExplore,
  view
}
