import type { PDFDocument } from 'pdf-lib'
import { createPDFBase, drawTextWithLineBreaks, getFinalPageTextPosition } from './pdfLibUtils'
import type { LandlordInvoice, LandlordConcept } from '@/modules/landlord/domain/landlordBillingDocuments'
import { toLocaleCurrencySymbol } from '../numbers'
import { i18n } from '@/i18n'

export async function createInvoicePDF(invoice: LandlordInvoice, concepts: LandlordConcept[]): Promise<PDFDocument> {
  const locale = i18n.global.locale
  const { pdfDoc, page, font, boldFont, size, color, colorGray } = await createPDFBase()

  let y = page.getSize().height - 50

  // Title
  page.drawText('Factura', { x: 50, y, size: 24, font, color })
  y -= 50

  // Main Data Invoice
  page.drawText('Nº de factura:', { x: 50, y, size, font: boldFont, color })
  page.drawText(invoice.invoiceNumber, { x: 200, y, size, font, color })
  y -= 20
  page.drawText('Fecha de la factura:', { x: 50, y, size, font: boldFont, color })
  page.drawText(invoice.issuedDate, { x: 200, y, size, font, color })
  y -= 20
  page.drawText('Fecha de vencimiento:', { x: 50, y, size, font: boldFont, color })
  page.drawText(invoice.dueDate, { x: 200, y, size, font, color })
  y -= 40

  //----------------------------

  const yStartData = y
  page.drawText('Emisor:', { x: 50, y, size, font: boldFont, color })
  y = drawTextWithLineBreaks(page, invoice.recipient.name, 120, y, size, font, color, 160)

  page.drawText('NIF/CIF:', { x: 50, y, size, font: boldFont, color })
  y = drawTextWithLineBreaks(page, invoice.recipient.identificationNumber, 120, y, size, font, color, 160)

  page.drawText('Dirección:', { x: 50, y, size, font: boldFont, color })
  y = drawTextWithLineBreaks(page, invoice.recipient.address, 120, y, size, font, color, 160)

  //----------------------------

  y = yStartData
  page.drawText('Cliente:', { x: 310, y, size, font: boldFont, color })
  y = drawTextWithLineBreaks(page, invoice.sender.name, 380, y, size, font, color, 160)

  page.drawText('NIF/CIF:', { x: 310, y, size, font: boldFont, color })
  y = drawTextWithLineBreaks(page, invoice.sender.identificationNumber, 380, y, size, font, color, 160)

  page.drawText('Dirección:', { x: 310, y, size, font: boldFont, color })
  y = drawTextWithLineBreaks(page, invoice.sender.address, 380, y, size, font, color, 160)

  y -= 10
  page.drawText('Método de pago:', { x: 310, y, size, font: boldFont, color })
  page.drawText('Domiciliación bancaria', { x: 410, y, size, font, color })

  y -= 40

  // Header table
  page.drawRectangle({ x: 40, y: y - 10, width: 520, height: 25, color: colorGray })
  page.drawText('CONCEPTO', { x: 50, y, size: 8, font })
  page.drawText('IMPORTE', { x: getFinalPageTextPosition(page, font, 'IMPORTE', 8), y, size: 8, font })
  y -= 10
  const yStartTable = y

  page.drawLine({ start: { x: 50, y }, end: { x: 550, y }, thickness: 1, color: colorGray })
  y -= 20

  // Items
  concepts.forEach((item) => {
    const formatAmount = (amount: number) => toLocaleCurrencySymbol(amount, locale.value)
    const amount = -+item.amount
    let netAmount = amount
    let taxAmount = 0
    // Taxes calculation
    if (item.taxesPercentage > 0) {
      netAmount = amount / (1 + item.taxesPercentage / 100)
      taxAmount = amount - netAmount
    }

    const value = formatAmount(netAmount)
    page.drawText(value, { x: getFinalPageTextPosition(page, font, value, size), y, size, font })
    y = drawTextWithLineBreaks(page, `${item.statement} (${item.chargedAt})`, 50, y, size, font, color, 400)

    page.drawLine({
      start: { x: 40, y: y + 6 },
      end: { x: page.getSize().width - 40, y: y + 6 },
      thickness: 1,
      color: colorGray
    })
    y -= 15
  })
  y -= 10

  // Table Footer
  type TaxRateDetails = {
    taxableBase: number
    tax: number
  }

  const taxDetails: Record<string, TaxRateDetails> = {}

  concepts.forEach((concept) => {
    const percentage = concept.taxesPercentage
    const finalAmount = -+concept.amount

    if (!taxDetails[percentage]) {
      taxDetails[percentage] = { taxableBase: 0, tax: 0 }
    }

    if (percentage > 0) {
      const baseImponible = finalAmount / (1 + percentage / 100)
      const impuesto = finalAmount - baseImponible

      taxDetails[percentage].taxableBase += baseImponible
      taxDetails[percentage].tax += impuesto
    } else {
      taxDetails[percentage].taxableBase += finalAmount
    }
  })

  Object.keys(taxDetails).forEach((percentage) => {
    const { taxableBase, tax } = taxDetails[percentage]

    page.drawText(`Base imponible al ${parseFloat(percentage).toFixed(2)}%`, { x: 320, y, size, font: boldFont })
    const taxBaseAmount = toLocaleCurrencySymbol(taxableBase, locale.value)
    page.drawText(taxBaseAmount, { x: getFinalPageTextPosition(page, font, taxBaseAmount, size), y, size, font })
    y -= 20

    page.drawText(`IVA / IGIC al ${parseFloat(percentage).toFixed(2)}%`, { x: 320, y, size, font: boldFont })
    const taxedAmount = toLocaleCurrencySymbol(tax, locale.value)
    page.drawText(taxedAmount, { x: getFinalPageTextPosition(page, font, taxedAmount, size), y, size, font })
    y -= 20
  })

  y -= 20

  // Table Lines
  page.drawLine({ start: { x: 40, y: yStartTable }, end: { x: 40, y }, thickness: 1, color: colorGray })
  page.drawLine({ start: { x: 470, y: yStartTable }, end: { x: 470, y }, thickness: 1, color: colorGray })
  page.drawLine({
    start: { x: page.getSize().width - 40, y: yStartTable },
    end: { x: page.getSize().width - 40, y },
    thickness: 1,
    color: colorGray
  })
  page.drawLine({ start: { x: 40, y }, end: { x: page.getSize().width - 40, y }, thickness: 1, color: colorGray })
  y -= 20

  // Total
  page.drawText('Total factura:', { x: 320, y, size: 12, font: boldFont })
  const value = toLocaleCurrencySymbol(invoice.finalAmount, locale.value)
  page.drawText(value, { x: getFinalPageTextPosition(page, font, value, 12), y, size: 12, font: boldFont })

  return pdfDoc
}
