import type { CreatePartnerReferralForm } from '@/modules/partnerReferral/domain/createPartnerReferralForm'
import type { PartnerReferralRepository } from '@/modules/partnerReferral/domain/partnerReferralRepository'
import type { Uuid } from '@/modules/shared/domain/uuid/uuid'

export function createBuilder(
  repository: PartnerReferralRepository,
  validator: Validator<CreatePartnerReferralForm>,
  uuidGenerator: () => Uuid
) {
  return async (form: CreatePartnerReferralForm) => {
    validator(form)
    await repository.create(uuidGenerator(), form)
  }
}
