import type { PartnerProperty } from '@/modules/partnerProperty/domain/partnerProperty'
import { PricingPlan } from '@/modules/property/domain/propertyPaymentInformation'
import { PropertyStatus } from '@/modules/property/domain/propertyStatus'

export interface PartnerPropertyDto {
  id: string
  address: {
    street: string
    city: string
    floor: string
    zipCode: string
  }
  landlord: { id: string }
  monthlyPaymentAmount: Nullable<number>
  rent: Nullable<{
    amount: Nullable<number>
  }>
  status: 'registered' | 'listed' | 'reserved' | 'rented'
  createdAt: string
}

export interface PartnerPropertyImageDto {
  id: string
  name: string
  mimeType: 'image/jpg' | 'image/jpeg' | 'image/png'
}

export interface ServiceBundleDto {
  pricingPlan?: { type: string }
}

export interface PartnerPropertyLandlordDto {
  name: string
  email: string
  phone: string
}

export function fromPricingPlanDto(dto: { type: string }): Nullable<PricingPlan> {
  if (dto.type === 'secured') {
    return PricingPlan.Secured
  } else if (dto.type === 'peace-of-mind') {
    return PricingPlan.PeaceOfMind
  } else if (dto.type === 'basic') {
    return PricingPlan.Basic
  } else if (dto.type === 'comfort') {
    return PricingPlan.Comfort
  } else {
    return null
  }
}

function fromImageDtos(propertyId: string, dtos: PartnerPropertyImageDto[]): string[] {
  const extensions = {
    'image/jpg': 'jpg',
    'image/jpeg': 'jpeg',
    'image/png': 'png'
  }
  return dtos.map(
    (dto) =>
      `${import.meta.env.VITE_FILE_STORAGE_BASE_URL}/properties/${propertyId}/images/${dto.id}.${extensions[dto.mimeType]}`
  )
}

export function fromPartnerPropertyDto(
  dto: PartnerPropertyDto,
  imageDtos: PartnerPropertyImageDto[],
  landlordDto: PartnerPropertyLandlordDto,
  serviceBundleDto: Nullable<ServiceBundleDto>,
  tenants: string[]
): PartnerProperty {
  return {
    city: dto.address.city,
    createdAt: new Date(dto.createdAt),
    floor: dto.address.floor,
    id: dto.id,
    images: fromImageDtos(dto.id, imageDtos),
    landlord: landlordDto,
    monthlyPayment: dto.monthlyPaymentAmount,
    pricingPlan: serviceBundleDto?.pricingPlan ? fromPricingPlanDto(serviceBundleDto.pricingPlan) : null,
    status: dto.status === 'listed' ? PropertyStatus.InVisits : (dto.status as PropertyStatus),
    street: dto.address.street,
    tenants,
    rent: dto.rent?.amount ?? null,
    zipCode: dto.address.zipCode
  }
}
