import { zodGenericSchemaValidatorBuilder } from '@/modules/shared/infrastructure/zod/zodGenericSchemaValidator'
import type { UpdateUserForm } from '@/modules/user/domain/updateUserForm'
import { z } from 'zod'

export const updateUserFormSchema = z
  .object({
    company: z.string().nullable(),
    name: z.string().regex(/^.+$/, 'validationError.required'),
    phone: z.string().regex(/^(?:\+34|0034|34)?[6789](\d){8}$/, 'validationError.formatPhone'),
    surname: z.string().regex(/^.+$/, 'validationError.required'),
    type: z.union([z.literal('landlord'), z.literal('partner'), z.literal('tenant')])
  })
  .refine(({ type, company }) => type !== 'partner' || company, {
    message: 'validationError.required',
    path: ['company']
  })

export function zodUpdateUserFormValidatorBuilder() {
  return zodGenericSchemaValidatorBuilder<UpdateUserForm>(updateUserFormSchema)
}
