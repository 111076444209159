import { AnalyticsEvent } from '../domain/analyticsEvent'

export const mixpanelEventsMap: Record<AnalyticsEvent, string> = {
  [AnalyticsEvent.LandlordBillingInvoicesList]: 'landlord_billing_invoices list',
  [AnalyticsEvent.LandlordBillingInvoicesListDownloadInvoice]: 'landlord_billing_invoices list_download invoice',
  [AnalyticsEvent.LandlordBillingInvoicesListDownloadLiquidation]:
    'landlord_billing_invoices list_download liquidation',
  [AnalyticsEvent.LandlordDocumentationUploadBills]: 'landlord_documentation_upload bills',
  [AnalyticsEvent.LandlordDocumentationUploadEnergyCertificate]: 'landlord_documentation_upload energy certificate',
  [AnalyticsEvent.LandlordDocumentationUploadHabitabilityCertificate]:
    'landlord_documentation_upload habitability certificate',
  [AnalyticsEvent.LandlordDocumentationUploadIBI]: 'landlord_documentation_upload IBI',
  [AnalyticsEvent.LandlordDocumentationUploadId]: 'landlord_documentation_upload enter id',
  [AnalyticsEvent.LandlordDocumentationUploadPropertyExpenses]: 'landlord_documentation_upload property expenses',
  [AnalyticsEvent.LandlordOnboardingCompleted]: 'landlord_onboarding completed',
  [AnalyticsEvent.LandlordOnboardingPropertiesList]: 'landlord_onboarding_properties list',
  [AnalyticsEvent.LandlordProfile]: 'landlord_profile',
  [AnalyticsEvent.LandlordProfileConfirmDeleteAccount]: 'landlord_profile_confirm delete account',
  [AnalyticsEvent.LandlordPropertiesAddNewProperty]: 'landlord_properties_add new property',
  [AnalyticsEvent.LandlordPropertiesContinueRegistration]: 'landlord_properties_continue registration',
  [AnalyticsEvent.LandlordPropertiesPropertiesList]: 'landlord_properties_properties list',
  [AnalyticsEvent.LandlordPropertyDetailContractualInformation]: 'landlord_property detail_contractual information',
  [AnalyticsEvent.LandlordPropertyDetailDocuments]: 'landlord_property detail_documents',
  [AnalyticsEvent.LandlordPropertyDetailDownloadDocument]: 'landlord_property detail_download document',
  [AnalyticsEvent.LandlordPropertyDetailStatusTimeline]: 'landlord_property detail_status timeline',
  [AnalyticsEvent.LandlordPropertyInformationCompleted]: 'landlord_property information completed',
  [AnalyticsEvent.LandlordPropertyInformationContinue]: 'landlord_property information_continue',
  [AnalyticsEvent.LandlordPropertyInformationContinueLater]: 'landlord_property information_later',
  [AnalyticsEvent.LandlordPropertyRegisterDeleteCatastralReference]:
    'landlord_property register_delete catastral reference',
  [AnalyticsEvent.LandlordPropertyRegisterEnterAddress]: 'landlord_property register_enter address',
  [AnalyticsEvent.LandlordPropertyRegisterEnterAvailability]: 'landlord_property register_enter availability',
  [AnalyticsEvent.LandlordPropertyRegisterEnterBathrooms]: 'landlord_property register_enter bathrooms',
  [AnalyticsEvent.LandlordPropertyRegisterEnterBedrooms]: 'landlord_property register_enter bedrooms',
  [AnalyticsEvent.LandlordPropertyRegisterEnterCatastralReference]:
    'landlord_property register_enter catastral reference',
  [AnalyticsEvent.LandlordPropertyRegisterEnterMonthlyRent]: 'landlord_property register_enter monthly rent',
  [AnalyticsEvent.LandlordPropertyRegisterErrorDocumentationFormat]:
    'landlord_property register_error documentation format',
  [AnalyticsEvent.LandlordPropertyRegisterErrorImageFormat]: 'landlord_property register_error image format',
  [AnalyticsEvent.LandlordPropertyRegisterHoverInformation]: 'landlord_property register_hover information',
  [AnalyticsEvent.LandlordPropertyRegisterSelectFurniture]: 'landlord_property register_select furniture',
  [AnalyticsEvent.LandlordPropertyRegisterSelectIndoorExtras]: 'landlord_property register_select indoor extras',
  [AnalyticsEvent.LandlordPropertyRegisterSelectOutdoorExtras]: 'landlord_property register_select outdoor extras',
  [AnalyticsEvent.LandlordPropertyRegisterSelectPets]: 'landlord_property register_select pets',
  [AnalyticsEvent.LandlordPropertyRegisterSelectPlan]: 'landlord_plan_select plan',
  [AnalyticsEvent.LandlordPropertyRegisterSelectProfessionalImages]:
    'landlord_property register_select professional images',
  [AnalyticsEvent.LandlordPropertyRegisterSelectPropertyState]: 'landlord_property register_select property state',
  [AnalyticsEvent.LandlordPropertyRegisterSelectPropertyType]: 'landlord_property register_select property type',
  [AnalyticsEvent.LandlordPropertyRegisterStartEquipmentExtras]: 'landlord_property register_start equipment extras',
  [AnalyticsEvent.LandlordPropertyRegisterStartGeneralData]: 'landlord_property register_start general data',
  [AnalyticsEvent.LandlordPropertyRegisterStartImages]: 'landlord_property register_start images',
  [AnalyticsEvent.LandlordPropertyRegisterStartPlan]: 'landlord_start plan',
  [AnalyticsEvent.LandlordPropertyRegisterStartPropertyData]: 'landlord_property register_start property data',
  [AnalyticsEvent.LandlordPropertyRegisterSubmitEquipmentExtras]: 'landlord_property register_submit equipment extras',
  [AnalyticsEvent.LandlordPropertyRegisterSubmitGeneralData]: 'landlord_property register_submit general data',
  [AnalyticsEvent.LandlordPropertyRegisterSubmitImages]: 'landlord_property register_submit images',
  [AnalyticsEvent.LandlordPropertyRegisterSubmitPlan]: 'landlord_submit plan',
  [AnalyticsEvent.LandlordPropertyRegisterSubmitPropertyData]: 'landlord_property register_submit property data',
  [AnalyticsEvent.LandlordPropertyRegisterUploadImages]: 'landlord_property register_upload images',
  [AnalyticsEvent.LandlordSignIn]: 'landlord_sign in',
  [AnalyticsEvent.LandlordSignupCompleted]: 'landlord_sign up completed',
  [AnalyticsEvent.LandlordSignupContinue]: 'landlord_sign up_continue',
  [AnalyticsEvent.LandlordSignupContinueLater]: 'landlord_sign up_later',
  [AnalyticsEvent.LandlordSignupDeleteCatastralReference]: 'landlord_sign up_delete catastral reference',
  [AnalyticsEvent.LandlordSignupEnterAddress]: 'landlord_sign up_enter address',
  [AnalyticsEvent.LandlordSignupEnterAvailability]: 'landlord_sign up_enter availability',
  [AnalyticsEvent.LandlordSignupEnterCatastralReference]: 'landlord_sign up_enter catastral reference',
  [AnalyticsEvent.LandlordSignupEnterMonthlyRent]: 'landlord_sign up_enter monthly rent',
  [AnalyticsEvent.LandlordSignupEnterName]: 'landlord_sign up_enter name',
  [AnalyticsEvent.LandlordSignupEnterPhone]: 'landlord_sign up_enter phone',
  [AnalyticsEvent.LandlordSignupEnterSurname]: 'landlord_sign up_enter surname',
  [AnalyticsEvent.LandlordSignupErrorAccountExists]: 'landlord_sign up_error account exists',
  [AnalyticsEvent.LandlordSignupInitiateRegister]: 'landlord_sign up_initiate register',
  [AnalyticsEvent.LandlordSignupRegisterWithEmail]: 'landlord_sign up_register email',
  [AnalyticsEvent.LandlordSignupRegisterWithFacebook]: 'landlord_sign up_register facebook',
  [AnalyticsEvent.LandlordSignupRegisterWithGoogle]: 'landlord_sign up_register google',
  [AnalyticsEvent.LandlordSignupSelectCommercialCommunications]: 'landlord_sign up_select commercial communications',
  [AnalyticsEvent.LandlordSignupSelectTermsAndConditions]: 'landlord_sign up_select t&c privacy',
  [AnalyticsEvent.LandlordSignupSelectUserType]: 'landlord_sign up_select user type',
  [AnalyticsEvent.LandlordSignupStartAccountInformation]: 'landlord_sign up_start account information',
  [AnalyticsEvent.LandlordSignupStartPersonalData]: 'landlord_sign up_start personal data',
  [AnalyticsEvent.LandlordSignupStartPropertyData]: 'landlord_sign up_start property data',
  [AnalyticsEvent.LandlordSignupSubmitPersonalData]: 'landlord_sign up_submit personal data',
  [AnalyticsEvent.LandlordSignupSubmitPropertyData]: 'landlord_sign up_submit property data',
  [AnalyticsEvent.LandlordStartDocumentation]: 'landlord_start documentation',
  [AnalyticsEvent.LandlordSubmitDocumentation]: 'landlord_submit documentation',
  [AnalyticsEvent.PartnerBillingInvoicesList]: 'partner_billing_invoices list',
  [AnalyticsEvent.PartnerLeadsCreateLeadBanner]: 'partner_leads_create lead (sidebar banner)',
  [AnalyticsEvent.PartnerLeadsCreateLeadButton]: 'partner_leads_create lead (leads list button)',
  [AnalyticsEvent.PartnerLeadsLeadCreated]: 'partner_leads_lead created',
  [AnalyticsEvent.PartnerLeadsLeadsList]: 'partner_leads_leads list',
  [AnalyticsEvent.PartnerPropertiesPropertiesList]: 'partner_properties_properties list',
  [AnalyticsEvent.PartnerPropertyCommercializationDetailConfirmDate]:
    'partner_property commercialization detail_confirm date',
  [AnalyticsEvent.PartnerPropertyCommercializationDetailModifyDate]:
    'partner_property commercialization detail_modify date',
  [AnalyticsEvent.PartnerPropertyCommercializationDetailSelectDate]:
    'partner_property commercialization detail_select date',
  [AnalyticsEvent.PartnerPropertyCommercializationDetailTasksCompleted]:
    'partner_property commercialization detail_task completed',
  [AnalyticsEvent.PartnerPropertyCommercializationDetailTasksList]:
    'partner_property commercialization detail_tasks list',
  [AnalyticsEvent.PartnerPropertyCommercializationDetailVisitsList]:
    'partner_property commercialization detail_visits list',
  [AnalyticsEvent.PartnerPropertyCommercializationPropertiesList]: 'partner_property commercialization_properties list',
  [AnalyticsEvent.PartnerPropertyDetailContractualInformation]: 'partner_property detail_contractual information',
  [AnalyticsEvent.PartnerPropertyDetailDocuments]: 'partner_property detail_documents',
  [AnalyticsEvent.PartnerPropertyDetailDownloadDocuments]: 'partner_property detail_download document',
  [AnalyticsEvent.PartnerPropertyDetailStatusTimeline]: 'partner_property detail_status timeline',
  [AnalyticsEvent.PartnerSignIn]: 'partner_sign in',
  [AnalyticsEvent.PartnerSignupEnterName]: 'partner_sign up_enter name',
  [AnalyticsEvent.PartnerSignupEnterPhone]: 'partner_sign up_enter phone',
  [AnalyticsEvent.PartnerSignupEnterSurname]: 'partner_sign up_enter surname',
  [AnalyticsEvent.PartnerSignupErrorAccountExists]: 'partner_sign up_error account exists',
  [AnalyticsEvent.PartnerSignupRegisterWithEmail]: 'partner_sign up_register email',
  [AnalyticsEvent.PartnerSignupSelectCommercialCommunications]: 'partner_sign up_select commercial communications',
  [AnalyticsEvent.PartnerSignupSelectTermsAndConditions]: 'partner_sign up_select t&c privacy',
  [AnalyticsEvent.PartnerSignupStartAccountInformation]: 'partner_sign up_start account information',
  [AnalyticsEvent.PartnerSignupStartPersonalData]: 'partner_sign up_start personal data',
  [AnalyticsEvent.PartnerSignupSubmitPersonalData]: 'partner_sign up_submit personal data',
  [AnalyticsEvent.SignInStart]: 'sign in completed',
  [AnalyticsEvent.SignUpStart]: 'sign up_start onboarding',
  [AnalyticsEvent.TenantApplicationAddTenant]: 'tenant_application_add tenant',
  [AnalyticsEvent.TenantApplicationApplicationsList]: 'tenant_application_applications list',
  [AnalyticsEvent.TenantApplicationCompleted]: 'tenant_application completed',
  [AnalyticsEvent.TenantApplicationCompletedApplicationsList]: 'tenant_application completed_applications list',
  [AnalyticsEvent.TenantApplicationDeleteTenant]: 'tenant_application_delete tenant',
  [AnalyticsEvent.TenantApplicationDocuments]: 'tenant_application_documents',
  [AnalyticsEvent.TenantApplicationDontUseExistingCotenantInformation]:
    'tenant_application_dont use existing co-tenant information',
  [AnalyticsEvent.TenantApplicationSaveDocuments]: 'tenant_application_save documents',
  [AnalyticsEvent.TenantApplicationSelectApplication]: 'tenant_application_select application',
  [AnalyticsEvent.TenantApplicationSelectFotocasa]: 'tenant_application_select fotocasa',
  [AnalyticsEvent.TenantApplicationSelectIdealista]: 'tenant_application_select idealista',
  [AnalyticsEvent.TenantApplicationSendDocuments]: 'tenant_application_send documents',
  [AnalyticsEvent.TenantApplicationUseExistingCotenantInformation]:
    'tenant_application_use existing co-tenant information',
  [AnalyticsEvent.TenantProfile]: 'tenant_profile',
  [AnalyticsEvent.TenantProfileConfirmDeleteAccount]: 'tenant_profile_confirm delete account',
  [AnalyticsEvent.TenantPropertiesApply]: 'tenant_properties_apply',
  [AnalyticsEvent.TenantPropertiesPropertiesList]: 'tenant_properties_properties list',
  [AnalyticsEvent.TenantPropertiesSelectIdealista]: 'tenant_properties_select idealista',
  [AnalyticsEvent.TenantSignIn]: 'tenant_sign in',
  [AnalyticsEvent.TenantSignupCompleted]: 'tenant_sign up completed',
  [AnalyticsEvent.TenantSignupContinue]: 'tenant_sign up_continue',
  [AnalyticsEvent.TenantSignupContinueLater]: 'tenant_sign up_later',
  [AnalyticsEvent.TenantSignupEnterName]: 'tenant_sign up_enter name',
  [AnalyticsEvent.TenantSignupEnterPhone]: 'tenant_sign up_enter phone',
  [AnalyticsEvent.TenantSignupEnterSurname]: 'tenant_sign up_enter surname',
  [AnalyticsEvent.TenantSignupErrorAccountExists]: 'tenant_sign up_error account exists',
  [AnalyticsEvent.TenantSignupInitiateRegister]: 'tenant_sign up_initiate register',
  [AnalyticsEvent.TenantSignupRegisterWithEmail]: 'tenant_sign up_register email',
  [AnalyticsEvent.TenantSignupRegisterWithFacebook]: 'tenant_sign up_register facebook',
  [AnalyticsEvent.TenantSignupRegisterWithGoogle]: 'tenant_sign up_register google',
  [AnalyticsEvent.TenantSignupSelectCommercialCommunications]: 'tenant_sign up_select commercial communications',
  [AnalyticsEvent.TenantSignupSelectTermsAndConditions]: 'tenant_sign up_select t&c privacy',
  [AnalyticsEvent.TenantSignupSelectUserType]: 'tenant_sign up_select user type',
  [AnalyticsEvent.TenantSignupStartAccountInformation]: 'tenant_sign up_start account information',
  [AnalyticsEvent.TenantSignupStartPersonalData]: 'tenant_sign up_start personal data',
  [AnalyticsEvent.TenantSignupSubmitPersonalData]: 'tenant_sign up_submit personal data'
}
