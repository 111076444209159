export enum IndoorExtra {
  Fridge = 'fridge',
  Oven = 'oven',
  Microwave = 'microwave',
  Stove = 'stove',
  Dishwasher = 'dishwasher',
  WashingMachine = 'washing-machine',
  Dryer = 'dryer',
  Alarm = 'alarm',
  AirConditioning = 'air-conditioning'
}

export enum OutdoorExtra {
  Lift = 'lift',
  Terrace = 'terrace',
  Balcony = 'balcony',
  PrivateGarage = 'privateGarage',
  StorageRoom = 'storageRoom'
}

export interface PropertyEquipmentInformation {
  furniture: Nullable<boolean>
  indoorExtras: IndoorExtra[]
  outdoorExtras: OutdoorExtra[]
}
