<template>
  <footer class="footer">
    <div class="button-container">
      <Loader v-if="loading" />
      <template v-else-if="isCompleted">
        <Button @click="emit('finish')" data-test-id="continueOnboardingButton">
          {{ t('property.onboarding.finish') }}
        </Button>
      </template>
      <template v-else>
        <p class="exit-text" @click="emit('exit')">{{ t('property.onboarding.saveAndExit') }}</p>
        <Button variant="secondary" @click="emit('continue')" data-test-id="continueOnboardingButton">
          {{ t('property.onboarding.continue') }}
        </Button>
      </template>
    </div>
  </footer>
</template>
<script setup lang="ts">
import { Button, Loader } from '@/components'
import { mustAcceptTheTermsAndConditions, type Landlord } from '@/modules/landlord/domain/landlord'
import { hasLandlordDocuments } from '@/modules/landlord/domain/landlordDocuments'
import { isPropertyComplete, type Property } from '@/modules/property/domain/property'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  property: Property
  landlord: Landlord
  loading: boolean
}>()

const emit = defineEmits(['continue', 'finish', 'exit'])

const { t } = useI18n()

const isCompleted = computed(
  () =>
    isPropertyComplete(props.property) &&
    hasLandlordDocuments(props.landlord) &&
    !mustAcceptTheTermsAndConditions(props.landlord)
)
</script>
<style lang="sass" scoped>
.footer
  border-top: 1px solid $grey200
  padding: $paddingS 0
  width: 100%
  background-color: $yellow500
  position: relative
  z-index: 7

.button-container
  display: flex
  gap: 2rem
  align-items: center
  justify-content: center
  margin: 0 $paddingS
  padding: 0 $paddingXL
  height: 3.25rem

  @media only screen and (max-width: 768px)
    flex-direction: column-reverse
    height: auto
    gap: 1rem

.exit-text
  font-size: 16px
  color: $grey500
  display: flex
  align-items: center
  cursor: pointer
</style>
