import { apiEndpointsV2 as v2 } from '@/config/api/endpoints.v2'
import type { PropertyCommercializationRepository } from '@/modules/propertyCommercializations/domain/propertyCommercializationRepository'
import {
  fromPropertyCommercializationDto,
  type PropertyCommercializationDto
} from '@/modules/propertyCommercializations/infrastructure/apiDto'
import type { ApiService } from '@/modules/shared/domain/api/apiService'
import { asyncMap } from '@/utils/array'

export function apiPropertyCommercializationRepositoryBuilder(
  apiV2Service: ApiService
): PropertyCommercializationRepository {
  return {
    async getAll() {
      const dtos = await apiV2Service.getList<PropertyCommercializationDto>(
        v2.partners().propertyCommercializations().list()
      )

      return asyncMap(dtos, async (dto) => {
        return fromPropertyCommercializationDto(dto)
      })
    }
  }
}
