<template>
  <div class="card mt-1">
    <div class="header-table">
      <h3>{{ t('property.details.documents.title') }}</h3>
      <p class="light">{{ t('property.details.documents.description') }}</p>
    </div>
    <div class="has-overflow-auto">
      <Table :options="{ columns, emptyState }">
        <TableRow v-for="file in files">
          <TableRowItem>
            <p>{{ formatDate(file.createdAt) }}</p>
          </TableRowItem>
          <TableRowItem>
            <div class="flex align-center gap-05">
              <img :src="fileIcons[file.mimeType]" />
              <p>{{ file.name }}</p>
            </div>
          </TableRowItem>
          <TableRowItem>
            <a :href="file.url" target="_blank" @click="handleDownloadDocument">
              <img :src="icons.cloudDownload" />
            </a>
          </TableRowItem>
        </TableRow>
      </Table>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { Table, TableRow, TableRowItem } from '@/components'
import { icons } from '@/config/assets/icons'
import { useDates } from '@/hooks/useDates'
import type { RentalContractFile } from '@/modules/rentalContract/domain/rentalContract'
import { MimeType } from '@/modules/shared/domain/document/mimeType'
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { useAnalytics } from '@/hooks/useAnalytics'
import { findCurrentUser } from '@/modules/user/application'
import { UserType } from '@/modules/user/domain/user'

defineProps<{ files: RentalContractFile[] }>()

const { t } = useI18n()
const { formatDate } = useDates()
const { track } = useAnalytics()

const fileIcons: Record<MimeType, string> = {
  [MimeType.JPG]: icons.fileJpg,
  [MimeType.JPEG]: icons.fileJpeg,
  [MimeType.PNG]: icons.filePng,
  [MimeType.PDF]: icons.filePdf
}

const columns = computed(() => [
  {
    name: t('common.date'),
    width: 120
  },
  {
    name: t('property.details.documents.document')
  },
  {
    name: t('common.actions'),
    width: 100
  }
])

const emptyState = computed(() => ({
  icon: icons.document,
  title: t('property.details.documents.empty.title'),
  description: t('property.details.documents.empty.description')
}))

const trackByUserType = (userType: UserType) => {
  switch (userType) {
    case UserType.Landlord:
      track(AnalyticsEvent.LandlordPropertyDetailDocuments)
      break
    case UserType.Partner:
      track(AnalyticsEvent.PartnerPropertyDetailDocuments)
      break
    default:
      break
  }
}

const handleDownloadDocument = async () => {
  const { type } = await findCurrentUser()
  switch (type) {
    case UserType.Landlord:
      track(AnalyticsEvent.LandlordPropertyDetailDownloadDocument)
      break
    case UserType.Partner:
      track(AnalyticsEvent.PartnerPropertyDetailDownloadDocuments)
      break
    default:
      break
  }
}

onMounted(async () => {
  const { type } = await findCurrentUser()
  trackByUserType(type)
})
</script>
<style scoped lang="sass">
.card
  padding: 0

.header-table
  padding: 1.25rem

.has-overflow-auto
  overflow: auto
</style>
