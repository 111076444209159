import type { AuthService } from '@/modules/auth/domain/authService'
import type { ApiService } from '@/modules/shared/domain/api/apiService'
import axios, { type InternalAxiosRequestConfig } from 'axios'
import * as Sentry from '@sentry/vue'

export function axiosApiServiceBuilder(baseUrl: string, authService: AuthService): ApiService {
  const instance = axios.create({ baseURL: baseUrl })

  instance.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    const token: string | null = await authService.getUserToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
      if (!config.headers['Content-Type']) {
        config.headers['Content-Type'] = 'application/json'
        config.headers.Accept = 'application/json'
      }
    }
    return config
  })

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status >= 500) {
        Sentry.captureException(error.response.data)
      }
      return Promise.reject(error)
    }
  )

  return {
    async get<T>(url: string): Promise<T> {
      const response = await instance.get<T>(url)
      return response.data
    },
    async getList<T>(url: string): Promise<T[]> {
      const response = await instance.get<{ data: T[] }>(url)
      return response.data.data
    },
    async patch<T>(url: string, data: Record<string, unknown>): Promise<T> {
      const response = await instance.patch<T>(url, data)
      return response.data
    },
    async post<T>(url: string, data: Record<string, unknown>): Promise<T> {
      const response = await instance.post<T>(url, data)
      return response.data
    },
    async put<T>(url: string, data: Record<string, unknown>): Promise<T> {
      const response = await instance.put<T>(url, data)
      return response.data
    },
    async delete<T>(url: string): Promise<T> {
      const response = await instance.delete<T>(url)
      return response.data
    },
    async upload<T>(url: string, file: File): Promise<T> {
      const formData = new FormData()
      formData.append('file', file)

      const response = await instance.post<T>(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      return response.data
    }
  }
}
