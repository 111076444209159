import {
  BasicInformation,
  CoreInformation,
  EquipmentInformation,
  PaymentInformation,
  PropertyDetails,
  PropertyDocuments,
  PropertyPhotos
} from '@/views'
import ResumeOnboarding from '@/views/property/ResumeOnboarding.vue'
import type { RouteRecordRaw } from 'vue-router'

export const propertyRoutes: RouteRecordRaw = {
  path: '/property/:id',
  name: 'property',
  meta: { landlord: true },
  children: [
    {
      path: 'information',
      name: 'coreInformation',
      component: CoreInformation,
      meta: { basicLayout: true, zapierChatbot: true }
    },
    {
      path: 'basic',
      name: 'basicInformation',
      component: BasicInformation,
      meta: { basicLayout: true, zapierChatbot: true }
    },
    {
      path: 'equipment',
      name: 'equipmentInformation',
      component: EquipmentInformation,
      meta: { basicLayout: true, zapierChatbot: true }
    },
    {
      path: 'photos',
      name: 'propertyPhotos',
      component: PropertyPhotos,
      meta: { basicLayout: true, zapierChatbot: true }
    },
    {
      path: 'documents',
      name: 'propertyDocuments',
      component: PropertyDocuments,
      meta: { basicLayout: true, zapierChatbot: true }
    },
    {
      path: 'plan',
      name: 'paymentInformation',
      component: PaymentInformation,
      meta: { basicLayout: true, zapierChatbot: true }
    },
    {
      path: 'resume',
      name: 'resumeOnboarding',
      component: ResumeOnboarding,
      meta: { basicLayout: true, zapierChatbot: true }
    },
    {
      path: 'details',
      name: 'propertyDetails',
      component: PropertyDetails
    }
  ]
}
