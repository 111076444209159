import type { PropertyStatus } from '@/modules/property/domain/propertyStatus'
import type { Uuid } from '@/modules/shared/domain/uuid/uuid'
import type { PropertyBasicInformation } from './propertyBasicInformation'
import { AvailabilityType, CountryState, type PropertyCoreInformation } from './propertyCoreInformation'
import type { PropertyDocuments } from './propertyDocuments'
import type { PropertyEquipmentInformation } from './propertyEquipmentInformation'
import type { PropertyImages } from './propertyImages'
import type { PropertyPaymentInformation } from './propertyPaymentInformation'

export interface Property {
  basicInformation: PropertyBasicInformation
  completed: boolean
  coreInformation: PropertyCoreInformation
  documents: PropertyDocuments
  equipmentInformation: PropertyEquipmentInformation
  id: Uuid
  images: PropertyImages
  paymentInformation: PropertyPaymentInformation
  status: PropertyStatus
}

export function hasBasicInformation(property: Property): boolean {
  const { bathrooms, bedrooms, pets, propertyCondition, propertyType } = property.basicInformation
  return Boolean(bathrooms && bedrooms && propertyCondition && propertyType) && pets !== null
}

export function hasCoreInformation(property: Property): boolean {
  const { availabilityDate, availabilityType, builtSize, catastralReference, floor, street, monthlyPayment } =
    property.coreInformation
  const isAvailabilitySelected =
    availabilityType === AvailabilityType.ExactDate ? Boolean(availabilityDate) : Boolean(availabilityType)
  return (
    isAvailabilitySelected &&
    Boolean(monthlyPayment) &&
    (Boolean(catastralReference) || Boolean(builtSize && floor && street))
  )
}

export function hasEquipmentInformation(property: Property): boolean {
  return property.equipmentInformation.furniture !== null
}

export function hasPropertyDocuments(property: Property): boolean {
  return (
    property.documents.energyCertificate.length > 0 &&
    (!isHabitabilityCertificateRequired(property) || property.documents.habitabilityCertificate.length > 0) &&
    property.documents.propertyExpenses.length > 0 &&
    property.documents.utilityBill.length > 0
  )
}

export function hasPropertyImages(property: Property): boolean {
  return property.images.files.length > 0 || Boolean(property.images.needsPhotoService)
}

export function hasPaymentInformation(property: Property): boolean {
  return property.paymentInformation.plan !== null
}

export function isPropertyComplete(property: Property): boolean {
  return (
    hasBasicInformation(property) &&
    hasCoreInformation(property) &&
    hasEquipmentInformation(property) &&
    hasPropertyDocuments(property) &&
    hasPropertyImages(property) &&
    hasPaymentInformation(property)
  )
}

export function isHabitabilityCertificateRequired(property: Property): boolean {
  const { state } = property.coreInformation
  return (
    state === null ||
    state === CountryState.Catalonia ||
    state === CountryState.BalearicIslands ||
    state === CountryState.ValencianCommunity ||
    state === CountryState.Cantabria ||
    state === CountryState.Asturias ||
    state === CountryState.Murcia ||
    state === CountryState.Navarre ||
    state === CountryState.LaRioja ||
    state === CountryState.Extremadura
  )
}

export function requiredPropertyDocumentTypes(property: Property): (keyof PropertyDocuments)[] {
  if (isHabitabilityCertificateRequired(property)) {
    return ['energyCertificate', 'habitabilityCertificate', 'propertyExpenses', 'utilityBill']
  } else {
    return ['energyCertificate', 'propertyExpenses', 'utilityBill']
  }
}
