export const esMessages = {
  'application.details.description':
    'Aquí puedes consultar la documentación que has enviado para la solicitud de la propiedad. Esta sección es solo de lectura. Si deseas realizar modificaciones, por favor, contáctanos.',
  'application.details.documents.title': 'Documentación requerida',
  'application.details.tenant.coTenant': 'Co-inquilino',
  'application.details.tenant.email': 'Correo electrónico',
  'application.details.tenant.employmentStatus': 'Situación laboral',
  'application.details.tenant.mainTenant': 'Inquilino principal',
  'application.details.tenant.name': 'Nombre',
  'application.details.tenant.phone': 'Teléfono',
  'application.details.tenant.surname': 'Apellidos',
  'application.details.title': 'Documentación enviada',
  'application.documentation.back': 'Solicitar propiedad',
  'application.documentation.description':
    'Para continuar con el proceso, necesitamos que subas la documentación de todos los inquilinos que vivirán en la propiedad. Una vez que hayas enviado todos los documentos, los revisaremos para validar su solvencia. Nos pondremos en contacto contigo por teléfono para informarte si todo está correcto.',
  'application.documentation.documents': 'Documentos',
  'application.documentation.pending': 'Pendientes',
  'application.documentation.title': 'Documentación requerida de inquilinos',
  'application.documentationCompleted.bold':
    'Has enviado con éxito tu solicitud para una de las propiedades de Vivara.',
  'application.documentationCompleted.finish': 'Llévame a mi página de solicitudes',
  'application.documentationCompleted.main':
    'Ahora revisaremos la información y te informaremos con los pasos a seguir.',
  'application.requiredDocumentation': 'Documentación necesaria',
  'application.saveAndExit': 'Guardar y salir',
  'application.send': 'Enviar',
  'applications.accountSuccess.description':
    'Has realizado una solicitud para una de nuestras propiedades a través de Idealista. Para completar el proceso, sube la documentación necesaria para verificar la solvencia y morosidad de todos los inquilinos.',
  'applications.accountSuccess.question': '¿Quieres continuar con este proceso ahora?',
  'applications.accountSuccess.title': 'Tu cuenta en Vivara ha sido creada con éxito.',
  'applications.accountSuccessEmpty.bold': 'Pronto recibirás un email de confirmación con más detalles.',
  'applications.accountSuccessEmpty.main': 'Tu cuenta en Vivara ha sido creada con éxito.',
  'applications.applied': 'Documentación enviada',
  'applications.completeDocumentation': 'Completar documentación',
  'applications.completeLater': 'Completar más tarde',
  'applications.empty.description':
    'Puedes explorar las propiedades disponibles en la sección “Propiedades Vivara” para realizar una solicitud de visita.',
  'applications.empty.title': 'Actualmente, no tienes ninguna solicitud activa para propiedades de Vivara.',
  'applications.myApplications': 'Mis solicitudes',
  'applications.pendingToSend': 'Pendiente de envío',
  'applications.request': 'Continuar solicitud',
  'auth.logOut': 'Cerrar sesión',
  'auth.privacyPolicy': 'Política de Privacidad',
  'auth.signIn': 'Accede a tu cuenta',
  'auth.signInWithGoogle': 'Continuar con Google',
  'auth.signUp': 'Registro',
  'auth.signUpLink': 'Crea una cuenta',
  'auth.signUpPrompt': '¿No tienes cuenta?',
  'auth.termsAndConditions': 'Términos y Condiciones de uso de la Plataforma',
  'auth.welcome': 'Bienvenido/a a Vivara',
  'authenticationError.invalidCredentials': 'El correo electrónico o la contraseña son incorrectos',
  'authenticationError.userAlreadyExists': 'Ya existe una cuenta asociada a ese correo electrónico',
  'common.accept': 'Aceptar',
  'common.actions': 'Acciones',
  'common.address': 'Dirección',
  'common.cancel': 'Cancelar',
  'common.catastralReference': 'Referencia catastral',
  'common.congratulations': '¡Enhorabuena!',
  'common.continue': 'Continuar',
  'common.date': 'Fecha',
  'common.delete': 'Borrar',
  'common.floor': 'Planta',
  'common.month': 'mes',
  'common.or': 'o',
  'common.property.availability.exact-date': 'Conozco la fecha exacta',
  'common.property.availability.less-than-one-month': 'Menos de un mes',
  'common.property.availability.more-than-three-months': 'Más de 3 meses',
  'common.property.availability.one-to-three-months': 'De 1 a 3 meses',
  'common.property.bathrooms': 'Baños',
  'common.property.bedrooms': 'Dormitorios',
  'common.property.indoorExtras.air-conditioning': 'Aire acondicionado',
  'common.property.indoorExtras.alarm': 'Alarma',
  'common.property.indoorExtras.dishwasher': 'Lavavajillas',
  'common.property.indoorExtras.dryer': 'Secadora',
  'common.property.indoorExtras.fridge': 'Nevera',
  'common.property.indoorExtras.microwave': 'Microondas',
  'common.property.indoorExtras.oven': 'Horno',
  'common.property.indoorExtras.stove': 'Vitrocerámica',
  'common.property.indoorExtras.washing-machine': 'Lavadora',
  'common.property.listed': 'Registrada',
  'common.property.newProperty': 'Nueva propiedad',
  'common.property.outdoorExtras.balcony': 'Balcón',
  'common.property.outdoorExtras.communityGarage': 'Garaje comunitario',
  'common.property.outdoorExtras.lift': 'Ascensor',
  'common.property.outdoorExtras.privateGarage': 'Garaje privado',
  'common.property.outdoorExtras.storageRoom': 'Trastero',
  'common.property.outdoorExtras.terrace': 'Terraza',
  'common.property.state.good': 'Bien',
  'common.property.state.needs-refurbish': 'Para reformar',
  'common.property.state.new': 'Nuevo',
  'common.property.state.very-good': 'Muy bien',
  'common.property.status.in-progress': 'En curso',
  'common.property.status.in-visits': 'En visitas',
  'common.property.status.listed': 'Publicada',
  'common.property.status.registered': 'Registrada',
  'common.property.status.rented': 'Alquilada',
  'common.property.status.reserved': 'Reservada',
  'common.property.type.attachedHouse': 'Adosado',
  'common.property.type.detachedHouse': 'Casa',
  'common.property.type.duplex': 'Duplex',
  'common.property.type.flat': 'Piso',
  'common.property.type.penthouse': 'Ático',
  'common.rooms': 'Habitación',
  'common.roomsAbbreviation': 'hab.',
  'common.showLess': 'Mostrar menos',
  'common.showMore': 'Mostrar más',
  'common.signIn': 'Accede',
  'footer.copyright': 'Copyright © {0}. Todos los derechos reservados',
  'footer.registry': "Registre d'agents immobiliaris de Catalunya aicat 12678",
  'forms.accountHolder': 'Titular de la cuenta',
  'forms.company': 'Empresa',
  'forms.companyPlaceholder': 'Nombre de tu agencia inmobiliaria',
  'forms.email': 'Correo electrónico',
  'forms.emailPlaceholder': "ejemplo{'@'}correo.es",
  'forms.fileInputBrowse': 'elige',
  'forms.fileInputFile': 'Arrastra y suelta ficheros o',
  'forms.fileInputImage': 'Arrastra y suelta imágenes o',
  'forms.fileInputInvalidFileCount': 'Número máximo de ficheros alcanzado',
  'forms.fileInputInvalidFormat': 'Alguno de los ficheros no tiene un formato válido',
  'forms.fileInputInvalidSize': 'Alguno de los ficheros excede el tamaño permitido',
  'forms.fileInputMax': 'ficheros máximo',
  'forms.fileInputMaxSize': 'Tamaño máximo {0}MB',
  'forms.name': 'Nombre',
  'forms.namePlaceholder': 'Tu nombre',
  'forms.password': 'Contraseña',
  'forms.passwordPlaceholder': 'Contraseña',
  'forms.phone': 'Número de teléfono',
  'forms.phonePlaceholder': 'Tu número de teléfono',
  'forms.surname': 'Apellidos',
  'forms.surnamePlaceholder': 'Tus apellidos',
  'genericError.unexpected': 'Algo salió mal',
  'landlord.documents.description.id': '(documento de identidad)',
  'landlord.documents.type.id': 'NIF/NIE',
  'landlord.documents.uploadInstructions': 'Formatos admitidos: pdf, png, jpg.',
  'landlordBilling.invoice.subtitle': 'Liquidaciones y facturas generadas para tus propiedades.',
  'landlordBilling.invoice.title': 'Historial de Facturación',
  'landlordBilling.table.columns.amount': 'Importe',
  'landlordBilling.table.columns.date': 'Fecha',
  'landlordBilling.table.columns.document': 'Documento',
  'landlordBilling.table.columns.property': 'Propiedad',
  'landlordBilling.table.columns.type': 'Tipo',
  'landlordBilling.table.documentNotAvailable': 'No disponible',
  'landlordBilling.table.emptyState.description': 'Aún no se han generado facturas para tus propiedades.',
  'landlordBilling.table.emptyState.title': 'No hay facturas disponibles',
  'landlordBilling.title': 'Facturación',
  'landlordBilling.tooltip.invoice': 'Factura: detalla solo los servicios facturados por Vivara.',
  'landlordBilling.tooltip.liquidation': 'Liquidación: incluye ingresos y todos los gastos de la propiedad.',
  'landlordBilling.tooltip.title': 'Tipos de documentos',
  'landlordBilling.type.invoice': 'Factura',
  'landlordBilling.type.liquidation': 'Liquidación',
  'listings.applied': 'Propiedad solicitada',
  'listings.idealistaLink': 'Ver en Idealista',
  'listings.instructions.step1.description': `Para solicitar una visita, selecciona la propiedad que te interesa y completa la solicitud subiendo la documentación requerida para validar la solvencia y morosidad de cada inquilino. 

Una vez que hayamos verificado toda la documentación, nos pondremos en contacto contigo para coordinar la visita.`,
  'listings.instructions.step1.title': '¿Cómo solicitar una visita a una propiedad?',
  'listings.instructions.step2.description': `Puedes consultar la información básica de la propiedad en la plataforma Vivara.  

Si deseas ver los detalles completos, dirígete a la sección “Propiedades Vivara” y haz clic en “Ver en Idealista” en la propiedad que te interese. Allí tendrás acceso a toda la información disponible.`,
  'listings.instructions.step2.title': '¿Dónde ver los detalles de la propiedad?',
  'listings.request': 'Solicitar',
  'listings.title': 'Propiedades Vivara',
  'partner.commercialization': 'Comercialización',
  'partner.creationDate': 'Fecha de creación',
  'partner.empty.description':
    'Haz crecer tu cartera de propiedades ofreciendo a tus clientes nuestra gestión integral y comienza a generar ingresos pasivos con Vivara.',
  'partner.empty.title': 'Actualmente no tienes ninguna propiedad gestionada con Vivara.',
  'partner.landlord': 'Propietario/a',
  'partner.management': 'Gestión',
  'partner.monthlyPaymentNotDefined': 'Mensualidad alquiler',
  'partner.planNotSelected': 'Plan y/o Servicio Vivara',
  'partner.properties': 'propiedad | propiedades',
  'partner.solvency': 'Solvencia y morosidad',
  'partner.sortedBy': 'Ordenadas por:',
  'partner.tenant': 'Inquilino/a',
  'partner.tenantsEmpty': 'Aún no hay inquilinos asignados a esta propiedad.',
  'partner.title': 'Propiedades',
  'partnerCandidature.list.columns.createdAt': 'Registrada',
  'partnerCandidature.list.columns.property': 'Propiedad',
  'partnerCandidature.list.columns.status': 'Estado',
  'partnerCandidature.list.description': 'Propiedades gestionadas y su estado en el proceso de comercialización.',
  'partnerCandidature.list.empty.description':
    'Aquí verás las propiedades que has comercializado y su estado en el proceso.',
  'partnerCandidature.list.empty.title': 'No hay propiedades en comercialización',
  'partnerCandidature.list.title': 'Propiedades en comercialización',
  'partnerCandidature.candidatures': 'propiedad | propiedades',
  'partnerCandidature.sentDate': 'Fecha de creación',
  'partnerCandidature.sortedBy': 'Ordenadas por:',
  'partnerCandidature.title': 'Candidaturas',
  'partnerBilling.invoice.title': 'Historial de Facturación',
  'partnerBilling.invoice.subtitle':
    'Resumen de ingresos generados por tu colaboración con Vivara. Los importes mostrados no incluyen IVA.',
  'partnerBilling.table.columns.amount': 'Importe',
  'partnerBilling.table.columns.date': 'Fecha',
  'partnerBilling.table.columns.property': 'Propiedad',
  'partnerBilling.table.columns.concept': 'Concepto',
  'partnerBilling.table.columns.collaborationType': 'Tipo de colaboración',
  'partnerBilling.table.documentNotAvailable': 'No disponible',
  'partnerBilling.table.emptyState.description':
    'Aquí aparecerán los ingresos generados por tu colaboración con Vivara. Cuando estén disponibles, podrás ver el detalle de cada concepto y su origen.',
  'partnerBilling.table.emptyState.title': 'Aún no hay ingresos registrados',
  'partnerBilling.title': 'Facturación',
  'partnerProperty.information.monthlyAndRent': 'Mensualidad y Renta',
  'partnerProperty.information.monthlyAndRentNotDefined': 'El precio del alquiler aún no ha sido definido.',
  'partnerProperty.information.planOrService': 'Plan o Servicio',
  'partnerProperty.information.planOrServiceNotSelected': 'El propietario aún no ha seleccionado un plan.',
  'partnerProperty.information.rent': 'Renta',
  'partnerProperty.tabs.communication': 'Comunicación',
  'partnerProperty.tabs.contract': 'Contrato y servicios',
  'partnerProperty.tabs.documentation': 'Documentación',
  'partnerProperty.tabs.information': 'Propiedad',
  'partnerProperty.tabs.timeline': 'Estado propiedad',
  'partnerProperty.title': 'Detalle de la propiedad',
  'partnerReferral.callToAction.action': 'Enviar lead',
  'partnerReferral.callToAction.description':
    '¿Tienes un cliente interesado en alquilar su propiedad? Envíanos sus datos y nosotros nos encargamos de ofrecerle nuestros servicios.',
  'partnerReferral.callToAction.title': 'Aumenta tus ganancias',
  'partnerReferral.list.columns.createdAt': 'Enviado',
  'partnerReferral.list.columns.email': 'Correo',
  'partnerReferral.list.columns.landlord': 'Propietario',
  'partnerReferral.list.columns.phone': 'Teléfono',
  'partnerReferral.list.columns.status': 'Estado',
  'partnerReferral.list.description': 'Propietarios enviados a Vivara y su estado en captación.',
  'partnerReferral.list.empty.description':
    'Aquí verás los propietarios que envíes a Vivara y su estado en el proceso de captación. Envía un lead para empezar.',
  'partnerReferral.list.empty.title': 'Aún no has enviado ningún lead',
  'partnerReferral.list.title': 'Estado de leads',
  'partnerReferral.referrals': 'lead | leads',
  'partnerReferral.send.action': 'Enviar lead',
  'partnerReferral.send.confirmation':
    'Confirmo que cuento con los permisos necesarios del propietario para compartir su información con Vivara y que esta será utilizada exclusivamente para ofrecer los servicios de gestión de alquiler. *',
  'partnerReferral.send.description':
    'Facilita los datos de tu cliente y nosotros nos encargamos de ofrecerle nuestros servicios de gestión de alquiler.',
  'partnerReferral.send.namePlaceholder': 'Nombre del propietario',
  'partnerReferral.send.phonePlaceholder': '737689023',
  'partnerReferral.send.success.close': 'Entendido',
  'partnerReferral.send.success.description':
    'Hemos recibido la información del propietario y nuestro equipo la revisará pronto. Puedes seguir su estado en la sección de Leads.',
  'partnerReferral.send.success.title': '¡Lead enviado con éxito!',
  'partnerReferral.send.surnamePlaceholder': 'Apellido Apellido',
  'partnerReferral.send.title': 'Enviar lead',
  'partnerReferral.sentDate': 'Fecha de envío',
  'partnerReferral.sortedBy': 'Ordenadas por:',
  'partnerReferral.status.activated': 'Asignado',
  'partnerReferral.status.contacted': 'Contactado',
  'partnerReferral.status.discarded': 'Descartado',
  'partnerReferral.status.sent': 'Enviado',
  'partnerReferral.title': 'Leads',
  'profile.help.contact': 'Contáctanos',
  'profile.help.description':
    'Si necesitas asistencia, estamos a un clic de distancia. Contáctanos y estaremos encantados de ayudarte con lo que necesites.',
  'profile.help.title': 'Ayuda',
  'profile.password.changePassword': 'Cambiar contraseña',
  'profile.password.changePasswordConfirmation': 'Su contraseña ha sido cambiada con éxito.',
  'profile.password.confirmNewPassword': 'Confirma la nueva contraseña',
  'profile.password.confirmNewPasswordPlaceholder': 'Vuelve a escribir la contraseña',
  'profile.password.currentPassword': 'Contraseña actual',
  'profile.password.currentPasswordPlaceholder': 'Introduce tu contraseña actual',
  'profile.password.description': 'Tu nueva contraseña debe ser diferente de las contraseñas utilizadas anteriormente.',
  'profile.password.invalidCurrentPassword':
    'La contraseña actual es incorrecta, si no la recuerdas puedes restablecerla cerrando sesión y pulsando en "¿Has olvidado tu contraseña?"',
  'profile.password.newPassword': 'Nueva contraseña',
  'profile.password.newPasswordPlaceholder': 'Agrega una nueva contraseña',
  'profile.password.title': 'Cambiar contraseña',
  'profile.settings.deleteAccount': 'Eliminar tu cuenta',
  'profile.settings.deleteAccountConfirmation.bold': 'Eliminar tu cuenta es una acción permanente.',
  'profile.settings.deleteAccountConfirmation.cancel': 'Cancelar y volver',
  'profile.settings.deleteAccountConfirmation.confirm': 'Eliminar cuenta',
  'profile.settings.deleteAccountConfirmation.description':
    'Esto borrará todos tus datos, y no podrás recuperar tu cuenta ni la información asociada. Si estás seguro de continuar, confirma a continuación.',
  'profile.settings.email': 'Correo electrónico de contacto',
  'profile.settings.phone': 'Teléfono de contacto',
  'profile.settings.save': 'Guardar cambios',
  'profile.settings.title': 'Ver mi perfil',
  'properties.accountSuccess.bold':
    '¿Te gustaría continuar añadiendo las características de tu propiedad para publicarla?',
  'properties.accountSuccess.main': 'Has completado la creación de tu cuenta.',
  'properties.addProperty': 'Añadir una propiedad',
  'properties.characteristicsSuccess.bold':
    '¿Te gustaría continuar subiendo la documentación requerida para registrar tu propiedad? ',
  'properties.characteristicsSuccess.main': 'Has completado las características de tu propiedad.',
  'properties.completeLater': 'Completar más tarde',
  'properties.continueRegistration': 'Continuar con el registro',
  'properties.creationDate': 'Fecha de creación',
  'properties.onboardingCompleted.bold':
    'Has finalizado con éxito el registro de tu propiedad y seleccionado un Plan para tu propiedad.',
  'properties.onboardingCompleted.finish': 'Llévame a mi página de propiedades',
  'properties.onboardingCompleted.main':
    'Ahora revisaremos la información y te informaremos cuando esté publicada y los pasos a seguir.',
  'properties.properties': 'propiedad | propiedades',
  'properties.sortedBy': 'Ordenadas por:',
  'properties.title': 'Mis propiedades',
  'property.basic.pets': '¿Se aceptan mascotas?',
  'property.basic.petsAllowed': 'Sí, la propieda acepta mascotas',
  'property.basic.petsNotAllowed': 'No, la propiedad no acepta mascotas',
  'property.basic.roomInformation.bathroom': 'Especificaciones del baño',
  'property.basic.roomInformation.bathroomDescription':
    'Se deben contar los inodoros como baño independiente si están ubicados fuera del baño principal.',
  'property.basic.roomInformation.bedroom': 'Especificaciones del dormitorio',
  'property.basic.roomInformation.bedroomDescription':
    'Según la ley, una habitación debe tener al menos 5m2 para considerarse dormitorio.',
  'property.basic.rooms': 'Cuántas habitaciones hay en tu propiedad',
  'property.basic.state': 'Selecciona la opción que describa mejor el estado de tu propiedad',
  'property.basic.step': 'Datos generales',
  'property.basic.title.bold': 'básicas.',
  'property.basic.title.main': 'Por favor, indícanos las características {0}',
  'property.basic.type': 'Elige la opción que mejor describa el tipo de propiedad',
  'property.characteristics.step': 'Características de tu propiedad',
  'property.core.addReference': 'Añadir referencia',
  'property.core.addressPlaceholder': 'Dirección de la vivienda',
  'property.core.availabilityDate': 'Fecha de disponibilidad',
  'property.core.availabilityTitle.bold': 'propiedad estará disponible.',
  'property.core.availabilityTitle.main': 'Por favor, indica el primer día en el que tu {0}',
  'property.core.availabilityType': 'Disponibilidad de la propiedad',
  'property.core.availabilityTypePlaceholder': 'Seleccionar disponibilidad',
  'property.core.catastralReferenceError': 'No hemos podido encontrar la propiedad con esta referencia catastral',
  'property.core.catastralReferenceInformation':
    'El número de referencia catastral es un código de 20 cifras que se encuentra en la Sede Electrónica del Catastro',
  'property.core.catastralReferencePlaceholder': '1111111XXXXXXX1111XX',
  'property.core.firstRent': 'Propiedad disponible en alquiler por primera vez',
  'property.core.floorPlaceholder': 'Planta de la vivienda',
  'property.core.manualAddressToggle': 'No tengo referencia catastral, introduciré los datos manualmente',
  'property.core.monthlyPayment': 'Renta mensual máxima',
  'property.core.rentTitle.bold': 'renta mensual?',
  'property.core.rentTitle.main': '¿Cuál será tu {0}',
  'property.core.step': 'Datos de tu propiedad',
  'property.core.surface': 'Superficie',
  'property.core.surfacePlaceholder': 'Superficie de la vivienda',
  'property.core.title.bold': 'datos de tu propiedad.',
  'property.core.title.main': 'Añade los {0}',
  'property.core.usableSurface': 'Superficie útil',
  'property.core.usableSurfacePlaceholder': 'Superficie útil de la vivienda',
  'property.core.usableSurfaceToggle': 'Es superficie útil',
  'property.details.documents.description': 'Listado de documentos vinculados a la propiedad.',
  'property.details.documents.document': 'Documento',
  'property.details.documents.empty.description':
    'Aún no hay documentos asociados a esta propiedad. Se mostrarán aquí en cuanto estén disponibles.',
  'property.details.documents.empty.title': 'Información no disponible',
  'property.details.documents.title': 'Documentación',
  'property.details.rent': 'Renta',
  'property.details.solvency': 'Solvencia y morosidad',
  'property.details.tabs.contract': 'Contrato y servicios',
  'property.details.tabs.documentation': 'Documentación',
  'property.details.tabs.information': 'Propiedad',
  'property.details.tabs.timeline': 'Estado propiedad',
  'property.details.tenant': 'Inquilino/a',
  'property.details.tenantsEmpty': 'Aún no hay inquilinos asignados a esta propiedad.',
  'property.details.timeline.status.in-visits.description':
    'Estamos coordinando y realizando visitas con potenciales inquilinos interesados en la propiedad.',
  'property.details.timeline.status.in-visits.title': 'En visitas',
  'property.details.timeline.status.listed.description':
    'La propiedad ya está visible en los portales de alquiler y en nuestra red para captar posibles inquilinos.',
  'property.details.timeline.status.listed.title': 'Publicada',
  'property.details.timeline.status.registered.description':
    'La propiedad ha sido registrada en nuestra plataforma y estamos preparando la información necesaria para iniciar la gestión del alquiler.',
  'property.details.timeline.status.registered.title': 'Registrada',
  'property.details.timeline.status.rented.description':
    'La propiedad ya cuenta con inquilino/s y el contrato de alquiler ha sido formalizado exitosamente.',
  'property.details.timeline.status.rented.title': 'Alquilada',
  'property.details.timeline.status.reserved.description':
    'La propiedad ha sido reservada por un inquilino y estamos en proceso de formalizar el contrato.',
  'property.details.timeline.status.reserved.title': 'Reservada',
  'property.details.timeline.title': 'Estado de la propiedad',
  'property.details.title': 'Detalle de la propiedad',
  'property.documents.description.energyCertificate': '',
  'property.documents.description.habitabilityCertificate': '',
  'property.documents.description.propertyExpenses': '(IBI, Comunidad de propietarios, tasa de residuos, vado, etc)',
  'property.documents.description.utilityBill': '(agua, luz y gas)',
  'property.documents.step': 'Documentación requerida',
  'property.documents.title.bold': 'documentos requiridos.',
  'property.documents.title.main': 'Por favor, añade los {0}',
  'property.documents.type.energyCertificate': 'Certificado energético',
  'property.documents.type.habitabilityCertificate': 'Cédula de habitabilidad',
  'property.documents.type.propertyExpenses': 'Gastos de la propiedad',
  'property.documents.type.utilityBill': 'Factura de suministros',
  'property.documents.uploadInstructions': 'Formatos admitidos: pdf, png, jpg.',
  'property.equipment.furnished': 'Amueblado',
  'property.equipment.furnishedSubtitle': 'La propiedad se alquilará con muebles',
  'property.equipment.furnitureTitle.bold': 'mobiliario de tu propiedad',
  'property.equipment.furnitureTitle.main': '¿Cuál es el estado del {0}?',
  'property.equipment.indoorTitle.bold': 'extras de interior.',
  'property.equipment.indoorTitle.main': 'Añade detalles sobre tus {0}',
  'property.equipment.indoorToggle': 'Estoy alquilando mi propiedad sin extras de interior.',
  'property.equipment.notFurnished': 'Sin amueblar',
  'property.equipment.notFurnishedSubtitle': 'La propiedad se alquilará sin muebles',
  'property.equipment.outdoorTitle.bold': 'extras de exterior.',
  'property.equipment.outdoorTitle.main': 'Añade detalles sobre tus {0}',
  'property.equipment.outdoorToggle': 'Estoy alquilando mi propiedad sin extras de exterior.',
  'property.equipment.step': 'Equipamiento y extras',
  'property.images.professionalPhotoService':
    'Estoy interesado en el servicio de fotografía profesional de propiedades de Vivara.',
  'property.images.step': 'Galería de imágenes',
  'property.images.title.bold': 'imágenes.',
  'property.images.title.main': 'Muéstranos toda tu propiedad en su estado actual añadiendo {0}',
  'property.images.uploadInstructions': 'Formatos admitidos: png, jpg, jpeg.',
  'property.onboarding.continue': 'Continuar',
  'property.onboarding.finish': 'Finalizar',
  'property.onboarding.saveAndExit': 'Guardar y salir',
  'property.onboarding.title': 'Registra tu propiedad',
  'property.payment.basic.description': 'Alquila tu propiedad con un proceso simple, seguro y sin preocupaciones.',
  'property.payment.basic.name': 'Plan Básico',
  'property.payment.basic.price': '0,00€',
  'property.payment.commercialization.description':
    'Comercializamos tu propiedad y encontramos a tu inquilino perfecto.',
  'property.payment.commercialization.name': 'Búsqueda de inquilino perfecto',
  'property.payment.commercialization.price': '1 mes de alquiler',
  'property.payment.commercialization.shortPrice': '1 mes',
  'property.payment.comfort.description': 'Nos ocupamos de todo: incidencias, reparaciones, emergencias, etc.',
  'property.payment.comfort.name': 'Plan Confort',
  'property.payment.comfort.price': '{percentage}% del alquiler mensual',
  'property.payment.features.bailManagement': 'Gestión de fianza y garantías adicionales',
  'property.payment.features.digitalSignature': 'Asistencia en formalización del contrato y firma electrónica',
  'property.payment.features.documentManagement': 'Gestión de documentación requerida (etiqueta, cédula, etc.)',
  'property.payment.features.guarantorRelease': 'Liberación de avalistas',
  'property.payment.features.incidentManagement': 'Gestión de incidencias y reparaciones',
  'property.payment.features.itpManagement': 'Asistencia en la presentación del ITP',
  'property.payment.features.monthlyPayments': 'Pagos mensuales automáticos',
  'property.payment.features.nonPaymentInsurance': 'Asistencia en contratación de un seguro de impago',
  'property.payment.features.professionalPhotos': 'Realización de fotografías profesionales',
  'property.payment.features.solvencyVerification':
    'Análisis de solvencia económica y consulta en ficheros de morosidad',
  'property.payment.features.tenantReplacement':
    'Reemplazo de inquilino sin coste adicional dentro de los primeros 12 meses',
  'property.payment.features.tenantReview': 'Recopilación de información y revisión de perfiles de posibles inquilinos',
  'property.payment.features.utilityTransfers': 'Cambio de suministros',
  'property.payment.features.visitsManagement': 'Visitas al inmueble',
  'property.payment.features.idealistaPublish': 'Publicación de anuncios en plataformas',
  'property.payment.free': 'Gratuito',
  'property.payment.lessDetails': 'Menos información',
  'property.payment.moreDetails': 'Más información',
  'property.payment.nonPaymentCoverage.12months': '12 meses',
  'property.payment.nonPaymentCoverage.18months': '18 meses',
  'property.payment.nonPaymentCoverage.24months': '24 meses',
  'property.payment.nonPaymentCoverage.description': 'cobertura de impagos',
  'property.payment.peaceOfMind.description': 'El plan que lo tiene todo. Seguro de impagos y gestión integral.',
  'property.payment.peaceOfMind.name': 'Plan Tranquilidad',
  'property.payment.peaceOfMind.price': '{percentage}% del alquiler mensual',
  'property.payment.plans': 'Nuestros Planes',
  'property.payment.plusIva': '(más IVA)',
  'property.payment.popular': 'El más popular',
  'property.payment.secured.description': 'Más que una garantía; un seguro. Cobra el 7 de cada mes, pase lo que pase.',
  'property.payment.secured.name': 'Plan Cobro Asegurado',
  'property.payment.secured.price': '{percentage}% del alquiler mensual',
  'property.payment.select': 'Seleccionar',
  'property.payment.services': 'Servicios',
  'property.payment.step': 'Elige tu plan',
  'property.payment.summary.empty':
    'Selecciona al menos un plan o un servicio para continuar con el registro de tu propiedad.',
  'property.payment.summary.free': 'Gratuito',
  'property.payment.summary.title': 'Resumen',
  'property.payment.summary.total': 'Total',
  'property.payment.termsAndConditions.error': 'Debes aceptar los Términos y Condiciones antes de finalizar el proceso',
  'property.payment.termsAndConditions.link': ' Términos y Condiciones de los Servicios de Vivara.',
  'property.payment.termsAndConditions.readAndAccept': 'He leído y acepto los',
  'property.payment.title.description':
    'Elige un plan, un servicio adicional o solo lo que necesites. ¡Tú decides cómo gestionar tu propiedad!',
  'property.payment.title.main': 'Personaliza la gestión de tu propiedad',
  'property.payment.unselect': 'Quitar',
  'propertyContract.collectionDate': 'Fecha cobro propietario',
  'propertyContract.contractualInformation': 'Información contractual',
  'propertyContract.depositBond': 'Depósito fianza',
  'propertyContract.effectiveDate': 'Fecha de vigencia',
  'propertyContract.empty.description':
    'Todavía no tenemos la información contractual y los servicios asociados a esta propiedad. Se mostrará aquí en cuanto esté disponible.',
  'propertyContract.empty.title': 'Información no disponible',
  'propertyContract.expirationDate': 'Vencimiento contrato',
  'propertyContract.nextRentIncrease': 'Próxima subida renta',
  'propertyContract.nonPaymentCoverage': 'Seguro impagos',
  'propertyContract.passedOnExpenses': 'Gastos repercutidos',
  'propertyContract.services': 'Servicios y coberturas',
  'propertyContract.supplyChange': 'Cambio de suministros',
  'signin.recoverPassword.description':
    'Te enviaremos un email con un enlace para que puedas restablecer tu contraseña.',
  'signin.recoverPassword.link': '¿Has olvidado tu contraseña?',
  'signin.recoverPassword.send': 'Restablecer contraseña',
  'signin.recoverPassword.success.description': `Hemos recibido correctamente tu solicitud para restablecer tu contraseña. 
          
          En breve recibirás un email para terminar el proceso. 
          
          ¿No has recibido tu email? Revisa tu carpeta de spam para asegurarte de que el email no está ahi.`,
  'signin.recoverPassword.success.title': 'Solicitud realizada con éxito',
  'signin.recoverPassword.title': 'Restablecer contraseña',
  'signin.resetPassword.description':
    'Tu nueva contraseña debe ser diferente de las contraseñas utilizadas anteriormente.',
  'signin.resetPassword.password': 'Nueva contraseña',
  'signin.resetPassword.passwordConfirmation': 'Confirma la nueva contraseña',
  'signin.resetPassword.passwordConfirmationPlaceholder': 'Vuelve a escribir la contraseña',
  'signin.resetPassword.passwordPlaceholder': 'Agrega una nueva contraseña',
  'signin.resetPassword.save': 'Guardar la nueva contraseña',
  'signin.resetPassword.title': 'Cambiar contraseña',
  'signup.account.commercial':
    'Quiero recibir comunicaciones comerciales y ofertas de Vivara o de terceros adaptadas a mis intereses.',
  'signup.account.landlord.signUpWithEmail': 'Regístrate con correo electrónico',
  'signup.account.landlord.subtitle': '',
  'signup.account.landlord.title.bold': 'información de cuenta.',
  'signup.account.landlord.title.main': 'Añade la {0}',
  'signup.account.partner.signUpWithEmail': 'Crear cuenta',
  'signup.account.partner.subtitle':
    'Asegúrate de utilizar el correo electrónico profesional de tu negocio con el que iniciamos comunicación en Vivara.',
  'signup.account.partner.title.bold': '',
  'signup.account.partner.title.main': 'Crea tu cuenta',
  'signup.account.signInPrompt': '¿Ya tienes una cuenta?',
  'signup.account.step': 'Información de tu cuenta',
  'signup.account.terms': 'Acepto los',
  'signup.account.termsEnd': 'de Vivara Company.',
  'signup.account.tenant.signUpWithEmail': 'Regístrate con correo electrónico',
  'signup.account.tenant.subtitle': '',
  'signup.account.tenant.title.bold': 'información de cuenta.',
  'signup.account.tenant.title.main': 'Añade la {0}',
  'signup.onboarding.title': 'Crear cuenta',
  'signup.personal.landlord.save': 'Continuar',
  'signup.personal.landlord.title.bold': 'datos personales.',
  'signup.personal.landlord.title.main': 'Añade tus {0}',
  'signup.personal.partner.save': 'Finalizar registro',
  'signup.personal.partner.title.bold': '',
  'signup.personal.partner.title.main': 'Completa tus datos personales',
  'signup.personal.step': 'Tus datos personales',
  'signup.personal.tenant.save': 'Continuar',
  'signup.personal.tenant.title.bold': 'datos personales.',
  'signup.personal.tenant.title.main': 'Añade tus {0}',
  'signup.property.step': 'Datos de tu propiedad',
  'signup.type.aLandlord': 'Soy propietario',
  'signup.type.aLandlordSub': 'Estoy buscando inquilino(s) para mi propiedad',
  'signup.type.aTenant': 'Soy inquilino',
  'signup.type.aTenantSub': 'Estoy buscando una propiedad para alquilar',
  'signup.type.step': 'Tipo de usuario',
  'signup.type.title.bold': 'propietario o inquilino?',
  'signup.type.title.main': '¿Eres {0}',
  'signup.welcome': 'Bienvenido/a a Vivara.',
  'tenant.add': 'Añadir otro inquilino',
  'tenant.coTenant': 'Co-inquilino',
  'tenant.delete.bold': '¿Estás seguro de que deseas borrar la información de este inquilino?',
  'tenant.delete.cancel': 'Cancelar y volver',
  'tenant.delete.confirm': 'Eliminar inquilino',
  'tenant.delete.description':
    'Toda la información y documentos subidos para este inquilino se eliminarán de forma permanente. Si estás seguro, haz clic en “Eliminar inquilino”. De lo contrario, puedes cancelar esta acción y mantener los datos.',
  'tenant.delete.title': 'Eliminar inquilino',
  'tenant.documentation': 'Documentación',
  'tenant.documents.employmentContract': 'Contrato laboral',
  'tenant.documents.id': 'DNI/NIE (por las dos caras)',
  'tenant.documents.lastForm100': 'Último modelo 100',
  'tenant.documents.lastForm130': 'Último modelo 130',
  'tenant.documents.payslip': 'Dos últimas nóminas',
  'tenant.documents.pensionRevaluationCertificate': 'Certificado de revalorización de pensiones',
  'tenant.documents.uploadInstructions': 'Formatos admitidos: pdf, png, jpg.',
  'tenant.employmentStatus': 'Situación laboral',
  'tenant.employmentStatusDescription':
    'Asegurate de seleccionar tu situación laboral actual para ver los documentos requeridos.',
  'tenant.employmentStatusNotSelected': 'Selecciona tu situación laboral para ver los documentos que debes subir.',
  'tenant.employmentStatuses.employed': 'Asalariado',
  'tenant.employmentStatuses.retired': 'Jubilado',
  'tenant.employmentStatuses.self-employed': 'Autónomo',
  'tenant.existingTenant.cancel': 'Cambiar email',
  'tenant.existingTenant.confirm': 'Usar datos existentes',
  'tenant.existingTenant.description':
    'El inquilino que intentas añadir, ya tiene una cuenta en Vivara con el email {email}. Podemos utilizar los datos existentes de su cuenta para completar automáticamente parte de la información para la solicitud.',
  'tenant.existingTenant.question':
    'Si prefieres no utilizar los datos existentes, puedes ingresar un email diferente para añadir este inquilino.',
  'tenant.existingTenant.title': 'Inquilino ya registrado',
  'tenant.exitWithoutSavingConfirmation': 'Tienes cambios sin guardar. ¿Seguro que quieres salir?',
  'tenant.mainTenant': 'Inquilino principal',
  'tenant.partialSave.confirm': 'Salir sin documentación',
  'tenant.partialSave.description':
    'Si decides salir ahora, la documentación que hayas subido de todos los co-inquilinos no se guardará, pero sí mantendremos la información relevante de cada uno. Al regresar, tendrás que volver a subir la documentación requerida de cada co-inquilino.',
  'tenant.partialSave.question':
    '¿Estás seguro de que deseas salir del proceso y volver a subir la documentación de los co-inquilinos?',
  'tenant.partialSave.title': 'Guardado parcial de la información',
  'tenant.requiredDocumentation.employed': 'Documentación requerida para asalariados',
  'tenant.requiredDocumentation.retired': 'Documentación requerida para jubilados',
  'tenant.requiredDocumentation.self-employed': 'Documentación requerida para autónomos',
  'validationError.formatEmail': 'Por favor, introduce un email válido',
  'validationError.formatPassword': 'La contraseña debe tener entre 6 y 20 caracteres de longitud',
  'validationError.formatPhone': 'Por favor, introduce un número de teléfono válido',
  'validationError.invalidDate': 'Por favor, introduce una fecha válida',
  'validationError.passwordsDoNotMatch': 'Las contraseñas no coinciden',
  'validationError.required': 'Por favor, rellena esta información',
  'validationError.requiredEmail': 'El correo electrónico es obligatorio',
  'validationError.requiredFullName': 'El nombre y los apellidos son obligatorios',
  'validationError.requiredName': 'El nombre es obligatorio',
  'validationError.requiredPassword': 'La contraseña es obligatoria',
  'validationError.requiredPhone': 'El número de teléfono es obligatorio',
  'validationError.requiredSurname': 'Los apellidos son obligatorios',
  'validationError.requiredTermsAndConditions': 'Debes aceptar los Términos y condiciones'
}
