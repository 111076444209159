<template>
  <div class="container">
    <div class="top flex justify-between align-end gap-05 mb-2">
      <h1>{{ t('partnerCandidature.title') }}</h1>
    </div>
    <hr />
    <div class="flex justify-between wrap gap-1 mt-1" v-if="candidatures.length > 0">
      <div class="flex">
        <p class="label">{{ candidatures.length }}</p>
        &nbsp;
        <p class="light">{{ t('partnerCandidature.candidatures', candidatures.length) }}</p>
      </div>
      <div class="flex">
        <p class="label">{{ t('partnerCandidature.sortedBy') }}</p>
        &nbsp;
        <p class="light">{{ t('partnerCandidature.sentDate') }}</p>
      </div>
    </div>
    <div class="card mt-1">
      <div class="header-table">
        <h3>{{ t('partnerCandidature.list.title') }}</h3>
        <p class="light">{{ t('partnerCandidature.list.description') }}</p>
      </div>
      <div class="has-overflow-auto">
        <Table :loading="loading" :options="{ columns, emptyState }">
          <TableRow v-for="candidature in candidatures">
            <TableRowItem>
              <p>{{ formatDate(candidature.createdAt) }}</p>
            </TableRowItem>
            <TableRowItem>
              <p class="title">{{ candidature.street }}</p>
              <p class="light">
                {{ candidature.zipCode }} <span v-if="candidature.zipCode">,</span> {{ candidature.city }}
              </p>
            </TableRowItem>
            <TableRowItem>
              <PropertyStatusBadge :status="candidature.status" />
            </TableRowItem>
            <TableRowItem>
              <a>
                <img :src="icons.arrowRight" />
              </a>
            </TableRowItem>
          </TableRow>
        </Table>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { PropertyStatusBadge, Table, TableRow, TableRowItem } from '@/components'
import { icons } from '@/config/assets/icons'
import { useDates } from '@/hooks/useDates'
import { findAllPropertyCommercializations } from '@/modules/propertyCommercializations/application'
import type { PropertyCommercialization } from '@/modules/propertyCommercializations/domain/propertyCommercialization'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { formatDate } = useDates()

const candidatures = ref<PropertyCommercialization[]>([])
const loading = ref(true)
const columns = computed(() => [
  {
    name: t('partnerCandidature.list.columns.createdAt'),
    width: 140
  },
  {
    name: t('partnerCandidature.list.columns.property')
  },
  {
    name: t('partnerCandidature.list.columns.status'),
    width: 140
  },
  {
    name: '',
    width: 20
  }
])

const emptyState = computed(() => ({
  icon: icons.key,
  title: t('partnerCandidature.list.empty.title'),
  description: t('partnerCandidature.list.empty.description')
}))

onMounted(loadCandidatures)

async function loadCandidatures() {
  loading.value = true
  candidatures.value = await findAllPropertyCommercializations()
  loading.value = false
}
</script>
<style lang="sass" scoped>
.top .button
  padding: 0.75rem 2rem

.card
  padding: 0

.header-table
  padding: 1.25rem

.has-overflow-auto
  overflow: auto
</style>
