import { apiEndpointsV2 as v2 } from '@/config/api/endpoints.v2'
import type { ApiService } from '@/modules/shared/domain/api/apiService'
import { asyncMap } from '@/utils/array'
import type { ListingRepository } from '../domain/listingRepository'
import { fromListingDto, type ListingDto, type ListingImageDto } from './apiDto'

export function apiListingRepositoryBuilder(apiV2Service: ApiService): ListingRepository {
  return {
    async getAll() {
      const dtos = await apiV2Service.getList<ListingDto>(v2.tenants().properties().list())
      return asyncMap(dtos, async (dto) => {
        const imagesDtos = await apiV2Service.getList<ListingImageDto>(v2.properties().images(dto.id).list())
        return fromListingDto(dto, imagesDtos)
      })
    }
  }
}
