<template>
  <div class="container pb-1">
    <div class="flex gap-05 align-center">
      <RouterLink :to="{ name: 'partnerProperties' }">
        <IconButton :icon="icons.arrowBack" />
      </RouterLink>
      <h2>{{ t('partnerProperty.title') }}</h2>
    </div>
  </div>
  <div class="container small pt-1">
    <template v-if="property">
      <PartnerPropertyInformation v-if="!isMobile" class="mb-1" :property="property" />
      <Tabs class="mb-1" :selected="tab" :options="tabs" @select="tab = $event" />
      <PartnerPropertyInformation v-if="tab === 'information'" :property="property" />
      <PropertyTimeline v-if="tab === 'timeline'" :status="property.status" />
      <PropertyContractInformation
        v-if="tab === 'contract'"
        :pricingPlan="property.pricingPlan"
        :rent="property.rent"
        :monthlyPayment="property.monthlyPayment"
        :contract="contract"
      />
    </template>
    <FormSkeleton v-else />
  </div>
</template>
<script lang="ts" setup>
import {
  FormSkeleton,
  IconButton,
  PartnerPropertyInformation,
  PropertyContractInformation,
  PropertyTimeline,
  Tabs
} from '@/components'
import { icons } from '@/config/assets/icons'
import { findPartnerProperty } from '@/modules/partnerProperty/application'
import { type PartnerProperty } from '@/modules/partnerProperty/domain/partnerProperty'
import { findPropertyContract } from '@/modules/propertyContract/application'
import type { PropertyContract } from '@/modules/propertyContract/domain/propertyContract'
import { router } from '@/router'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const id = router.currentRoute.value.params.id as string

const allTabs = [
  { value: 'timeline', label: t('partnerProperty.tabs.timeline') },
  { value: 'contract', label: t('partnerProperty.tabs.contract') },
  { value: 'documentation', label: t('partnerProperty.tabs.documentation'), disabled: true },
  { value: 'communication', label: t('partnerProperty.tabs.communication'), disabled: true }
]
const mobileTabs = [{ value: 'information', label: t('partnerProperty.tabs.information') }]

const tab = ref('timeline')
const property = ref<Nullable<PartnerProperty>>(null)
const contract = ref<Nullable<PropertyContract>>(null)
const isMobile = ref(window.innerWidth <= 768)
const tabs = computed(() => (isMobile.value ? [...mobileTabs, ...allTabs] : allTabs))

onMounted(async () => {
  contract.value = await findPropertyContract(id)
  property.value = await findPartnerProperty(id)

  if (isMobile.value) {
    tab.value = 'information'
  }
})
</script>
<style scoped lang="sass"></style>
