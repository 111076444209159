<template>
  <div v-if="model">
    <h2>{{ t('property.payment.title.main') }}</h2>
    <p>{{ t('property.payment.title.description') }}</p>
    <Separator class="mt-3 mb-3">{{ t('property.payment.plans') }}</Separator>
    <div class="row" data-test-id="paymentPlanSelector">
      <div class="col col-md-4 col-lg-6 col-xl-6">
        <PricingPlanOption
          :plan="PricingPlan.PeaceOfMind"
          :initialValue="model"
          :compareFeatures="generalPricingPlanFeatures"
          :selected="model.plan === PricingPlan.PeaceOfMind"
          :opened="opened"
          @toggle="opened = !opened"
          @select="onSelect(PricingPlan.PeaceOfMind, $event)"
          @unselect="onUnselect()"
          hasNonPaymentCoverage
          isPopular
        />
        <PricingPlanOption
          :plan="PricingPlan.Comfort"
          :initialValue="model"
          :compareFeatures="generalPricingPlanFeatures"
          :selected="model.plan === PricingPlan.Comfort"
          :opened="opened"
          @toggle="opened = !opened"
          @select="onSelect(PricingPlan.Comfort, null)"
          @unselect="onUnselect()"
        />
      </div>
      <div class="col col-md-4 col-lg-6 col-xl-6">
        <PricingPlanOption
          :plan="PricingPlan.Secured"
          :initialValue="model"
          :compareFeatures="generalPricingPlanFeatures"
          :selected="model.plan === PricingPlan.Secured"
          :opened="opened"
          @toggle="opened = !opened"
          @select="onSelect(PricingPlan.Secured, $event)"
          @unselect="onUnselect()"
          hasNonPaymentCoverage
        />
        <PricingPlanOption
          v-if="basicPlanVisibility"
          :plan="PricingPlan.Basic"
          :initialValue="model"
          :compareFeatures="servicesPricingPlanFeatures"
          :selected="model.plan === PricingPlan.Basic"
          :opened="opened"
          @toggle="opened = !opened"
          @select="onSelect(PricingPlan.Basic, null)"
          @unselect="onUnselect()"
          isFree
        />
      </div>
    </div>
    <Separator class="mb-3">{{ t('property.payment.services') }}</Separator>
    <div class="row" data-test-id="paymentServiceSelector">
      <div class="col col-md-4 col-lg-5 col-xl-5">
        <PricingCommercializationOption
          :disabled="model.plan === PricingPlan.Basic"
          :hasCommercialization="model.hasCommercialization"
          :opened="opened"
          @toggle="opened = !opened"
          @select="model.hasCommercialization = true"
          @unselect="model.hasCommercialization = false"
        />
      </div>
    </div>
  </div>
  <FormSkeleton v-else />
</template>
<script lang="ts" setup>
import { FormSkeleton, Separator } from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { findCurrentLandlordBasicPlanVisibility } from '@/modules/landlord/application'
import {
  generalPricingPlanFeatures,
  PricingPlan,
  servicesPricingPlanFeatures,
  type NonPaymentCoverageOption,
  type PropertyPaymentInformation
} from '@/modules/property/domain/propertyPaymentInformation'
import { onMounted, ref, watch } from 'vue'
import PricingPlanOption from './PricingPlanOption.vue'
import PricingCommercializationOption from './PricingCommercializationOption.vue'
import { useI18n } from 'vue-i18n'

const model = defineModel<PropertyPaymentInformation>()

const { t } = useI18n()
const { track } = useAnalytics()

const opened = ref(false)
const basicPlanVisibility = ref(false)

function onSelect(plan: PricingPlan, nonPaymentCoverageOption: Nullable<NonPaymentCoverageOption>) {
  if (!model.value) return

  if (plan === PricingPlan.Basic) {
    model.value.hasCommercialization = false
  }

  model.value.plan = plan
  model.value.nonPaymentCoverage = nonPaymentCoverageOption
  track(AnalyticsEvent.LandlordPropertyRegisterSelectPlan)
}

function onUnselect() {
  if (!model.value) return

  model.value.plan = null
}

onMounted(async () => {
  basicPlanVisibility.value = await findCurrentLandlordBasicPlanVisibility()
})

watch(
  () => model.value,
  () => {
    if (model.value?.plan === PricingPlan.Basic) {
      basicPlanVisibility.value = true
    } else if (model.value?.plan === null && !model.value.hasCommercialization && basicPlanVisibility.value) {
      model.value.plan = PricingPlan.Basic
    } else if (model.value?.plan === null && !basicPlanVisibility.value) {
      model.value.plan = PricingPlan.PeaceOfMind
      model.value.hasCommercialization = true
      model.value.nonPaymentCoverage = 12
    }
  }
)
</script>
