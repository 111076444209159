<template>
  <template v-if="properties?.length === 1">
    <LandlordAccountSuccessModal />
  </template>
  <div class="container">
    <div class="top flex justify-between align-end wrap gap-1 mb-2">
      <h1>{{ t('properties.title') }}</h1>
      <Button :loading="creating" @click="onAddProperty" data-test-id="addPropertyButton">
        {{ t('properties.addProperty') }}
      </Button>
    </div>
    <hr />
    <div class="flex justify-between mt-1" v-if="properties.length > 0">
      <div class="flex">
        <p class="label">{{ properties.length }}</p>
        &nbsp;
        <p class="light">{{ t('properties.properties', properties.length) }}</p>
      </div>
      <div class="flex">
        <p class="label">{{ t('properties.sortedBy') }}</p>
        &nbsp;
        <p class="light">{{ t('properties.creationDate') }}</p>
      </div>
    </div>
    <div class="flex gap-1 wrap mt-1">
      <template v-if="loading">
        <ImageCardSkeleton />
        <ImageCardSkeleton />
        <ImageCardSkeleton />
      </template>
      <template v-else>
        <ImageCard
          v-for="property of properties"
          :source="property.images.files[0]?.uri"
          interactable
          @click="onOpenProperty(property)"
        >
          <div>
            <div class="flex justify-end mb-1">
              <PropertyStatusBadge :status="property.status" />
            </div>
            <p class="title mb-1">
              {{ property.coreInformation.street ?? t('common.property.newProperty') }}
            </p>
          </div>
          <div>
            <template v-if="property.completed || property.status !== PropertyStatus.Registered">
              <hr />
              <p class="mt-1" v-if="property.coreInformation.monthlyPayment">
                {{ toLocaleCurrencySymbol(property.coreInformation.monthlyPayment, locale) }}/{{ t('common.month') }}
              </p>
              <p>
                {{ t(`property.payment.${property.paymentInformation.plan}.name`) }}
              </p>
            </template>
            <Button v-else variant="secondary" class="button">
              {{ t('properties.continueRegistration') }}
            </Button>
          </div>
        </ImageCard>
      </template>
    </div>
  </div>
</template>
<script setup lang="ts">
import { Button, ImageCard, ImageCardSkeleton, LandlordAccountSuccessModal, PropertyStatusBadge } from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { createProperty, findAllProperties } from '@/modules/property/application'
import type { Property } from '@/modules/property/domain/property'
import { PropertyStatus } from '@/modules/property/domain/propertyStatus'
import { router } from '@/router'
import { toLocaleCurrencySymbol } from '@/utils/numbers'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t, locale } = useI18n()
const { track } = useAnalytics()

const properties = ref<Property[]>([])
const loading = ref(true)
const creating = ref(false)

track(AnalyticsEvent.LandlordPropertiesPropertiesList)

onMounted(async () => {
  properties.value = await findAllProperties()
  loading.value = false
})

function onOpenProperty(property: Property) {
  if (property.completed || property.status !== PropertyStatus.Registered) {
    router.push({ name: 'propertyDetails', params: { id: property.id } })
  } else {
    track(AnalyticsEvent.LandlordPropertiesContinueRegistration)
    router.push({ name: 'resumeOnboarding', params: { id: property.id } })
  }
}

async function onAddProperty() {
  creating.value = true
  try {
    track(AnalyticsEvent.LandlordPropertiesAddNewProperty)
    const id = await createProperty()
    router.push({ name: 'coreInformation', params: { id } })
  } catch {}
  creating.value = false
}
</script>
