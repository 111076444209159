<template>
  <div class="card">
    <div class="contracts">
      <div v-if="contract">
        <h3>{{ t('propertyContract.contractualInformation') }}</h3>
        <hr class="mt-1 mb-2" />
        <div class="flex wrap gap-05">
          <div class="field">
            <p class="title">{{ t('propertyContract.effectiveDate') }}:</p>
            <p>{{ formatDate(contract.effectiveDate) }}</p>
          </div>
          <div class="field">
            <p class="title">{{ t('propertyContract.collectionDate') }}:</p>
            <p>{{ formatDate(collectionDate) }}</p>
          </div>
          <div class="field">
            <p class="title">{{ t('propertyContract.expirationDate') }}:</p>
            <p>{{ formatDate(contract.expirationDate) }}</p>
          </div>
          <div class="field">
            <p class="title">{{ t('propertyContract.nextRentIncrease') }}:</p>
            <p>{{ formatDate(contract.nextRentIncreaseDate) }}</p>
          </div>
          <div class="field">
            <p class="title">{{ t('propertyContract.passedOnExpenses') }}:</p>
            <p>{{ passedOnExpenses ? `${passedOnExpenses}€` : '-' }}</p>
          </div>
        </div>
        <h3 class="mt-3">{{ t('propertyContract.services') }}</h3>
        <hr class="mt-1 mb-2" />
        <div class="flex wrap">
          <div class="flex align-center gap-05 mr-3">
            <p>{{ t('propertyContract.supplyChange') }}</p>
            <img :src="icons.checkGreen" />
          </div>
          <div class="flex align-center gap-05 mr-3">
            <p>{{ t('propertyContract.depositBond') }}</p>
            <img :src="icons.checkGreen" />
          </div>
          <div class="flex align-center gap-05">
            <p>{{ t('propertyContract.nonPaymentCoverage') }}</p>
            <img v-if="hasNonPaymentCoverage" :src="icons.checkGreen" />
            <img v-else :src="icons.cancelRed" />
          </div>
        </div>
      </div>
      <div class="empty" v-else>
        <div class="icon mb-1">
          <img :src="icons.documentLight" width="40" height="40" />
        </div>
        <p class="title">{{ t('propertyContract.empty.title') }}</p>
        <p>{{ t('propertyContract.empty.description') }}</p>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { icons } from '@/config/assets/icons'
import { useDates } from '@/hooks/useDates'
import { PricingPlan } from '@/modules/property/domain/propertyPaymentInformation'
import type { PropertyContract } from '@/modules/propertyContract/domain/propertyContract'
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { useAnalytics } from '@/hooks/useAnalytics'
import { findCurrentUser } from '@/modules/user/application'
import { UserType } from '@/modules/user/domain/user'

const props = defineProps<{
  pricingPlan: Nullable<PricingPlan>
  rent: Nullable<number>
  monthlyPayment: Nullable<number>
  contract: Nullable<PropertyContract>
}>()

const { t } = useI18n()
const { formatDate } = useDates()
const { track } = useAnalytics()

const collectionDate = computed(() => {
  const today = new Date()
  return new Date(today.getFullYear(), today.getMonth() + 1, 7)
})

const passedOnExpenses = computed(() => (props.monthlyPayment && props.rent ? props.monthlyPayment - props.rent : null))

const hasNonPaymentCoverage = computed(
  () => props.pricingPlan === PricingPlan.PeaceOfMind || props.pricingPlan === PricingPlan.Secured
)

const trackByUserType = (userType: UserType) => {
  switch (userType) {
    case UserType.Landlord:
      track(AnalyticsEvent.LandlordPropertyDetailContractualInformation)
      break
    case UserType.Partner:
      track(AnalyticsEvent.PartnerPropertyDetailContractualInformation)
      break
    default:
      break
  }
}

onMounted(async () => {
  const { type } = await findCurrentUser()
  trackByUserType(type)
})
</script>
<style scoped lang="sass">
@use "@/assets/styles/responsive.sass" as *

.contracts
  padding: 0.5rem

p
  font-size: 1rem

.field
  display: flex
  gap: 0.25rem
  width: 100%

  @include md
    width: calc(50% - 0.5rem)

.empty
  text-align: center
  padding: 1rem

  .icon
    display: inline-flex
    justify-content: center
    align-items: center
    padding: 1rem
    border-radius: 3rem
    background-color: #F9F9F9

  p
    margin: 0 auto
    margin-top: 0.5rem
    max-width: 38rem
</style>
