export function useDates() {
  return {
    formatDate(date: Nullable<Date>) {
      if (!date) return '-'

      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      return `${day}/${month}/${year}`
    }
  }
}
