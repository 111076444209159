<template>
  <Teleport to="body">
    <PartnerReferralCreationModal v-if="showCreationModal" @close="showCreationModal = false" />
  </Teleport>
  <div class="cta">
    <div class="icon"><img :src="icons.profit" /></div>
    <p class="title mt-05">{{ t('partnerReferral.callToAction.title') }}</p>
    <p class="description mb-1">{{ t('partnerReferral.callToAction.description') }}</p>
    <Button @click="handleCreateReferral">{{ t('partnerReferral.callToAction.action') }}</Button>
  </div>
</template>
<script lang="ts" setup>
import { Button, PartnerReferralCreationModal } from '@/components'
import { icons } from '@/config/assets/icons'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { useAnalytics } from '@/hooks/useAnalytics'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { track } = useAnalytics()

const showCreationModal = ref(false)

async function handleCreateReferral() {
  showCreationModal.value = true
  track(AnalyticsEvent.PartnerLeadsCreateLeadBanner)
}
</script>
<style scoped lang="sass">
@use "@/assets/styles/responsive.sass" as *

.cta
  display: none
  position: absolute
  bottom: 1rem
  width: calc(100% - 2rem)
  box-sizing: border-box
  padding: 0.9rem
  border-radius: 0.5rem
  background-color: #EDB7F7
  text-align: left

  @include lg
    display: block

  .icon
    background-color: #F6DAFB
    border-radius: 0.5rem
    padding: 0.5rem
    display: inline-flex
    align-items: center
    justify-content: center

  .title
    font-size: 0.875rem

  .description
    font-size: 0.75rem

  .button
    padding: 0.75rem
    width: 100%
</style>
