import Hotjar from '@hotjar/browser'
import * as Sentry from '@sentry/vue'
import { inject as injectVercelAnalytics } from '@vercel/analytics'
import { LDPlugin } from 'launchdarkly-vue-client-sdk'
import { createApp } from 'vue'
import App from './App.vue'
import { i18n } from './i18n'
import { router } from './router'

if (import.meta.env.PROD) {
  const hotjarVersion = 6
  Hotjar.init(import.meta.env.VITE_HOTJAR_ID, hotjarVersion)
}

injectVercelAnalytics({ mode: import.meta.env.PROD ? 'production' : 'development' })

const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://74d4622631ec29873b61ce35c62400db@o4508771519102976.ingest.de.sentry.io/4508771521921104',
  integrations: [],
  environment: import.meta.env.MODE
})

app.use(router)
app.use(i18n)
app.use(LDPlugin, { clientSideID: import.meta.env.VITE_LAUNCHDARKLY_CLIENT_ID })
app.mount('#app')
