<template>
  <div class="plan" :class="{ selected }" :data-test-id="`${plan}Plan`">
    <div class="tag" v-if="isFree">{{ t('property.payment.free') }}</div>
    <div class="tag" v-if="isPopular">{{ t('property.payment.popular') }}</div>
    <div>
      <h3>{{ t(`property.payment.${plan}.name`) }}</h3>
      <p class="description">{{ t(`property.payment.${plan}.description`) }}</p>
      <div class="price">
        {{
          t(`property.payment.${plan}.price`, {
            percentage: getPricingPlanMonthlyRentPercentage(plan, nonPaymentCoverage)
          })
        }}
        <span>{{ t('property.payment.plusIva') }}</span>
      </div>
      <div v-if="hasNonPaymentCoverage" class="mb-1">
        <p class="capitalize">{{ t('property.payment.nonPaymentCoverage.description') }}</p>
        <div class="flex gap-05 mt-05">
          <RadioButton
            name="nonPaymentCoverage"
            :modelValue="nonPaymentCoverage"
            :options="nonPaymentCoverageOptions"
            @update:modelValue="onChangeNonPaymentCoverageOption($event as NonPaymentCoverageOption)"
          />
        </div>
      </div>
      <Button v-if="selected" class="mt-05" @click="emit('unselect')">{{ t('property.payment.unselect') }}</Button>
      <Button v-else class="mt-05" :data-test-id="`${plan}PlanSelector`" @click="emit('select', nonPaymentCoverage)">{{
        t('property.payment.select')
      }}</Button>
    </div>
    <div class="details" v-if="opened">
      <div v-for="feature in compareFeatures">
        <img v-if="isFeatureIncludedInPricingPlan(feature, plan)" :src="icons.checkGreen" />
        <img v-else :src="icons.cancelRed" />
        <p>{{ t(`property.payment.features.${feature}`) }}</p>
      </div>
    </div>
    <div class="more-details" @click="emit('toggle')">
      <span v-if="opened">{{ t('property.payment.lessDetails') }}</span>
      <span v-else>{{ t('property.payment.moreDetails') }}</span>
      <img v-if="opened" :src="icons.chevronUp" />
      <img v-else :src="icons.chevronDown" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { icons } from '@/config/assets/icons'
import { Button, RadioButton } from '@/components'
import {
  getPricingPlanMonthlyRentPercentage,
  isFeatureIncludedInPricingPlan,
  PricingPlan,
  PricingPlanFeature,
  type NonPaymentCoverageOption,
  type PropertyPaymentInformation
} from '@/modules/property/domain/propertyPaymentInformation'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  plan: PricingPlan
  opened: boolean
  compareFeatures: PricingPlanFeature[]
  selected: boolean
  initialValue: PropertyPaymentInformation
  hasNonPaymentCoverage?: boolean
  isFree?: boolean
  isPopular?: boolean
}>()

const emit = defineEmits<{
  (e: 'select', nonPaymentCoverage: Nullable<NonPaymentCoverageOption>): void
  (e: 'toggle'): void
  (e: 'unselect'): void
}>()

const { t } = useI18n()

const nonPaymentCoverageOptions: { value: NonPaymentCoverageOption; label: string }[] = [
  { value: 12, label: 'property.payment.nonPaymentCoverage.12months' },
  { value: 18, label: 'property.payment.nonPaymentCoverage.18months' },
  { value: 24, label: 'property.payment.nonPaymentCoverage.24months' }
]
const nonPaymentCoverage = ref<Nullable<NonPaymentCoverageOption>>(null)

onMounted(() => {
  if (props.hasNonPaymentCoverage) {
    if (props.initialValue.nonPaymentCoverage && props.selected) {
      nonPaymentCoverage.value = props.initialValue.nonPaymentCoverage
    } else {
      onChangeNonPaymentCoverageOption(12)
    }
  }
})

function onChangeNonPaymentCoverageOption(value: Nullable<NonPaymentCoverageOption>) {
  nonPaymentCoverage.value = value
  if (props.selected) {
    emit('select', value)
  }
}
</script>
<style lang="sass" scoped>
@keyframes appear
  from
    opacity: 0
    transform: scale(0.95)
  to
    opacity: 1
    transform: scale(1)

.tag
  box-sizing: border-box
  background-color: #0B0B0B
  border-radius: 1.25rem
  text-align: right
  padding: 0.35rem 0.5rem
  font-size: 0.75rem
  position: absolute
  z-index: 0
  top: 0rem
  left: 50%
  transform: translate(-50%, -50%)
  color: white

.plan
  position: relative
  z-index: 1
  padding: 2rem 1.25rem
  padding-bottom: 1.25rem
  border-radius: 1.25rem
  border: 1px solid #F0F0F0
  background-color: white
  margin-bottom: 2rem

  &:hover
    border-color: #0B0B0B

  .description
    font-size: 1rem
    margin-top: 0.5rem

  .price
    font-size: 1.125rem
    font-weight: 500
    margin-top: 1.5rem
    margin-bottom: 1.5rem

    span
      font-size: 0.75rem
      font-weight: 400

  .details
    margin-top: 2rem
    animation: appear 0.25s ease

    > div
      display: flex
      gap: 1rem
      align-items: center

      p
        font-size: 1rem

  .more-details
    margin-top: 1rem
    padding-top: 1.5rem
    cursor: pointer
    text-align: center
    font-size: 0.875rem
    border-top: 1px solid #E6E6E6

    span
      margin-right: 0.5rem

    img
      transform: translateY(-1px)

    &:hover
      text-decoration: underline

  &.selected
    background-color: #F6F3A0
    border: 1px solid #0B0B0B

    .check::after
      content: ''
      width: 0.6rem
      height: 0.6rem
      border-radius: 50%
      background-color: #0B0B0B
      display: block
      position: relative
      top: 0.2rem
      left: 0.2rem

    .title, .more-details
      border-color: #0B0B0B
</style>
