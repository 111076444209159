import type { PropertyContract } from '@/modules/propertyContract/domain/propertyContract'

export interface PropertyContractDto {
  effective_date: Nullable<string>
  expiration_date: Nullable<string>
  next_rent_increase_date: Nullable<string>
}

export function fromDto(dto: PropertyContractDto): PropertyContract {
  return {
    effectiveDate: dto.effective_date ? new Date(dto.effective_date) : null,
    expirationDate: dto.expiration_date ? new Date(dto.expiration_date) : null,
    nextRentIncreaseDate: dto.next_rent_increase_date ? new Date(dto.next_rent_increase_date) : null
  }
}
