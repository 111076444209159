import type { PropertyContractRepository } from '@/modules/propertyContract/domain/propertyContractRepository'
import type { DatabaseService } from '@/modules/shared/domain/database/apiService'
import { fromDto, type PropertyContractDto } from './apiDto'

export function apiPropertyContractRepositoryBuilder(databaseService: DatabaseService): PropertyContractRepository {
  return {
    async getByPropertyId(propertyId) {
      const results = await databaseService.select<PropertyContractDto>('property_contracts', 'property_id', propertyId)
      return results ? fromDto(results[0]) : null
    }
  }
}
