import mixpanel from 'mixpanel-browser'

import { AnalyticsEvent } from '../domain/analyticsEvent'
import type { AnalyticsService } from '../domain/analyticsService'
import { mixpanelEventsMap } from './mixpanelEventsMap'
import type { UserType } from '@/modules/user/domain/user'

export function mixpanelAnalyticsServiceBuilder({ token }: { token: string }): AnalyticsService {
  let isIdentified = false

  mixpanel.init(token, { record_sessions_percent: 1, ignore_dnt: true })

  return {
    identify: (email: string, type: UserType) => {
      if (isIdentified) return

      mixpanel.identify(email)
      mixpanel.people.set({
        $email: email,
        role: type
      })
      mixpanel.register({ role: type })
      isIdentified = true
    },
    track: (type: AnalyticsEvent, payload?: Record<string, unknown>) => {
      mixpanel.track(mixpanelEventsMap[type], payload)
    },
    clear: () => {
      mixpanel.reset()
      isIdentified = false
    }
  }
}
