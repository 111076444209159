import { apiEndpointsV2 as v2 } from '@/config/api/endpoints.v2'
import type { PartnerPropertyRepository } from '@/modules/partnerProperty/domain/partnerPropertyRepository'
import type { ApiService } from '@/modules/shared/domain/api/apiService'
import { asyncMap } from '@/utils/array'
import {
  fromPartnerPropertyDto,
  type PartnerPropertyDto,
  type PartnerPropertyImageDto,
  type PartnerPropertyLandlordDto,
  type ServiceBundleDto
} from './apiDto'

export function apiPartnerPropertyRepositoryBuilder(apiV2Service: ApiService): PartnerPropertyRepository {
  return {
    async getAll() {
      const dtos = await apiV2Service.getList<PartnerPropertyDto>(v2.partners().properties().list())

      return asyncMap(dtos, async (dto) => {
        let imagesDtos: PartnerPropertyImageDto[] = []
        try {
          imagesDtos = await apiV2Service.getList<PartnerPropertyImageDto>(v2.properties().images(dto.id).list())
        } catch {}

        const landlordDto = await apiV2Service.get<PartnerPropertyLandlordDto>(
          v2.partners().landlord().find(dto.landlord.id)
        )

        let serviceBundleDto: Nullable<ServiceBundleDto> = null
        try {
          serviceBundleDto = await apiV2Service.get<ServiceBundleDto>(
            v2.partners().properties().serviceBundle(dto.id).find()
          )
        } catch {}

        return fromPartnerPropertyDto(dto, imagesDtos, landlordDto, serviceBundleDto, [])
      })
    },
    async getById(id) {
      const dto = await apiV2Service.get<PartnerPropertyDto>(v2.partners().properties().find(id))

      let imagesDtos: PartnerPropertyImageDto[] = []
      try {
        imagesDtos = await apiV2Service.getList<PartnerPropertyImageDto>(v2.properties().images(dto.id).list())
      } catch {}

      const landlordDto = await apiV2Service.get<PartnerPropertyLandlordDto>(
        v2.partners().landlord().find(dto.landlord.id)
      )

      let serviceBundleDto: Nullable<ServiceBundleDto> = null
      try {
        serviceBundleDto = await apiV2Service.get<ServiceBundleDto>(
          v2.partners().properties().serviceBundle(dto.id).find()
        )
      } catch {}

      let tenants: string[] = []
      try {
        tenants = (await apiV2Service.getList<{ name: string }>(v2.partners().properties().tenants(dto.id).list())).map(
          ({ name }) => name
        )
      } catch {}

      return fromPartnerPropertyDto(dto, imagesDtos, landlordDto, serviceBundleDto, tenants)
    }
  }
}
