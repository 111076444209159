<template>
  <Modal @close="emit('close')">
    <div v-if="success">
      <div class="success flex align-center justify-center column gap-1 p-2">
        <img class="mt-1 mb-05" :src="images.ellipsesPink" />
        <h3>{{ t('partnerReferral.send.success.title') }}</h3>
        <p class="align-center">{{ t('partnerReferral.send.success.description') }}</p>
      </div>
      <div class="flex align-center justify-center p-2">
        <Button @click="emit('close')">
          {{ t('partnerReferral.send.success.close') }}
        </Button>
      </div>
    </div>
    <div v-else class="flex align-center justify-center column gap-1 p-2">
      <img class="mt-1 mb-05" :src="images.ellipsesPink" />
      <h3>{{ t('partnerReferral.send.title') }}</h3>
      <p class="align-center">{{ t('partnerReferral.send.description') }}</p>
      <Form @submit="onSend">
        <InputField
          v-model="form.name"
          :error="errors['name']"
          :label="t('forms.name')"
          :placeholder="t('partnerReferral.send.namePlaceholder')"
        />
        <InputField
          v-model="form.surname"
          :error="errors['surname']"
          :label="t('forms.surname')"
          :placeholder="t('partnerReferral.send.surnamePlaceholder')"
        />
        <InputField
          name="email"
          v-model="form.email"
          :error="errors['email']"
          :label="t('forms.email')"
          :placeholder="t('forms.emailPlaceholder')"
          type="email"
        />
        <PhoneInputField
          v-model="form.phone"
          :error="errors['phone']"
          :label="t('forms.phone')"
          :placeholder="t('partnerReferral.send.phonePlaceholder')"
        />
        <p v-if="errors['generic']" class="error-message center">
          {{ errors['generic'] }}
        </p>
        <Button submit :loading="loading" :disabled="!isConfirmationChecked">
          {{ t('partnerReferral.send.action') }}
        </Button>
      </Form>
      <div class="flex align-start gap-05">
        <Checkbox v-model="isConfirmationChecked" />
        <p class="mt-0">{{ t('partnerReferral.send.confirmation') }}</p>
      </div>
    </div>
  </Modal>
</template>
<script setup lang="ts">
import { Button, Checkbox, Form, InputField, Modal, PhoneInputField } from '@/components'
import { images } from '@/config/assets/images'
import { createPartnerReferral } from '@/modules/partnerReferral/application'
import type { CreatePartnerReferralForm } from '@/modules/partnerReferral/domain/createPartnerReferralForm'
import { FormValidationError } from '@/modules/shared/domain/errors/formValidationError'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { useAnalytics } from '@/hooks/useAnalytics'

const { t } = useI18n()
const { track } = useAnalytics()
const emit = defineEmits(['close', 'updated'])

const isConfirmationChecked = ref(false)
const loading = ref(false)
const success = ref(false)
const errors = ref<Record<string, string>>({})
const form = ref<CreatePartnerReferralForm>({
  name: '',
  surname: '',
  phone: '',
  email: ''
})

async function onSend() {
  if (!isConfirmationChecked.value) return

  loading.value = true
  errors.value = {}

  try {
    await createPartnerReferral(form.value)
    emit('updated')
    success.value = true
    track(AnalyticsEvent.PartnerLeadsLeadCreated)
  } catch (error) {
    if (error instanceof FormValidationError) {
      errors.value = error.errors
    } else if (error instanceof Error) {
      errors.value = { generic: 'genericError.unexpected' }
    }
    loading.value = false
  }
}
</script>
<style lang="sass" scoped>
@use "@/assets/styles/responsive.sass" as *

.success
  background-color: #EDB7F7

button, form
  width: 100%

@include md
  :deep(.modal-content)
    max-width: 32rem
</style>
