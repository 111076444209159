export enum PricingPlan {
  Basic = 'basic',
  Comfort = 'comfort',
  Secured = 'secured',
  PeaceOfMind = 'peaceOfMind'
}

export type NonPaymentCoverageOption = 12 | 18 | 24

export function getPricingPlanMonthlyRentPercentage(
  plan: PricingPlan,
  nonPaymentCoverage: Nullable<NonPaymentCoverageOption>
): number {
  const option = nonPaymentCoverage || 12
  if (plan === PricingPlan.Comfort) {
    return 5
  } else if (plan === PricingPlan.Secured) {
    return { 12: 5, 18: 6, 24: 6.5 }[option]
  } else if (plan === PricingPlan.PeaceOfMind) {
    return { 12: 8, 18: 9, 24: 9.5 }[option]
  } else {
    return 0
  }
}

export enum PricingPlanFeature {
  DocumentManagement = 'documentManagement',
  SolvencyVerification = 'solvencyVerification',
  UtilityTransfers = 'utilityTransfers',
  BailManagement = 'bailManagement',
  NonPaymentInsurance = 'nonPaymentInsurance',
  MonthlyPayments = 'monthlyPayments',
  IncidentManagement = 'incidentManagement',
  GuarantorRelease = 'guarantorRelease',
  ProfessionalPhotos = 'professionalPhotos',
  IdealistaPublish = 'idealistaPublish',
  TenantReview = 'tenantReview',
  VisitsManagement = 'visitsManagement',
  DigitalSignature = 'digitalSignature',
  ItpManagement = 'itpManagement',
  TenantReplacement = 'tenantReplacement'
}

export const generalPricingPlanFeatures = [
  PricingPlanFeature.DocumentManagement,
  PricingPlanFeature.SolvencyVerification,
  PricingPlanFeature.UtilityTransfers,
  PricingPlanFeature.BailManagement,
  PricingPlanFeature.NonPaymentInsurance,
  PricingPlanFeature.MonthlyPayments,
  PricingPlanFeature.IncidentManagement,
  PricingPlanFeature.GuarantorRelease
]

export const servicesPricingPlanFeatures = [
  PricingPlanFeature.DocumentManagement,
  PricingPlanFeature.ProfessionalPhotos,
  PricingPlanFeature.IdealistaPublish,
  PricingPlanFeature.TenantReview,
  PricingPlanFeature.SolvencyVerification,
  PricingPlanFeature.VisitsManagement,
  PricingPlanFeature.DigitalSignature,
  PricingPlanFeature.UtilityTransfers,
  PricingPlanFeature.BailManagement,
  PricingPlanFeature.ItpManagement,
  PricingPlanFeature.TenantReplacement
]

export function isFeatureIncludedInPricingPlan(feature: PricingPlanFeature, plan: PricingPlan): boolean {
  const propertyPlanFeatures: Record<PricingPlan, PricingPlanFeature[]> = {
    [PricingPlan.Basic]: [
      PricingPlanFeature.DocumentManagement,
      PricingPlanFeature.IdealistaPublish,
      PricingPlanFeature.TenantReview,
      PricingPlanFeature.SolvencyVerification,
      PricingPlanFeature.UtilityTransfers
    ],
    [PricingPlan.Comfort]: [
      PricingPlanFeature.DocumentManagement,
      PricingPlanFeature.UtilityTransfers,
      PricingPlanFeature.BailManagement,
      PricingPlanFeature.MonthlyPayments,
      PricingPlanFeature.IncidentManagement,
      PricingPlanFeature.GuarantorRelease
    ],
    [PricingPlan.Secured]: [
      PricingPlanFeature.DocumentManagement,
      PricingPlanFeature.SolvencyVerification,
      PricingPlanFeature.UtilityTransfers,
      PricingPlanFeature.BailManagement,
      PricingPlanFeature.NonPaymentInsurance,
      PricingPlanFeature.MonthlyPayments,
      PricingPlanFeature.GuarantorRelease
    ],
    [PricingPlan.PeaceOfMind]: [
      PricingPlanFeature.DocumentManagement,
      PricingPlanFeature.SolvencyVerification,
      PricingPlanFeature.UtilityTransfers,
      PricingPlanFeature.BailManagement,
      PricingPlanFeature.NonPaymentInsurance,
      PricingPlanFeature.MonthlyPayments,
      PricingPlanFeature.IncidentManagement,
      PricingPlanFeature.GuarantorRelease
    ]
  }

  return propertyPlanFeatures[plan].includes(feature)
}

export interface PropertyPaymentInformation {
  hasCommercialization: Nullable<boolean>
  nonPaymentCoverage: Nullable<NonPaymentCoverageOption>
  plan: Nullable<PricingPlan>
}
