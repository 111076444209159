<template>
  <tr class="table-row" :style="grid.rowGrid">
    <slot />
  </tr>
</template>

<script setup lang="ts">
import { inject } from 'vue'
import type { Grid } from './types'
const grid: Grid = inject('grid', {
  widths: {},
  minWidths: {},
  rowGrid: '',
  aligns: {}
})
</script>

<style scoped lang="sass">
.table-row
  display: grid
  min-width: fit-content
  width: 100%
  border-bottom: 1px solid $lightBorder
  &:last-child
    border-bottom: 0
</style>
