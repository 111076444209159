import type { PropertyRepository } from '../domain/propertyRepository'
import { generateUuid } from '@/modules/shared/domain/uuid/uuid'

export function createBuilder(propertyRepository: PropertyRepository) {
  return async () => {
    const propertyId = generateUuid()
    await propertyRepository.create(propertyId)
    return propertyId
  }
}
