export enum MimeType {
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  PDF = 'application/pdf'
}

export const mimeTypeExtensions: Record<MimeType, string> = {
  [MimeType.JPG]: 'jpg',
  [MimeType.JPEG]: 'jpeg',
  [MimeType.PNG]: 'png',
  [MimeType.PDF]: 'pdf'
}
