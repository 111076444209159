// eslint-disable-next-line typescript-eslint/no-explicit-any
export function hasNullProperty(obj: Record<string, any>): boolean {
  for (let key in obj) {
    if (obj[key] === null) return true

    if (typeof obj[key] === 'object' && obj[key] !== null) {
      if (hasNullProperty(obj[key])) {
        return true
      }
    }
  }
  return false
}
