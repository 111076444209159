<template>
  <RouterLink class="link" :to="{ name: 'properties' }" :class="{ active: route.name === 'properties' }">
    <img :src="icons.properties" />
    <span>{{ t('properties.title') }}</span>
  </RouterLink>
  <RouterLink
    v-if="billingLandlordFlag"
    class="link mt-05"
    :to="{ name: 'billing' }"
    :class="{ active: route.name === 'billing' }"
  >
    <img :src="icons.billing" />
    <span>{{ t('landlordBilling.title') }}</span>
  </RouterLink>
</template>
<script lang="ts" setup>
import { icons } from '@/config/assets/icons'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useLDFlag } from 'launchdarkly-vue-client-sdk'

const billingLandlordFlag = useLDFlag('billing-landlord')

const { t } = useI18n()
const route = useRoute()
</script>
