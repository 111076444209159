import { findCurrentUser } from '@/modules/user/application'
import { Applications } from '@/views'
import type { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'

export const applicationsRoutes: RouteRecordRaw = {
  path: '/applications',
  name: 'applications',
  component: Applications,
  beforeEnter: applicationsGuard,
  meta: { tenant: true, zapierChatbot: true }
}

export async function applicationsGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const user = await findCurrentUser()
  return !user.name ? next({ name: 'personalInformation' }) : next()
}
