<template>
  <div class="table-wrapper">
    <table class="table">
      <thead class="head">
        <tr class="head-row" :style="data.grid.rowGrid">
          <th
            v-for="column in columns"
            :id="column.key || column.name"
            :key="column.key || column.name"
            class="head-column"
            :style="{
              minWidth: data.grid.minWidths[column.key || column.name],
              width: data.grid.widths[column.key || column.name]
            }"
          >
            <div
              class="head-column-text"
              :class="{
                left: column.align === 'left',
                right: column.align === 'right',
                center: column.align === 'center'
              }"
            >
              {{ column.name }}
            </div>
          </th>
        </tr>
      </thead>
      <table-loader :loading="props.loading" data-test-id="table-content" class="body">
        <slot v-if="hasContent" />
        <table-empty-state
          v-else-if="emptyState"
          :image="emptyState.icon"
          :title="emptyState.title"
          :description="emptyState.description"
        />
      </table-loader>
    </table>
  </div>
</template>

<script setup lang="ts">
import { computed, useSlots, Fragment, reactive, onMounted, provide, watch } from 'vue'
import type { VNode } from 'vue'
import type { Options, Grid } from './types'
import TableLoader from './TableLoader.vue'
import TableEmptyState from './TableEmptyState.vue'
const slots = useSlots()
const props = defineProps<{
  loading?: boolean
  options: Options
}>()
interface TableData {
  grid: Grid
}
const data: TableData = reactive({
  grid: {
    rowGrid: '',
    widths: {},
    minWidths: {},
    aligns: {}
  }
})

const columns = computed(() => props.options.columns)

const emptyState = computed(() => props.options.emptyState)

const hasContent = computed(() => {
  const computedSlots = (slots.default?.({}) ?? []) as Array<VNode>
  return computedSlots.reduce((hasToShow: boolean, slot: VNode) => {
    if (slot?.type === Fragment) {
      const children = slot.children as unknown[] | { length: number } | undefined
      return hasToShow || (children?.length ?? 0) > 0
    }
    return true
  }, false)
})

function createGrid() {
  data.grid.rowGrid = `grid-template-columns: ${columns.value
    .map((item) => {
      const width = data.grid.widths[item.key ?? item.name] || 'auto'
      return width === 'auto' ? `minmax(min(${item.minWidth ?? 100}px, 80vw), 1fr)` : 'max-content'
    })
    .join(' ')}`
}
function generateWidths(property: 'width' | 'minWidth') {
  return props.options.columns.reduce((acum, column) => {
    let value = property === 'width' ? 'auto' : '100px'
    if (typeof column[property] === 'number') {
      value = `${column[property]}px`
    }
    return { ...acum, [column.key ?? column.name]: value }
  }, {})
}

function initTable() {
  data.grid.widths = generateWidths('width')
  data.grid.minWidths = generateWidths('minWidth')
  createGrid()
}

provide('grid', data.grid)
provide('options', props.options)
provide(
  'columns',
  computed(() => columns)
)

onMounted(() => {
  initTable()
})
</script>

<style scoped lang="sass">
.table-wrapper
  position: relative
  display: grid
  grid-template-rows: 1fr min-content
  height: 100%
  border-radius: 0.8rem
.table
  min-width: 100%
  border-collapse: collapse
  width: 100%
.head
  display: block
  width: 100%
  background: $grey200
  border-top: 1px solid $lightBorder
  border-bottom: 1px solid $lightBorder
.head-row
  display: grid
  min-width: fit-content
  width: 100%
.head-column
  color: black
  background: white
  height: 44px
  font-weight: 400
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
  vertical-align: middle
  font-size: 0.8rem
  font-weight: 500
  width: 100%
  display: flex
  align-items: center
  background: $grey200
.head-column-text
  width: 100%
  margin: 0 1em
  display: flex
  gap: 4px
  align-items: center
  text-align: left
  &.left
    justify-content: flex-start
  &.right
    justify-content: flex-end
  &.center
    justify-content: center
</style>
