import type { PartnerReferral, PartnerReferralStatus } from '@/modules/partnerReferral/domain/partnerReferral'
import type { Uuid } from '@/modules/shared/domain/uuid/uuid'

export interface PartnerReferralDto {
  id: Uuid
  status: PartnerReferralStatus
  name: string
  surname: string
  email: string
  phone: string
  koReason: Nullable<string>
  createdAt: string
}

export function fromDto(dto: PartnerReferralDto): PartnerReferral {
  return {
    ...dto,
    createdAt: new Date(dto.createdAt)
  }
}
