<template>
  <div class="number-picker">
    <button @click="decrement" :disabled="isDecrementDisabled || disabled">-</button>
    <span :class="{ disabled }">{{ modelValue ?? minNumber }}</span>
    <button @click="increment" :disabled="isIncrementDisabled || disabled">+</button>
  </div>
</template>
<script setup lang="ts">
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { computed, onMounted } from 'vue'

const props = defineProps<{
  modelValue: Nullable<number>
  minNumber: number
  maxNumber?: number
  disabled?: boolean
  trackEnterEvent?: AnalyticsEvent
}>()

const emit = defineEmits(['update:modelValue'])

const { track } = useAnalytics()

const isIncrementDisabled = computed(
  () => props.modelValue === null || props.modelValue >= (props.maxNumber ?? Infinity)
)
const isDecrementDisabled = computed(() => props.modelValue === null || props.modelValue <= props.minNumber)

onMounted(() => {
  if (props.modelValue === null) {
    emit('update:modelValue', props.minNumber)
  }
})

const increment = () => {
  const value = props.modelValue !== null ? props.modelValue : props.minNumber
  if (!props.disabled && value < (props.maxNumber ?? Infinity)) {
    emit('update:modelValue', value + 1)
    if (props.trackEnterEvent) {
      track(props.trackEnterEvent)
    }
  }
}

const decrement = () => {
  const value = props.modelValue !== null ? props.modelValue : props.minNumber
  if (!props.disabled && value > props.minNumber) {
    emit('update:modelValue', value - 1)
    if (props.trackEnterEvent) {
      track(props.trackEnterEvent)
    }
  }
}
</script>
<style scoped lang="sass">
.number-picker
  display: flex
  max-width: 25rem

button
  box-sizing: content-box
  font-size: 24px
  height: 48px
  width: 56px
  cursor: pointer
  padding: 0
  border: 1px solid $lightBorder
  border-radius: 8px
  background-color: transparent

  &:not(:disabled):not(:active):hover
    border-color: $grey500

  &:enabled:active
    background-color: $yellow500

  &:disabled
    cursor: not-allowed

span
  width: 6rem
  height: 3rem
  flex: auto
  display: flex
  align-items: center
  justify-content: center
  margin-left: $paddingXS
  margin-right: $paddingXS
  font-size: 16px
  text-align: center
  border: 1px solid $lightBorder
  border-radius: 8px
  color: $grey500

span.disabled
  border-color: $grey300
  color: $grey300
</style>
