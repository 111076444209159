<template>
  <div class="container">
    <h1 class="mb-2">{{ t('partnerBilling.title') }}</h1>
    <hr />

    <div class="card p-0 mt-2">
      <div class="header-table">
        <h4>{{ t('partnerBilling.invoice.title') }}</h4>
        <p class="light">{{ t('partnerBilling.invoice.subtitle') }}</p>
      </div>

      <div class="has-overflow-auto">
        <Table :loading="loading" :options="data.options">
          <TableRow v-for="(document, key) in data.documents" :key="key">
            <TableRowItem>
              <p>{{ document.issuedDate }}</p>
            </TableRowItem>
            <TableRowItem>
              <p>{{ document.propertyAddress }}</p>
            </TableRowItem>
            <TableRowItem>
              <p>{{ document.collaborationType }}</p>
            </TableRowItem>
            <TableRowItem>
              <p>{{ document.concept }}</p>
            </TableRowItem>
            <TableRowItem>
              <p>{{ toLocaleCurrencySymbol(+document.finalAmount, locale) }}</p>
            </TableRowItem>
          </TableRow>
        </Table>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, reactive, onMounted, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { Table, TableRow, TableRowItem } from '@/components'
import { icons } from '@/config/assets/icons'
import { findAllPartnerProperties } from '@/modules/partnerProperty/application'
import { findPartnerBillingDocuments } from '@/modules/partnerBilling/application'
import type { PartnerInvoice } from '@/modules/partnerBilling/domain/partnerBillingDocuments'
import { toLocaleCurrencySymbol } from '@/utils/numbers'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { useAnalytics } from '@/hooks/useAnalytics'

const { t, locale } = useI18n()
const loading = ref(true)
const { track } = useAnalytics()
interface DataType {
  documents: PartnerInvoice[]
  options: (typeof Table)['options']
}

const columns = computed(() => [
  {
    key: 'date',
    name: t('partnerBilling.table.columns.date'),
    width: 150
  },
  {
    key: 'property',
    name: t('partnerBilling.table.columns.property'),
    minWidth: 250
  },
  {
    key: 'collaborationType',
    name: t('partnerBilling.table.columns.collaborationType'),
    width: 200
  },
  {
    key: 'concept',
    name: t('partnerBilling.table.columns.concept'),
    width: 200
  },
  {
    key: 'amount',
    name: t('partnerBilling.table.columns.amount'),
    width: 150
  }
])

const emptyState = computed(() => ({
  icon: icons.billing,
  title: t('partnerBilling.table.emptyState.title'),
  description: t('partnerBilling.table.emptyState.description')
}))

const data: DataType = reactive({
  documents: [],
  options: {
    get columns() {
      return columns.value
    },
    get emptyState() {
      return emptyState.value
    }
  }
})

onMounted(async () => {
  data.documents = []
  const properties = await findAllPartnerProperties()
  for (const property of properties) {
    const propertyDocuments = await findPartnerBillingDocuments(property)
    data.documents = [...data.documents, ...propertyDocuments]
  }
  loading.value = false
  track(AnalyticsEvent.PartnerBillingInvoicesList)
})
</script>

<style scoped lang="sass">
.header-table
  padding: 1.25rem 1.5rem
.card.p-0
  padding: 0 !important
.has-overflow-auto
  overflow: auto
</style>
