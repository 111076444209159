import { apiEndpointsV1 as v1 } from '@/config/api/endpoints.v1'
import { apiEndpointsV2 as v2 } from '@/config/api/endpoints.v2'
import { i18n } from '@/i18n'
import type { ApiService } from '@/modules/shared/domain/api/apiService'
import type { Document } from '@/modules/shared/domain/document/document'
import { asyncForEach, asyncMap } from '@/utils/array'
import type { Property } from '../domain/property'
import type { PropertyBasicInformation } from '../domain/propertyBasicInformation'
import type { CountryState, PropertyCoreInformation } from '../domain/propertyCoreInformation'
import type { PropertyDocuments } from '../domain/propertyDocuments'
import type { PropertyEquipmentInformation } from '../domain/propertyEquipmentInformation'
import type { PropertyImages } from '../domain/propertyImages'
import type { PropertyPaymentInformation } from '../domain/propertyPaymentInformation'
import type { PropertyRepository } from '../domain/propertyRepository'
import {
  fromDto,
  type PropertyDocumentDto,
  type PropertyDto,
  type PropertyImageDto,
  type ServiceBundleDto,
  toPropertyUpdateRequest,
  toServiceBundleUpdateRequest
} from './apiDto'
import { findCountryState } from './countryStateGeocode'

export function apiPropertyRepositoryBuilder(apiService: ApiService, apiV2Service: ApiService): PropertyRepository {
  let cache: Nullable<Property> = null

  async function uploadDocuments(id: string, type: string, documents: Document[]): Promise<void> {
    const filesToUpload = documents.filter(({ source }) => source !== null).map(({ source }) => source as File)

    await asyncForEach(filesToUpload, async (file) =>
      apiV2Service.upload(v2.properties().documents(id).upload(type), file)
    )
  }

  async function getById(id: string): Promise<Property> {
    if (cache?.id === id) {
      return cache
    }

    const [dto, onboardingStatus] = await Promise.all([
      apiV2Service.get<PropertyDto>(v2.landlords().properties().find(id)),
      apiService.get<{ status: string }>(v1.properties().find(id))
    ])

    let imagesDtos: PropertyImageDto[] = []
    try {
      imagesDtos = await apiV2Service.getList<PropertyImageDto>(v2.properties().images(dto.id).list())
    } catch {}

    let documentsDtos: PropertyDocumentDto[] = []
    try {
      documentsDtos = await apiV2Service.getList<PropertyDocumentDto>(
        v2.landlords().properties().documents(dto.id).list()
      )
    } catch {}

    let serviceBundleDto: Nullable<ServiceBundleDto> = null
    try {
      serviceBundleDto = await apiV2Service.get<ServiceBundleDto>(v2.landlords().properties().serviceBundle(id).find())
    } catch {}

    let state: Nullable<CountryState> = null
    if (dto.address.street) {
      try {
        state = await findCountryState(dto.address.street)
      } catch {}
    }

    const completed = onboardingStatus.status === 'onboardingCompleted'
    cache = fromDto(dto, completed, imagesDtos, documentsDtos, serviceBundleDto, state)
    return cache
  }

  return {
    async create(id: string) {
      await apiV2Service.post(v2.landlords().properties().create(), { propertyId: id })
    },
    async deleteDocument(id: string, documentId: string) {
      cache = null
      await apiV2Service.delete(v2.landlords().properties().documents(id).delete(documentId))
    },
    async deleteImage(id: string, imageId: string) {
      cache = null
      await apiV2Service.delete(v2.landlords().properties().images().delete(imageId))
    },
    async get(id: string) {
      return getById(id)
    },
    async getAll(landlordId: string) {
      const [dtos, onboardingStatuses] = await Promise.all([
        apiV2Service.getList<PropertyDto>(v2.landlords().properties().list()),
        apiService.get<{ id: string; status: string }[]>(v1.landlords().properties(landlordId).list())
      ])

      return asyncMap(dtos, async (dto) => {
        let imagesDtos: PropertyImageDto[] = []
        try {
          imagesDtos = await apiV2Service.getList<PropertyImageDto>(v2.properties().images(dto.id).list())
        } catch {}

        let serviceBundleDto: Nullable<ServiceBundleDto> = null
        try {
          serviceBundleDto = await apiV2Service.get<ServiceBundleDto>(
            v2.landlords().properties().serviceBundle(dto.id).find()
          )
        } catch {}

        const completed = onboardingStatuses.some(({ id, status }) => dto.id === id && status === 'onboardingCompleted')
        return fromDto(dto, completed, imagesDtos, [], serviceBundleDto, null)
      })
    },
    async getCatastralInformation(reference: string) {
      return apiService.get(v1.catastro().find(reference))
    },
    async getTenantNames(id: string) {
      let tenants: string[] = []
      try {
        tenants = (await apiV2Service.getList<{ name: string }>(v2.landlords().properties().tenants(id).list())).map(
          ({ name }) => name
        )
      } catch {}
      return tenants
    },
    async finishOnboarding(id: string) {
      cache = null
      const lang = i18n.global.locale.value
      return apiService.post(v1.properties().finishOnboarding(id, lang))
    },
    async updateBasicInformation(id: string, basicInformation: PropertyBasicInformation): Promise<void> {
      const property = await getById(id)
      const updatedProperty = { ...property, basicInformation }
      await apiV2Service.put(v2.landlords().properties().update(id), toPropertyUpdateRequest(updatedProperty))
      cache = updatedProperty
    },
    async updateCoreInformation(id: string, coreInformation: PropertyCoreInformation): Promise<void> {
      const property = await getById(id)
      const updatedProperty = { ...property, coreInformation }
      await apiV2Service.put(v2.landlords().properties().update(id), toPropertyUpdateRequest(updatedProperty))
      cache = updatedProperty
    },
    async updateEquipmentInformation(id: string, equipmentInformation: PropertyEquipmentInformation): Promise<void> {
      const property = await getById(id)
      const updatedProperty = { ...property, equipmentInformation }
      await apiV2Service.put(v2.landlords().properties().update(id), toPropertyUpdateRequest(updatedProperty))
      cache = updatedProperty
    },
    async updateImages(id: string, images: PropertyImages): Promise<void> {
      const property = await getById(id)
      const updatedProperty = { ...property, images }
      await apiV2Service.put(
        v2.landlords().properties().serviceBundle(id).update(),
        toServiceBundleUpdateRequest(updatedProperty)
      )
      const filesToUpload = images.files.filter(({ source }) => source !== null).map(({ source }) => source as File)
      await asyncForEach(filesToUpload, async (file) => apiV2Service.upload(v2.properties().images(id).upload(), file))
      cache = null
    },
    async updateDocuments(id: string, documents: PropertyDocuments): Promise<void> {
      await uploadDocuments(id, 'energy-cert', documents.energyCertificate)
      await uploadDocuments(id, 'habitability-cert', documents.habitabilityCertificate)
      await uploadDocuments(id, 'property-expense-bill', documents.propertyExpenses)
      await uploadDocuments(id, 'utility-bill', documents.utilityBill)
      cache = null
    },
    async updatePaymentInformation(id: string, paymentInformation: PropertyPaymentInformation): Promise<void> {
      const property = await getById(id)
      const updatedProperty = { ...property, paymentInformation }
      await apiV2Service.put(
        v2.landlords().properties().serviceBundle(id).update(),
        toServiceBundleUpdateRequest(updatedProperty)
      )
      cache = updatedProperty
    }
  }
}
