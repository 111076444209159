<template>
  <div class="container pb-1">
    <div class="flex gap-05 align-center">
      <RouterLink :to="{ name: 'properties' }">
        <IconButton :icon="icons.arrowBack" />
      </RouterLink>
      <h2>{{ t('partnerProperty.title') }}</h2>
    </div>
  </div>
  <div class="container small pt-1">
    <template v-if="property">
      <PropertyInformation v-if="!isMobile" class="mb-1" :property="property" />
      <Tabs class="mb-1" :selected="tab" :options="tabs" @select="tab = $event" />
      <PropertyInformation v-if="tab === 'information'" class="mb-1" :property="property" />
      <PropertyTimeline v-if="tab === 'timeline'" :status="property.status" />
      <PropertyContractInformation
        v-if="tab === 'contract'"
        :pricingPlan="property.paymentInformation.plan"
        :rent="property.coreInformation.rent"
        :monthlyPayment="property.coreInformation.monthlyPayment"
        :contract="contract"
      />
      <PropertyDocumentList v-if="tab === 'documentation'" :files="rentalContractFiles" />
    </template>
    <FormSkeleton v-else />
  </div>
</template>
<script lang="ts" setup>
import {
  FormSkeleton,
  IconButton,
  PropertyContractInformation,
  PropertyDocumentList,
  PropertyInformation,
  PropertyTimeline,
  Tabs
} from '@/components'
import { icons } from '@/config/assets/icons'
import { findPropertyById } from '@/modules/property/application'
import type { Property } from '@/modules/property/domain/property'
import { findPropertyContract } from '@/modules/propertyContract/application'
import type { PropertyContract } from '@/modules/propertyContract/domain/propertyContract'
import { findRentalContract } from '@/modules/rentalContract/application'
import type { RentalContractFile } from '@/modules/rentalContract/domain/rentalContract'
import { router } from '@/router'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const id = router.currentRoute.value.params.id as string

const allTabs = [
  { value: 'timeline', label: t('property.details.tabs.timeline') },
  { value: 'contract', label: t('property.details.tabs.contract') },
  { value: 'documentation', label: t('property.details.tabs.documentation') }
]
const mobileTabs = [{ value: 'information', label: t('property.details.tabs.information') }]

const tab = ref('timeline')
const contract = ref<Nullable<PropertyContract>>(null)
const rentalContractFiles = ref<RentalContractFile[]>([])
const isMobile = ref(window.innerWidth <= 768)
const tabs = computed(() => (isMobile.value ? [...mobileTabs, ...allTabs] : allTabs))

const property = ref<Nullable<Property>>(null)

onMounted(async () => {
  contract.value = await findPropertyContract(id)
  property.value = await findPropertyById(id)
  const rentalContract = await findRentalContract(id)
  rentalContractFiles.value = rentalContract?.files ?? []

  if (isMobile.value) {
    tab.value = 'information'
  }
})
</script>
