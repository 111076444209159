<template>
  <div class="wrapper">
    <div class="tabs">
      <template v-for="option in options">
        <div v-if="option.disabled" class="tab disabled">
          {{ option.label }}
        </div>
        <div v-else class="tab" :class="{ selected: selected === option.value }" @click="emit('select', option.value)">
          {{ option.label }}
        </div>
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
defineProps<{ selected: string; options: { label: string; value: string; disabled?: boolean }[] }>()
const emit = defineEmits<{ (e: 'select', option: string): void }>()
</script>
<style scoped lang="sass">
@use "@/assets/styles/responsive.sass" as *

.wrapper
  position: relative
  height: 4.5rem
  overflow-x: auto
  overflow-y: hidden
  box-sizing: border-box
  left: -1.5rem
  padding-left: 1.5rem
  padding-right: 3rem
  width: 100vw

  @include md
    width: 100%
    padding-left: 0
    padding-right: 0
    left: 0

.tabs
  position: absolute
  padding: 0.5rem
  border-radius: 3rem
  background: white
  border: 1px solid #E6E6E6
  display: flex
  gap: 0.5rem
  min-width: 100%
  box-sizing: border-box

  .tab
    padding: 1rem 2rem
    border-radius: 2rem
    flex-grow: 1
    text-align: center
    white-space: nowrap

    &.disabled
      cursor: default
      color: #9F9F9F

    &:not(.disabled)
      cursor: pointer

      &:hover
        background-color: #F5F5F5

      &.selected
        background-color: #0B0B0B
        color: white
</style>
