<template>
  <p class="text-header">{{ t('partner.commercialization') }}</p>
  <RouterLink
    class="link mb-05"
    :to="{ name: 'partnerReferrals' }"
    :class="{ active: route.name === 'partnerReferrals' }"
  >
    <img :src="icons.lead" />
    <span>{{ t('partnerReferral.title') }}</span>
  </RouterLink>
  <RouterLink
    v-if="commercializationPartnerFlag"
    class="link mb-05"
    :to="{ name: 'partnerCandidatures' }"
    :class="{ active: route.name === 'partnerCandidatures' }"
  >
    <img :src="icons.key" />
    <span>{{ t('partnerCandidature.title') }}</span>
  </RouterLink>
  <hr />
  <p class="text-header">{{ t('partner.management') }}</p>
  <RouterLink class="link" :to="{ name: 'partnerProperties' }" :class="{ active: route.name === 'partnerProperties' }">
    <img :src="icons.properties" />
    <span>{{ t('partner.title') }}</span>
  </RouterLink>
  <hr />
  <RouterLink
    v-if="billingPartnerFlag"
    class="link"
    :to="{ name: 'partnerBilling' }"
    :class="{ active: route.name === 'partnerBilling' }"
  >
    <img :src="icons.billing" />
    <span>{{ t('partnerBilling.title') }}</span>
  </RouterLink>
</template>
<script lang="ts" setup>
import { icons } from '@/config/assets/icons'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useLDFlag } from 'launchdarkly-vue-client-sdk'

const billingPartnerFlag = useLDFlag('billing-partner')
const commercializationPartnerFlag = useLDFlag('commercialization-partner')

const { t } = useI18n()
const route = useRoute()
</script>
