<template>
  <Teleport to="body">
    <PartnerReferralCreationModal
      v-if="showCreationModal"
      @close="showCreationModal = false"
      @updated="emit('updated')"
    />
  </Teleport>
  <div class="cta">
    <div class="flex align-start gap-1">
      <div class="icon"><img :src="icons.profit" /></div>
      <div>
        <p class="title mt-0">{{ t('partnerReferral.callToAction.title') }}</p>
        <p class="description mb-1">{{ t('partnerReferral.callToAction.description') }}</p>
      </div>
    </div>
    <Button class="button-md" @click="handleCreateReferral">
      {{ t('partnerReferral.callToAction.action') }}
    </Button>
    <IconButton class="button-sm" @click="handleCreateReferral" :icon="icons.sendLight" filled></IconButton>
  </div>
</template>
<script lang="ts" setup>
import { Button, IconButton, PartnerReferralCreationModal } from '@/components'
import { icons } from '@/config/assets/icons'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { useAnalytics } from '@/hooks/useAnalytics'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const emit = defineEmits(['updated'])

const { t } = useI18n()
const { track } = useAnalytics()

const showCreationModal = ref(false)

async function handleCreateReferral() {
  showCreationModal.value = true
  track(AnalyticsEvent.PartnerLeadsCreateLeadBanner)
}
</script>
<style scoped lang="sass">
@use "@/assets/styles/responsive.sass" as *

$sidebar-width-md: 5rem

.cta
  position: fixed
  bottom: 0
  left: 0
  width: 100%
  box-sizing: border-box
  padding: 0.9rem
  border-top-left-radius: 1rem
  border-top-right-radius: 1rem
  background-color: #EDB7F7
  display: flex
  justify-content: space-between
  align-items: center
  gap: 2rem

  @include md
    left: $sidebar-width-md
    width: calc(100% - #{$sidebar-width-md})

  @include lg
    display: none

  .icon
    background-color: #F6DAFB
    border-radius: 0.5rem
    padding: 0.5rem
    display: inline-flex
    align-items: center
    justify-content: center
    display: none

    @include md
      display: block

  .title
    font-size: 0.875rem

  .description
    font-size: 0.75rem

  .button-sm
    flex-shrink: 0
  .button-md
    display: none
    padding: 0.75rem 2rem
    flex-shrink: 0

  @include md
    .button-sm
      display: none
    .button-md
      display: block
</style>
