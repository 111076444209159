<template>
  <div class="container narrow">
    <form class="flex column gap-1" @submit="onSend">
      <h2>{{ t('signin.resetPassword.title') }}</h2>
      <p>{{ t('signin.resetPassword.description') }}</p>
      <InputField
        v-model="password"
        :error="errors['password']"
        :label="t('signin.resetPassword.password')"
        :placeholder="t('signin.resetPassword.passwordPlaceholder')"
        type="password"
      />
      <InputField
        v-model="passwordConfirmation"
        :error="errors['passwordConfirmation']"
        :label="t('signin.resetPassword.passwordConfirmation')"
        :placeholder="t('signin.resetPassword.passwordConfirmationPlaceholder')"
        type="password"
      />
      <Button :loading="loading" submit>{{ t('signin.resetPassword.save') }}</Button>
    </form>
  </div>
</template>
<script lang="ts" setup>
import { Button, InputField } from '@/components'
import { FormValidationError } from '@/modules/shared/domain/errors/formValidationError'
import { resetPassword, signIn } from '@/modules/user/application'
import { UserType } from '@/modules/user/domain/user'
import { router } from '@/router'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const password = ref('')
const passwordConfirmation = ref('')
const loading = ref(false)
const errors = ref<Record<string, string>>({})

function getQueryParamWithSpecialCharacters(name: string): Nullable<string> {
  return (
    location.search
      .split('&')
      .find((param) => param.startsWith(`${name}=`))
      ?.replace(`${name}=`, '') ?? null
  )
}

const token = router.currentRoute.value.query.token as Nullable<string>
const userType = router.currentRoute.value.query.type as Nullable<UserType>
const email = getQueryParamWithSpecialCharacters('email')

async function onSend(event: Event) {
  event.preventDefault()

  if (!token || !userType || !email) {
    router.push({ name: 'signIn' })
    return
  }

  loading.value = true
  errors.value = {}

  try {
    await resetPassword(token, userType, {
      currentPassword: password.value,
      password: password.value,
      passwordConfirmation: passwordConfirmation.value
    })
    await signIn({ email, password: password.value })
    router.push({ name: 'home' })
  } catch (error) {
    if (error instanceof FormValidationError) {
      errors.value = error.errors
    } else {
      router.push({ name: 'signIn' })
    }
  }

  loading.value = false
}
</script>
<style lang="sass" scoped>
.link-wrapper
  text-align: center
</style>
