import { type RentalContract, type RentalContractFile } from '@/modules/rentalContract/domain/rentalContract'
import { MimeType, mimeTypeExtensions } from '@/modules/shared/domain/document/mimeType'
import type { Uuid } from '@/modules/shared/domain/uuid/uuid'

export interface RentalContractFileDto {
  id: Uuid
  name: string
  mimeType: MimeType
  rentalContractId: string
  createdAt: string
  type: string
}

function fromFileDto(dto: RentalContractFileDto): RentalContractFile {
  const url = `${import.meta.env.VITE_FILE_STORAGE_BASE_URL}/rentalcontracts/${dto.rentalContractId}/${dto.type}/${dto.id}.${mimeTypeExtensions[dto.mimeType]}`

  return {
    createdAt: new Date(dto.createdAt),
    id: dto.id,
    name: dto.name,
    mimeType: dto.mimeType,
    url
  }
}

export function fromDto(id: Uuid, fileDtos: RentalContractFileDto[]): RentalContract {
  return { id, files: fileDtos.map(fromFileDto) }
}
