<template>
  <td
    :id="data.key"
    ref="rowItem"
    data-test-id="table-row-item"
    class="table-row-item-wrapper"
    :style="{ minWidth: data.minWidth, width: data.width }"
    :class="{
      left: data.align === 'left',
      right: data.align === 'right',
      center: data.align === 'center'
    }"
  >
    <div class="table-row-item">
      <slot />
    </div>
  </td>
</template>

<script setup lang="ts">
import { inject, onMounted, reactive, ref, nextTick } from 'vue'
import type { Options, Grid } from './types'
const rowItem = ref(null)
const options: Options = inject('options', { columns: [] })
const grid: Grid = inject('grid', {
  widths: {},
  minWidths: {},
  rowGrid: '',
  aligns: {}
})
interface TableRowItemData {
  key: string
  width: string
  minWidth: string
  align: string
}
const data: TableRowItemData = reactive({
  key: '',
  width: 'auto',
  minWidth: 'auto',
  align: 'left'
})
onMounted(async () => {
  const row = (rowItem.value as unknown as HTMLElement)?.closest('tr') as HTMLElement
  const cells = row?.getElementsByTagName('td') ?? []
  const index = Array.prototype.indexOf.call(cells, rowItem.value)
  await nextTick()
  data.key = options.columns[index].key || options.columns[index].name
  data.width = grid.widths[data.key] ?? 'auto'
  data.minWidth = grid.minWidths[data.key] ?? '100px'
  data.align = options.columns[index].align ?? 'left'
})
</script>

<style scoped lang="sass">
.table-row-item-wrapper
  min-height: 72px
  vertical-align: middle
  display: flex
  align-items: center
  justify-content: flex-start
  &.left
    justify-content: flex-start
  &.right
    justify-content: flex-end
  &.center
    justify-content: center
.table-row-item
  margin: 0 1em
  width: calc(100% - 1em)
  :deep(p)
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
    margin: 0
</style>
