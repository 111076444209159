import { PDFDocument, PDFPage, PDFFont, rgb } from 'pdf-lib'
import fontkit from '@pdf-lib/fontkit'
import { images } from '@/config/assets/images'
import { createInvoicePDF } from '@/utils/billing/invoicePdf'
import { createLiquidationPDF } from '@/utils/billing/liquidationPdf'
import type {
  LandlordLiquidation,
  LandlordInvoice,
  LandlordConcept
} from '@/modules/landlord/domain/landlordBillingDocuments'

export function drawTextWithLineBreaks(
  page: PDFPage,
  text: string,
  x: number,
  y: number,
  size: number,
  font: PDFFont,
  color: ReturnType<typeof rgb>,
  maxWidth: number
): number {
  const lines = text.split('\n')
  const lineHeight = size * 1.4
  let yPosition = y

  for (const line of lines) {
    let currentLine = ''
    const words = line.split(' ')

    for (const word of words) {
      const testLine = currentLine + (currentLine ? ' ' : '') + word
      const width = font.widthOfTextAtSize(testLine, size)

      if (width <= maxWidth || !currentLine) {
        currentLine = testLine
      } else {
        page.drawText(currentLine, { x, y: yPosition, size, font, color })
        yPosition -= lineHeight
        currentLine = word
      }
    }

    if (currentLine) {
      page.drawText(currentLine, { x, y: yPosition, size, font, color })
      yPosition -= lineHeight
    }
  }
  return yPosition
}

export function getFinalPageTextPosition(page: PDFPage, font: PDFFont, text: string, size: number): number {
  const textWidth = font.widthOfTextAtSize(text, size)
  return page.getSize().width - textWidth - 50
}

export async function createPDFBase() {
  // Page
  const pdfDoc = await PDFDocument.create()
  const page = pdfDoc.addPage([600, 800])
  const { width, height } = page.getSize()

  // Font
  pdfDoc.registerFontkit(fontkit)
  const fontUrl = new URL('@/assets/fonts/InstrumentSans.ttf', import.meta.url).href
  const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer())
  const font = await pdfDoc.embedFont(fontBytes)

  const boldFontUrl = new URL('@/assets/fonts/InstrumentSans-Bold.ttf', import.meta.url).href
  const boldFontBytes = await fetch(boldFontUrl).then((res) => res.arrayBuffer())
  const boldFont = await pdfDoc.embedFont(boldFontBytes)

  const size = 11
  const color = rgb(0, 0, 0)
  const colorGray = rgb(240 / 255, 240 / 255, 240 / 255)

  // Logo
  const imgBytes = await fetch(images.vivaraSquareYellow).then((res) => res.arrayBuffer())
  const img = await pdfDoc.embedPng(imgBytes)
  const scale = 0.25

  page.drawImage(img, {
    x: width - img.width * scale - 50,
    y: height - 100,
    width: img.width * scale,
    height: img.height * scale
  })

  return { pdfDoc, page, font, boldFont, size, color, colorGray }
}

async function getBlobUrl(pdfDoc: PDFDocument) {
  const pdfBytes = await pdfDoc.save()
  const blob = new Blob([pdfBytes], { type: 'application/pdf' })
  return URL.createObjectURL(blob)
}

async function getPdfDoc(
  billingDocument: LandlordInvoice | LandlordLiquidation,
  concepts: LandlordConcept[]
): Promise<PDFDocument | undefined> {
  let pdfDoc = undefined
  if (billingDocument.type === 'invoice') pdfDoc = await createInvoicePDF(billingDocument, concepts)
  if (billingDocument.type === 'liquidation') pdfDoc = await createLiquidationPDF(billingDocument, concepts)
  return pdfDoc
}

export async function previewPDF(billingDocument: LandlordInvoice | LandlordLiquidation, concepts: LandlordConcept[]) {
  const pdfDoc = await getPdfDoc(billingDocument, concepts)
  if (!pdfDoc) return
  const url = await getBlobUrl(pdfDoc)
  window.open(url, '_blank')
}

export async function downloadPDF(billingDocument: LandlordInvoice | LandlordLiquidation, concepts: LandlordConcept[]) {
  const pdfDoc = await getPdfDoc(billingDocument, concepts)
  if (!pdfDoc) return
  const url = await getBlobUrl(pdfDoc)
  const link = document.createElement('a')
  link.href = url
  link.download = billingDocument.fileName
  link.click()
  URL.revokeObjectURL(url)
}
