export enum AvailabilityType {
  ExactDate = 'exact-date',
  LessThanOneMonth = 'less-than-one-month',
  OneToThreeMonths = 'one-to-three-months',
  MoreThanThreeMonths = 'more-than-three-months'
}

export enum CountryState { // ISO 3166-2
  Andalusia = 'ES-AN',
  Aragon = 'ES-AR',
  Asturias = 'ES-AS',
  BalearicIslands = 'ES-IB',
  BasqueCountry = 'ES-PV',
  CanaryIslands = 'ES-CN',
  Cantabria = 'ES-CB',
  CastileandLeon = 'ES-CL',
  CastileLaMancha = 'ES-CM',
  Catalonia = 'ES-CT',
  Extremadura = 'ES-EX',
  Galicia = 'ES-GA',
  LaRioja = 'ES-RI',
  Madrid = 'ES-MD',
  Murcia = 'ES-MC',
  Navarre = 'ES-NC',
  ValencianCommunity = 'ES-VC'
}

export interface PropertyCoreInformation {
  availabilityDate: Nullable<string>
  availabilityType: Nullable<AvailabilityType>
  builtSize: Nullable<number>
  catastralReference: Nullable<string>
  city: Nullable<string>
  floor: Nullable<string>
  street: Nullable<string>
  state: Nullable<CountryState>
  monthlyPayment: Nullable<number>
  usableSize: Nullable<number>
  rent: Nullable<number>
  zipCode: Nullable<string>
}
