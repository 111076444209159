import { authService } from '@/modules/auth'
import { findTenantNamesBuilder } from '@/modules/property/application/findTenantNames'
import {
  propertyBasicInformationValidator,
  propertyCoreInformationValidator,
  propertyDocumentsValidator,
  propertyEquipmentInformationValidator,
  propertyImagesValidator,
  propertyPaymentInformationValidator,
  propertyRepository
} from '../infrastructure'
import { createBuilder } from './create'
import { deleteDocumentBuilder } from './deleteDocument'
import { deleteImageBuilder } from './deleteImage'
import { findAllBuilder } from './findAll'
import { findByIdBuilder } from './findById'
import { findCatastralInformationByReferenceBuilder } from './findCatastralInformationByReference'
import { finishOnboardingBuilder } from './finishOnboarding'
import { updateBasicInformationBuilder } from './updateBasicInformation'
import { updateCoreInformationBuilder } from './updateCoreInformation'
import { updateDocumentsBuilder } from './updateDocuments'
import { updateEquipmentInformationBuilder } from './updateEquipmentInformation'
import { updateImagesBuilder } from './updateImages'
import { updatePaymentInformationBuilder } from './updatePaymentInformation'

export const createProperty = createBuilder(propertyRepository)
export const deletePropertyDocument = deleteDocumentBuilder(propertyRepository)
export const deletePropertyImage = deleteImageBuilder(propertyRepository)
export const findAllProperties = findAllBuilder(propertyRepository, authService)
export const findPropertyById = findByIdBuilder(propertyRepository)
export const findCatastralInformationByReference = findCatastralInformationByReferenceBuilder(propertyRepository)
export const finishPropertyOnboarding = finishOnboardingBuilder(propertyRepository)
export const findTenantNames = findTenantNamesBuilder(propertyRepository)

export const updatePropertyBasicInformation = updateBasicInformationBuilder(
  propertyRepository,
  propertyBasicInformationValidator
)
export const updatePropertyCoreInformation = updateCoreInformationBuilder(
  propertyRepository,
  propertyCoreInformationValidator
)
export const updatePropertyEquipmentInformation = updateEquipmentInformationBuilder(
  propertyRepository,
  propertyEquipmentInformationValidator
)
export const updatePropertyImages = updateImagesBuilder(propertyRepository, propertyImagesValidator)
export const updatePropertyDocuments = updateDocumentsBuilder(propertyRepository, propertyDocumentsValidator)
export const updatePropertyPaymentInformation = updatePaymentInformationBuilder(
  propertyRepository,
  propertyPaymentInformationValidator
)
