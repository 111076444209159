import { zodGenericSchemaValidatorBuilder } from '@/modules/shared/infrastructure/zod/zodGenericSchemaValidator'
import type { CreatePartnerReferralForm } from '@/modules/partnerReferral/domain/createPartnerReferralForm'
import { z } from 'zod'

export const createPartnerReferralFormSchema = z.object({
  name: z.string().regex(/^.+$/, 'validationError.required'),
  surname: z.string().regex(/^.+$/, 'validationError.required'),
  email: z.string().regex(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/, 'validationError.formatEmail'),
  phone: z.string().regex(/^(?:\+34|0034|34)?[6789](\d){8}$/, 'validationError.formatPhone')
})

export function zodCreatePartnerReferralFormValidatorBuilder() {
  return zodGenericSchemaValidatorBuilder<CreatePartnerReferralForm>(createPartnerReferralFormSchema)
}
