export enum PropertyType {
  Flat = 'flat',
  Penthouse = 'penthouse',
  Duplex = 'duplex',
  AttachedSemiDetached = 'semi-detached',
  DetachedHouse = 'detached-house'
}

export enum PropertyCondition {
  New = 'new',
  VeryGood = 'very-good',
  Good = 'good',
  NeedsRefurbish = 'needs-refurbish'
}

export interface PropertyBasicInformation {
  bathrooms: Nullable<number>
  bedrooms: Nullable<number>
  pets: Nullable<boolean>
  propertyCondition: Nullable<PropertyCondition>
  propertyType: Nullable<PropertyType>
}
