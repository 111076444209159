import type { Landlord } from '../domain/landlord'
import type { LandlordInvoice, LandlordLiquidation, LandlordConcept } from '../domain/landlordBillingDocuments'
import type { Property } from '@/modules/property/domain/property'

export interface LandlordApiDto {
  createdAt: string
  files: {
    type: string
    id: string
    originalName: string
    path?: string
  }[]
}

export function fromDto(dto: LandlordApiDto, acceptedTermsAndConditionsAt: Nullable<Date>): Landlord {
  return {
    acceptedTermsAndConditionsAt,
    createdAt: new Date(dto.createdAt),
    documents: {
      id:
        dto.files
          ?.filter(({ type }) => type === 'id')
          .map(({ id, originalName, path }) => ({
            name: originalName,
            id,
            uri: path ? `${import.meta.env.VITE_FILE_STORAGE_BASE_URL}/userfiles/${path}` : null,
            source: null
          })) ?? []
    }
  }
}

export interface LandlordLiquidationDto {
  property_id: string
  liquidation_id: string
  final_amount: string // Final amount of liquidation (positivo)
  liquidation_date: string
  liquidated_from: string
  liquidated_to: string
  bill_number: string // Final amount of the invoice (position / negativo)
  bill_date: string
  bill_due_date: string
  bill_recipient_name: string
  bill_recipient_identification_number: string
  bill_recipient_address: string
  bill_final_amount: string
}

export interface LandlordLiquidationConceptDto {
  liquidation_id: number
  type: string
  statement: string
  charged_at: string
  final_amount: number
  taxes_percentage: number
}

export function fromLiquidationDtoToLandlordInvoice(
  liquidationDto: LandlordLiquidationDto,
  property: Property
): LandlordInvoice {
  return {
    type: 'invoice',
    fileName: `Factura_${liquidationDto.bill_number}.pdf`,
    liquidationId: liquidationDto.liquidation_id,
    propertyId: liquidationDto.property_id,
    invoiceNumber: liquidationDto.bill_number,
    issuedDate: liquidationDto.bill_date,
    dueDate: liquidationDto.bill_due_date,
    propertyAddress: [
      property.coreInformation?.street ?? '',
      property.coreInformation?.floor ?? '',
      property.coreInformation?.city ?? '',
      property.coreInformation?.zipCode ?? ''
    ]
      .filter(Boolean)
      .join(', '),
    sender: {
      name: 'Vivara Solutions, SL',
      identificationNumber: 'B72812415',
      address: 'Alcalde Barnils 63, 08174, Sant Cugat del Vallès'
    },
    recipient: {
      name: liquidationDto.bill_recipient_name,
      identificationNumber: liquidationDto.bill_recipient_identification_number,
      address: liquidationDto.bill_recipient_address
    },
    paymentMethod: 'bankTransfer',
    finalAmount: +liquidationDto.bill_final_amount * -1
  }
}

export function fromLiquidationDtoToLandlordLiquidation(
  liquidationDto: LandlordLiquidationDto,
  property: Property
): LandlordLiquidation {
  const propertyAddress = [
    property.coreInformation?.street ?? '',
    property.coreInformation?.floor ?? '',
    property.coreInformation?.city ?? '',
    property.coreInformation?.zipCode ?? ''
  ]
    .filter(Boolean)
    .join(', ')
  return {
    type: 'liquidation',
    fileName: `Liq_${propertyAddress}_${liquidationDto.liquidation_date}.pdf`,
    liquidationId: liquidationDto.liquidation_id,
    propertyId: liquidationDto.property_id,
    invoiceNumber: liquidationDto.bill_number,
    issuedDate: liquidationDto.bill_date,
    startPeriod: liquidationDto.liquidated_from,
    endPeriod: liquidationDto.liquidated_from,
    propertyAddress,
    liquidationDate: liquidationDto.liquidation_date,
    landlord: {
      id: liquidationDto.property_id,
      name: liquidationDto.bill_recipient_name,
      identificationNumber: liquidationDto.bill_recipient_identification_number,
      address: liquidationDto.bill_recipient_address
    },
    sender: {
      name: 'Vivara Solutions, SL',
      identificationNumber: 'B72812415',
      address: 'Alcalde Barnils 63, 08174, Sant Cugat del Vallès'
    },
    finalAmount: Math.abs(+liquidationDto.bill_final_amount)
  }
}

export function fromLiquidationConceptDtoToLandlordLiquidationConcept(
  liquidationConceptDto: LandlordLiquidationConceptDto[]
): LandlordConcept[] {
  return liquidationConceptDto.map((concept) => ({
    statement: concept.statement,
    chargedAt: concept.charged_at,
    amount: concept.final_amount,
    taxesPercentage: concept.taxes_percentage,
    type: concept.type
  }))
}
