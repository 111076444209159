import { findCurrentUser } from '@/modules/user/application'
import { PartnerBilling, PartnerCandidatures, PartnerProperties, PartnerProperty, PartnerReferrals } from '@/views'
import type { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'

export const partnerRoutes: RouteRecordRaw[] = [
  {
    path: '/partner/properties',
    name: 'partnerProperties',
    component: PartnerProperties,
    beforeEnter: partnerGuard,
    meta: { partner: true }
  },
  {
    path: '/partner/property/:id',
    name: 'partnerProperty',
    component: PartnerProperty,
    beforeEnter: partnerGuard,
    meta: { partner: true }
  },
  {
    path: '/partner/referrals',
    name: 'partnerReferrals',
    component: PartnerReferrals,
    beforeEnter: partnerGuard,
    meta: { partner: true }
  },
  {
    path: '/partner/billing',
    name: 'partnerBilling',
    component: PartnerBilling,
    beforeEnter: partnerGuard,
    meta: { partner: true }
  },
  {
    path: '/partner/candidatures',
    name: 'partnerCandidatures',
    component: PartnerCandidatures,
    beforeEnter: partnerGuard,
    meta: { partner: true }
  }
]

export async function partnerGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const user = await findCurrentUser()
  return !user.name ? next({ name: 'personalInformation' }) : next()
}
