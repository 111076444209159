import { createBuilder } from '@/modules/partnerReferral/application/create'
import { findAllBuilder } from '@/modules/partnerReferral/application/findAll'
import { createPartnerReferralFormValidator, partnerReferralRepository } from '@/modules/partnerReferral/infrastructure'
import { generateUuid } from '@/modules/shared/domain/uuid/uuid'

export const createPartnerReferral = createBuilder(
  partnerReferralRepository,
  createPartnerReferralFormValidator,
  generateUuid
)

export const findAllPartnerReferrals = findAllBuilder(partnerReferralRepository)
