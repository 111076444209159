<template>
  <div id="app">
    <FullScreenLoader v-if="loading" />
    <template v-else>
      <DashboardLayout v-if="layoutType === 'dashboard' && user" :user="user">
        <RouterView />
      </DashboardLayout>
      <BasicLayout v-else :user="user">
        <RouterView />
      </BasicLayout>
      <ZapierChatbot v-if="zapierChatbotId" :chatbotId="zapierChatbotId" />
    </template>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { RouterView, useRoute } from 'vue-router'
import { BasicLayout, FullScreenLoader, DashboardLayout, ZapierChatbot } from './components'
import { findCurrentUser } from './modules/user/application'
import { UserType, type User } from './modules/user/domain/user'
import { useLDClient } from 'launchdarkly-vue-client-sdk'

const ldClient = useLDClient()

const route = useRoute()
const loading = ref(true)
const user = ref<Nullable<User>>(null)
const layoutType = ref<'basic' | 'dashboard'>('basic')
const zapierChatbotId = computed(() => {
  if (!route.meta.zapierChatbot || !user.value || user.value.type === UserType.Partner) return null

  return user.value.type === 'landlord'
    ? import.meta.env.VITE_ZAPIER_LANDLORD_CHATBOT_ID
    : import.meta.env.VITE_ZAPIER_TENANT_CHATBOT_ID
})

onMounted(async () => {
  try {
    user.value = await findCurrentUser()
  } catch {}
  loading.value = false
})

watch(route, async ({ meta }) => {
  layoutType.value = meta.basicLayout ? 'basic' : 'dashboard'
  if (meta.signedOut) {
    user.value = null
  } else {
    user.value = await findCurrentUser()

    ldClient.identify({
      kind: 'user',
      key: user.value.id,
      name: `${user.value.name} ${user.value.surname}`,
      email: user.value.email,
      role: user.value.type
    })
  }
})
</script>
<style lang="sass">
@use "@/assets/styles/main.sass" as *

#app
  position: relative
  min-height: 100vh
</style>
