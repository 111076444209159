<template>
  <OnboardingCompletedModal v-if="showOnboardingCompletedModal" />
  <PaymentInformationSummary v-if="paymentInformation" :paymentInformation="paymentInformation" mobile />
  <div class="container fill">
    <div class="row">
      <div class="col-4 col-md-8 col-lg-3 col-xl-3">
        <PropertyStepper :property="property" :landlord="landlord" />
      </div>
      <div class="col-4 col-md-8 col-lg-6 col-xl-7">
        <PaymentInformationForm v-model="paymentInformation" />
        <div class="mt-2 flex align-center gap-05" id="termsAndConditionsCheckbox">
          <Checkbox v-model="termsAndConditionsCheck" data-test-id="termsAndConditionsCheckbox" />
          <p>
            <span>{{ t('property.payment.termsAndConditions.readAndAccept') }}&nbsp;</span>
            <a :href="termsAndConditionsUrl" target="_blank">
              {{ t('property.payment.termsAndConditions.link') }}
            </a>
          </p>
        </div>
        <div class="error-message" v-if="showTermsAndConditionsError">
          {{ t('property.payment.termsAndConditions.error') }}
        </div>
      </div>
      <div class="col-4 col-md-2 col-lg-3 col-xl-2" v-if="paymentInformation">
        <PaymentInformationSummary :paymentInformation="paymentInformation" />
      </div>
    </div>
  </div>
  <Teleport to="#basicLayoutFooter" v-if="landlord && property && paymentInformation">
    <PropertyStepper mobile :property="property" :landlord="landlord" />
    <OnboardingFooter
      v-if="!showOnboardingCompletedModal"
      :loading="loading"
      :landlord="landlord"
      :property="{ ...property, paymentInformation }"
      @continue="onContinue"
      @finish="onFinish"
      @exit="onExit"
    />
  </Teleport>
</template>
<script lang="ts" setup>
import {
  Checkbox,
  OnboardingCompletedModal,
  OnboardingFooter,
  PaymentInformationForm,
  PaymentInformationSummary,
  PropertyStepper
} from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { findCurrentLandlord, updateCurrentLandlord } from '@/modules/landlord/application'
import { mustAcceptTheTermsAndConditions, type Landlord } from '@/modules/landlord/domain/landlord'
import {
  findPropertyById,
  finishPropertyOnboarding,
  updatePropertyPaymentInformation
} from '@/modules/property/application'
import type { Property } from '@/modules/property/domain/property'
import type { PropertyPaymentInformation } from '@/modules/property/domain/propertyPaymentInformation'
import { router } from '@/router'
import { onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const { track } = useAnalytics()

const id = router.currentRoute.value.params.id as string
const landlord = ref<Landlord | undefined>()
const property = ref<Property | undefined>()
const paymentInformation = ref<PropertyPaymentInformation | undefined>()
const loading = ref(false)
const showOnboardingCompletedModal = ref(false)
const termsAndConditionsCheck = ref(false)
const showTermsAndConditionsError = ref(false)
const termsAndConditionsUrl = import.meta.env.VITE_SERVICES_TERMS_AND_CONDITIONS_URL

track(AnalyticsEvent.LandlordPropertyRegisterStartPlan)

onMounted(async () => {
  landlord.value = await findCurrentLandlord()
  property.value = await findPropertyById(id)
  paymentInformation.value = { ...property.value.paymentInformation }
  termsAndConditionsCheck.value = !mustAcceptTheTermsAndConditions(landlord.value)
})

async function save() {
  if (!paymentInformation.value || !property.value || !landlord.value) return

  loading.value = true
  try {
    if (termsAndConditionsCheck.value) {
      await updateCurrentLandlord(landlord.value)
    }
    await updatePropertyPaymentInformation(id, paymentInformation.value)
    track(AnalyticsEvent.LandlordPropertyRegisterSubmitPlan)
  } catch {
    loading.value = false
  }
}

async function onExit() {
  await save()
  await router.push({ name: 'properties' })
}

async function onContinue() {
  if (!termsAndConditionsCheck.value) {
    showTermsAndConditionsError.value = true
    scrollToTermsAndConditions()
    loading.value = false
  } else {
    await save()
    await router.push({ name: 'resumeOnboarding' })
  }
}

async function onFinish() {
  if (!termsAndConditionsCheck.value) {
    showTermsAndConditionsError.value = true
    scrollToTermsAndConditions()
    loading.value = false
  } else {
    await save()
    await finishPropertyOnboarding(id)
    track(AnalyticsEvent.LandlordOnboardingCompleted)
    showOnboardingCompletedModal.value = true
  }
}

function scrollToTermsAndConditions() {
  const element = document.getElementById('termsAndConditionsCheckbox')
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}

watch(termsAndConditionsCheck, (value) => {
  if (!landlord.value) return
  landlord.value.acceptedTermsAndConditionsAt = value ? new Date() : null
})

watch(
  () => paymentInformation.value,
  (_, previousValue) => {
    if (previousValue === undefined) return
    termsAndConditionsCheck.value = false
  },
  { deep: true }
)
</script>
