<template>
  <div class="image-card">
    <div class="image"></div>
    <div class="content">
      <div class="mb-05"></div>
      <div></div>
    </div>
  </div>
</template>
<style scoped lang="sass">

@keyframes preloadAnimation
  0%
    background: #EEE
  50%
    background: #FFF
  100%
    background: #EEE

.image-card
  width: 100%
  box-shadow: 0px 1px 4px 0px #0000001A
  border-radius: 1rem
  background-color: white
  min-width: 18rem

  @media only screen and (min-width: 768px)
    max-width: 18rem

  .image
    overflow: hidden
    height: 13rem
    border-top-left-radius: 1rem
    border-top-right-radius: 1rem
    animation-name: preloadAnimation
    animation-duration: 2s
    animation-iteration-count: infinite
    animation-timing-function: linear

  .content
    background-color: white
    padding: 1rem
    padding-bottom: 0
    border-radius: 1rem
    position: relative
    top: -1rem

    div
      height: 1.5rem
      border-radius: 0.5rem
      animation-name: preloadAnimation
      animation-duration: 2s
      animation-iteration-count: infinite
      animation-timing-function: linear
</style>
