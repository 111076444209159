import type { PropertyStatus } from '@/modules/property/domain/propertyStatus'
import type { PropertyCommercialization } from '@/modules/propertyCommercializations/domain/propertyCommercialization'

export interface PropertyCommercializationDto {
  id: string
  address: {
    city: Nullable<string>
    floor: Nullable<string>
    street: Nullable<string>
    zipCode: Nullable<string>
  }
  status: PropertyStatus
  createdAt: string
}

export function fromPropertyCommercializationDto(dto: PropertyCommercializationDto): PropertyCommercialization {
  return {
    city: dto.address.city,
    createdAt: new Date(dto.createdAt),
    propertyId: dto.id,
    status: dto.status,
    street: dto.address.street,
    zipCode: dto.address.zipCode
  }
}
