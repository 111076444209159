import type { PropertyPaymentInformation } from '@/modules/property/domain/propertyPaymentInformation'
import { zodGenericSchemaValidatorBuilder } from '@/modules/shared/infrastructure/zod/zodGenericSchemaValidator'
import { z } from 'zod'

export const propertyPaymentInformationSchema = z.object({
  hasCommercialization: z.boolean().nullable(),
  plan: z.union([z.literal('basic'), z.literal('comfort'), z.literal('secured'), z.literal('peaceOfMind')]).nullable(),
  nonPaymentCoverage: z.union([z.literal(12), z.literal(18), z.literal(24)]).nullable()
})

export function zodPropertyPaymentInformationValidatorBuilder() {
  return zodGenericSchemaValidatorBuilder<PropertyPaymentInformation>(propertyPaymentInformationSchema)
}
