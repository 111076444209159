import type { PartnerInvoice } from '@/modules/partnerBilling/domain/partnerBillingDocuments'
import type { PartnerProperty } from '@/modules/partnerProperty/domain/partnerProperty'

export interface PartnerInvoiceDto {
  property_id: string
  partner_id: string
  invoice_date: string
  concept: string
  collaboration_type: string
  total_amount: string
}

export function fromInvoiceDtoToPartnerInvoice(
  invoiceDto: PartnerInvoiceDto,
  property: PartnerProperty
): PartnerInvoice {
  const propertyAddress = [property?.street ?? '', property?.floor ?? '', property?.city ?? '', property?.zipCode ?? '']
    .filter(Boolean)
    .join(', ')

  return {
    type: 'invoice',
    propertyId: invoiceDto.property_id,
    propertyAddress,
    partnerId: invoiceDto.partner_id,
    issuedDate: invoiceDto.invoice_date,
    concept: invoiceDto.concept,
    collaborationType: invoiceDto.collaboration_type,
    finalAmount: invoiceDto.total_amount
  }
}
