export const apiEndpointsV2 = {
  applications: () => ({
    create: () => '/platform/tenants/me/applications',
    find: (applicationId: string) => `/platform/tenants/me/applications/${applicationId}`,
    list: () => '/platform/tenants/me/applications?page=1&pageSize=50',
    submit: (applicationId: string) => `/platform/tenants/me/applications/${applicationId}/submit`,
    coTenants: (applicationId: string) => ({
      list: () => `/platform/tenants/me/applications/${applicationId}/co-tenants`
    }),
    tenants: (applicationId: string) => ({
      link: (tenantId: string) => `/platform/tenants/me/applications/${applicationId}/co-tenants/${tenantId}`,
      unlink: (tenantId: string) => `/platform/tenants/me/applications/${applicationId}/co-tenants/${tenantId}`
    })
  }),
  coTenants: () => ({
    find: (coTenantId: string) => `/platform/tenants/co-tenants/${coTenantId}`,
    documents: (coTenantId: string) => ({
      delete: (documentId: string) => `/platform/tenants/co-tenant-files/${documentId}`,
      list: () => `/platform/tenants/co-tenants/${coTenantId}/files`,
      upload: (documentType: string) => `/platform/tenants/co-tenants/${coTenantId}/files/${documentType}`
    }),
    search: (email: string) => `/platform/tenants/co-tenants/email-search?value=${encodeURIComponent(email)}`,
    workingStatus: (coTenantId: string) => ({
      update: () => `/platform/tenants/co-tenants/${coTenantId}/working-status`
    })
  }),
  landlords: () => ({
    documents: () => ({
      upload: (documentType: string) => `/onboarding/landlords/me/files/${documentType}`
    }),
    properties: () => ({
      create: () => '/platform/landlords/me/properties',
      documents: (propertyId: string) => ({
        delete: (documentId: string) => `/platform/landlords/me/property-files/${documentId}`,
        list: () => `/platform/landlords/me/properties/${propertyId}/files`
      }),
      images: () => ({
        delete: (imageId: string) => `/platform/landlords/me/property-images/${imageId}`
      }),
      find: (propertyId: string) => `/platform/landlords/me/properties/${propertyId}`,
      list: () => '/platform/landlords/me/properties',
      serviceBundle: (propertyId: string) => ({
        find: () => `/platform/landlords/me/properties/${propertyId}/service-bundle`,
        update: () => `/platform/landlords/me/properties/${propertyId}/service-bundle`
      }),
      rentalContract: (propertyId: string) => ({
        find: () => `/platform/landlords/me/properties/${propertyId}/rental-contracts/active`,
        files: (rentalContractId: string) => ({
          list: () => `/platform/landlords/me/rental-contracts/${rentalContractId}/files`
        })
      }),
      tenants: (propertyId: string) => ({
        list: () => `/platform/landlords/me/properties/${propertyId}/tenants`
      }),
      update: (propertyId: string) => `/platform/landlords/me/properties/${propertyId}`
    })
  }),
  partners: () => ({
    create: () => '/public/partners',
    find: () => '/platform/partners/me',
    landlord: () => ({
      find: (landlordId: string) => `/platform/partners/me/landlords/${landlordId}`
    }),
    update: () => '/platform/partners/me',
    properties: () => ({
      find: (propertyId: string) => `/platform/partners/me/properties/${propertyId}`,
      list: () => '/platform/partners/me/properties',
      serviceBundle: (propertyId: string) => ({
        find: () => `/platform/partners/me/properties/${propertyId}/service-bundle`
      }),
      tenants: (propertyId: string) => ({
        list: () => `/platform/partners/me/properties/${propertyId}/tenants`
      })
    }),
    propertyCommercializations: () => ({
      list: () => '/platform/partners/me/property-commercializations'
    }),
    referrals: () => ({
      create: () => '/platform/partners/me/referrals',
      list: () => '/platform/partners/me/referrals'
    })
  }),
  properties: () => ({
    find: (propertyId: string) => `/onboarding/properties/${propertyId}`,
    documents: (propertyId: string) => ({
      upload: (documentType: string) => `/onboarding/properties/${propertyId}/files/${documentType}`
    }),
    images: (propertyId: string) => ({
      list: () => `/public/properties/${propertyId}/images`,
      upload: () => `/onboarding/properties/${propertyId}/images`
    }),
    serviceBundle: (propertyId: string) => ({
      find: () => `/onboarding/properties/${propertyId}/service-bundle`,
      update: () => `/onboarding/properties/${propertyId}/service-bundle`
    })
  }),
  tenants: () => ({
    documents: () => ({
      delete: (documentId: string) => `/onboarding/tenants/me/files/${documentId}`,
      list: () => '/onboarding/tenants/me/files',
      upload: (documentType: string) => `/onboarding/tenants/me/files/${documentType}`
    }),
    leads: () => ({
      find: () => '/platform/tenants/leads/me'
    }),
    properties: () => ({
      find: (propertyId: string) => `/platform/tenants/properties/${propertyId}`,
      list: () => '/platform/tenants/properties'
    }),
    workingStatus: () => ({
      update: () => '/platform/tenants/me/working-status'
    })
  })
}
