import type { PDFDocument } from 'pdf-lib'
import { createPDFBase, drawTextWithLineBreaks, getFinalPageTextPosition } from './pdfLibUtils'
import type { LandlordLiquidation, LandlordConcept } from '@/modules/landlord/domain/landlordBillingDocuments'
import { toLocaleCurrencySymbol } from '../numbers'
import { i18n } from '@/i18n'

export async function createLiquidationPDF(
  liquidation: LandlordLiquidation,
  concepts: LandlordConcept[]
): Promise<PDFDocument> {
  const locale = i18n.global.locale
  const { pdfDoc, page, font, boldFont, size, color, colorGray } = await createPDFBase()

  let y = page.getSize().height - 50

  // Title
  page.drawText('Liquidación', { x: 50, y, size: 24, font, color })
  y -= 50

  // Main Data Invoice
  page.drawText(`Periodo ${liquidation.startPeriod} - ${liquidation.endPeriod}`, {
    x: 50,
    y,
    size,
    font: boldFont,
    color
  })
  y -= 20
  page.drawText(liquidation.propertyAddress, { x: 50, y, size, font: boldFont, color })
  y -= 20
  page.drawText(`Fecha de liquidación ${liquidation.liquidationDate}`, { x: 50, y, size, font: boldFont, color })
  y -= 40

  //----------------------------

  const yStartData = y
  page.drawText('Emisor:', { x: 310, y, size, font: boldFont, color })
  y = drawTextWithLineBreaks(page, liquidation.sender.name, 380, y, size, font, color, 160)

  page.drawText('NIF/CIF:', { x: 310, y, size, font: boldFont, color })
  y = drawTextWithLineBreaks(page, liquidation.sender.identificationNumber, 380, y, size, font, color, 160)

  page.drawText('Dirección:', { x: 310, y, size, font: boldFont, color })
  y = drawTextWithLineBreaks(page, liquidation.sender.address, 380, y, size, font, color, 160)

  //----------------------------

  y = yStartData
  page.drawText('Propietario:', { x: 50, y, size, font: boldFont, color })
  y = drawTextWithLineBreaks(page, liquidation.landlord.name, 120, y, size, font, color, 160)

  page.drawText('NIF/CIF:', { x: 50, y, size, font: boldFont, color })
  y = drawTextWithLineBreaks(page, liquidation.landlord.identificationNumber, 120, y, size, font, color, 160)

  page.drawText('Dirección:', { x: 50, y, size, font: boldFont, color })
  y = drawTextWithLineBreaks(page, liquidation.landlord.address, 120, y, size, font, color, 160)

  y -= 40

  // Header table
  page.drawRectangle({ x: 40, y: y - 10, width: 520, height: 25, color: colorGray })
  page.drawText('CONCEPTO', { x: 50, y, size: 8, font })
  page.drawText('INGRESOS', { x: 400, y, size: 8, font })
  page.drawText('PAGOS', { x: getFinalPageTextPosition(page, font, 'IMPORTE', 8), y, size: 8, font })
  y -= 10
  const yStartTable = y

  page.drawLine({ start: { x: 50, y }, end: { x: 550, y }, thickness: 1, color: colorGray })
  y -= 20

  // Items
  let totalRent = 0
  let totalClaimable = 0

  concepts.forEach((item) => {
    const formatAmount = (amount: number) => toLocaleCurrencySymbol(amount, locale.value)
    const amount = Math.abs(item.amount)

    if (item.type === 'rent') {
      const value = formatAmount(amount)
      page.drawText(value, { x: 400, y, size, font })
      y = drawTextWithLineBreaks(page, `${item.statement} (${item.chargedAt})`, 50, y, size, font, color, 400)
      totalRent += amount
    }

    if (item.type === 'claimable') {
      let netAmount = amount
      let taxAmount = 0

      // Taxes calculation
      if (item.taxesPercentage > 0) {
        netAmount = amount / (1 + item.taxesPercentage / 100)
        taxAmount = amount - netAmount
      }

      const value = formatAmount(netAmount)
      page.drawText(value, { x: getFinalPageTextPosition(page, font, value, size), y, size, font })
      y = drawTextWithLineBreaks(page, `${item.statement} (${item.chargedAt})`, 50, y, size, font, color, 400)
      totalClaimable += amount

      // Taxes Line
      if (item.taxesPercentage > 0) {
        const netAmountValue = formatAmount(netAmount)
        const taxAmountValue = formatAmount(taxAmount)
        page.drawText(`IVA/IGIC ${item.taxesPercentage}% sobre ${netAmountValue}`, { x: 80, y, size, font })
        page.drawText(taxAmountValue, { x: getFinalPageTextPosition(page, font, taxAmountValue, size), y, size, font })
        y -= 15
      }
    }

    // Dibujar línea separadora
    page.drawLine({
      start: { x: 40, y: y + 6 },
      end: { x: page.getSize().width - 40, y: y + 6 },
      thickness: 1,
      color: colorGray
    })
    y -= 15
  })
  y -= 10

  // Table Footer
  let value = ''
  page.drawText('Total', { x: 320, y, size, font: boldFont })
  value = toLocaleCurrencySymbol(totalRent, locale.value)
  page.drawText(value, { x: 400, y, size, font: boldFont })
  value = toLocaleCurrencySymbol(totalClaimable, locale.value)
  page.drawText(value, { x: getFinalPageTextPosition(page, font, value, size), y, size, font: boldFont })

  y -= 20

  // Table Lines
  page.drawLine({ start: { x: 40, y: yStartTable }, end: { x: 40, y }, thickness: 1, color: colorGray })
  page.drawLine({ start: { x: 390, y: yStartTable }, end: { x: 390, y }, thickness: 1, color: colorGray })
  page.drawLine({ start: { x: 470, y: yStartTable }, end: { x: 470, y }, thickness: 1, color: colorGray })
  page.drawLine({
    start: { x: page.getSize().width - 40, y: yStartTable },
    end: { x: page.getSize().width - 40, y },
    thickness: 1,
    color: colorGray
  })
  page.drawLine({ start: { x: 40, y }, end: { x: page.getSize().width - 40, y }, thickness: 1, color: colorGray })
  y -= 20

  // Total
  page.drawText('Saldo a favor', { x: 320, y, size: 12, font: boldFont })
  value = toLocaleCurrencySymbol(liquidation.finalAmount, locale.value)
  page.drawText(value, { x: getFinalPageTextPosition(page, font, value, 12), y, size: 12, font: boldFont })

  return pdfDoc
}
