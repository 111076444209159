import type { PartnerProperty } from '@/modules/partnerProperty/domain/partnerProperty'
import type { PartnerInvoice } from '@/modules/partnerBilling/domain/partnerBillingDocuments'
import type { DatabaseService } from '@/modules/shared/domain/database/apiService'
import type { PartnerBillingRepository } from '@/modules/partnerBilling/domain/partnerBillingRepository'

import { fromInvoiceDtoToPartnerInvoice, type PartnerInvoiceDto } from './apiDto'

export function apiPartnerBillingRepositoryBuilder(databaseService: DatabaseService): PartnerBillingRepository {
  return {
    async getBillingDocuments(property: PartnerProperty): Promise<PartnerInvoice[]> {
      const results = await databaseService.select<PartnerInvoiceDto>(
        'partner_invoice',
        'property_id',
        property.id,
        'invoice_date'
      )
      return results?.map((result) => fromInvoiceDtoToPartnerInvoice(result, property)) || []
    }
  }
}
