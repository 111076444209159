<template>
  <div class="container">
    <h1 class="mb-2">{{ t('partner.title') }}</h1>
    <hr />
    <div class="flex justify-between mt-1" v-if="properties.length > 0">
      <div class="flex">
        <p class="label">{{ properties.length }}</p>
        &nbsp;
        <p class="light">{{ t('partner.properties', properties.length) }}</p>
      </div>
      <div class="flex">
        <p class="label">{{ t('partner.sortedBy') }}</p>
        &nbsp;
        <p class="light">{{ t('partner.creationDate') }}</p>
      </div>
    </div>
    <div class="grid-content mt-1">
      <template v-if="loading">
        <ImageCardSkeleton />
        <ImageCardSkeleton />
        <ImageCardSkeleton />
      </template>
      <template v-else>
        <template v-if="properties.length > 0">
          <RouterLink
            v-for="property in properties"
            class="unstyled flex-grow"
            :to="{ name: 'partnerProperty', params: { id: property.id } }"
          >
            <ImageCard :source="property.images[0]" interactable data-test-id="partnerPropertyCard">
              <div>
                <div class="flex justify-end mb-1">
                  <PropertyStatusBadge :status="property.status" />
                </div>
                <p class="address title mb-1">{{ property.street }}, {{ property.city }}</p>
              </div>
              <div>
                <p class="light">{{ t('partner.landlord') }}</p>
                <p class="landlord">{{ property.landlord.name }}</p>

                <hr />
                <p class="monthly-payment mt-1" v-if="property.monthlyPayment">
                  {{ property.monthlyPayment }} €/{{ t('common.month') }}
                </p>
                <p class="monthly-payment mt-1" v-else>{{ t('partner.monthlyPaymentNotDefined') }}</p>
                <p class="plan" v-if="property.pricingPlan">
                  {{ t(`property.payment.${property.pricingPlan}.name`) }}
                </p>
                <p class="plan light" v-else>{{ t('partner.planNotSelected') }}</p>
              </div>
            </ImageCard>
          </RouterLink>
        </template>
        <div v-else class="empty">
          <div class="flex column align-center gap-1">
            <img :src="images.partnerProperties" class="mb-2" />
            <h2>{{ t('partner.empty.title') }}</h2>
            <p class="title">{{ t('partner.empty.description') }}</p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ImageCard, ImageCardSkeleton, PropertyStatusBadge } from '@/components'
import { images } from '@/config/assets/images'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { findAllPartnerProperties } from '@/modules/partnerProperty/application'
import type { PartnerProperty } from '@/modules/partnerProperty/domain/partnerProperty'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { track } = useAnalytics()

const properties = ref<PartnerProperty[]>([])
const loading = ref(true)

track(AnalyticsEvent.PartnerPropertiesPropertiesList)

onMounted(async () => {
  properties.value = await findAllPartnerProperties()
  loading.value = false
})
</script>
<style lang="sass" scoped>
.grid-content
  display: grid
  gap: 1rem
  grid-template-columns: repeat(auto-fill, minmax(18rem, max-content))
  align-items: stretch

  @media screen and (max-width: 768px)
    grid-template-columns: auto
    max-width: 24rem
    justify-self: center

.empty
  text-align: center
  padding-top: 1rem
  max-width: 48rem

.address, .monthly-payment
  font-weight: 500
  font-size: 1.125rem

.plan, .landlord
  font-size: 1rem
</style>
