import type { DatabaseService } from '@/modules/shared/domain/database/apiService'
import { createClient } from '@supabase/supabase-js'

export function supabaseDatabaseBuilder(): DatabaseService {
  const supabase = createClient(import.meta.env.VITE_SUPABASE_URL, import.meta.env.VITE_SUPABASE_KEY)

  return {
    async select<T>(
      table: string,
      byField: string,
      value: string,
      orderBy?: string,
      orderDirection: 'asc' | 'desc' = 'asc'
    ): Promise<Nullable<T[]>> {
      try {
        let query = supabase.from(table).select('*').eq(byField, value)

        if (orderBy) {
          query = query.order(orderBy, { ascending: orderDirection === 'asc' })
        }

        const result = await query
        return result.data?.length ? result.data : null
      } catch {
        return null
      }
    }
  }
}
