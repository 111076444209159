import type { Uuid } from '@/modules/shared/domain/uuid/uuid'

export const apiEndpointsV1 = {
  catastro: () => ({
    find: (reference: string) => `/properties-v2/catastro?ref=${reference}`
  }),
  landlords: () => ({
    create: () => '/landlords',
    delete: (landlordId: string) => `/landlords/${landlordId}`,
    find: (landlordId: string) => `/landlords/${landlordId}`,
    update: (landlordId: string, lang: string) => `/landlords/${landlordId}?lang=${lang}`,
    documents: (landlordId: string) => ({
      delete: (documentId: string) => `/landlords/${landlordId}/documents/${documentId}`
    }),
    properties: (landlordId: string) => ({
      list: () => `/landlords/${landlordId}/properties`
    })
  }),
  properties: () => ({
    find: (propertyId: string) => `/properties-v2/${propertyId}`,
    finishOnboarding: (propertyId: string, lang: string) =>
      `/properties-v2/${propertyId}/finish-onboarding?lang=${lang}`
  }),
  tenants: () => ({
    create: () => '/tenants',
    delete: (tenantId: Uuid) => `/tenants/${tenantId}`,
    find: (tenantId: Uuid) => `/tenants/${tenantId}`,
    update: (tenantId: Uuid) => `/tenants/${tenantId}`,
    cotenants: (tenantId: Uuid) => ({
      create: () => `/tenants/${tenantId}`
    })
  })
}
