<template></template>
<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue'

const props = defineProps<{ chatbotId: string }>()

const tag = 'zapier-interfaces-chatbot-embed'

onMounted(() => {
  const isInitialized = document.querySelector(tag) !== null
  if (isInitialized) return

  const embed = document.createElement(tag)
  embed.setAttribute('is-popup', 'true')
  embed.setAttribute('chatbot-id', props.chatbotId)
  document.body.appendChild(embed)
})

onUnmounted(() => {
  const embed = document.querySelector(tag)
  if (embed) {
    document.body.removeChild(embed)
  }
})
</script>
