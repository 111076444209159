export const enMessages = {
  'application.details.description':
    'Here you can consult the documentation you have sent for the property application. This section is read only. If you wish to make changes, please contact us.',
  'application.details.documents.title': 'Required documentation',
  'application.details.tenant.coTenant': 'Co-tenant',
  'application.details.tenant.email': 'E-mail',
  'application.details.tenant.employmentStatus': 'Employment status',
  'application.details.tenant.mainTenant': 'Primary tenant',
  'application.details.tenant.name': 'Name',
  'application.details.tenant.phone': 'Phone number',
  'application.details.tenant.surname': 'Surname',
  'application.details.title': 'Documentation Sent',
  'application.documentation.back': 'Request property',
  'application.documentation.description':
    'To continue with the process, we need you to upload the documentation of all the tenants who will be living in the property. Once you have sent all the documents, we will review them to validate their solvency. We will contact you by phone to let you know if everything is correct.',
  'application.documentation.documents': 'Documents',
  'application.documentation.pending': 'Pending',
  'application.documentation.title': 'Required Tenant Documentation',
  'application.documentationCompleted.bold':
    "You have successfully submitted your application for one of Vivara's properties.",
  'application.documentationCompleted.finish': 'Take me to my application page',
  'application.documentationCompleted.main': 'We will now review the information and inform you with the next steps.',
  'application.requiredDocumentation': 'Required Documentation',
  'application.saveAndExit': 'Save and exit',
  'application.send': 'Send',
  'applications.accountSuccess.description':
    'You have applied for one of our properties through Idealista. To complete the process, upload the necessary documentation to verify the financial solvency and tenant debt status of all tenants.',
  'applications.accountSuccess.question': 'Would you like to continue with this process now?',
  'applications.accountSuccess.title': 'Your Vivara account has been successfully created.',
  'applications.accountSuccessEmpty.bold': 'You will soon receive a confirmation email with more details.',
  'applications.accountSuccessEmpty.main': 'Your Vivara account has been successfully created.',
  'applications.applied': 'Applied',
  'applications.completeDocumentation': 'Complete documentation',
  'applications.completeLater': 'Complete later',
  'applications.empty.description':
    'You can explore the available properties in the “Vivara Properties” section to apply for a visit.',
  'applications.empty.title': 'You currently have no active applications for Vivara properties.',
  'applications.myApplications': 'My Applications',
  'applications.pendingToSend': 'Pending to send',
  'applications.request': 'Continue application',
  'auth.and': "and Vivara Company's",
  'auth.logOut': 'Log out',
  'auth.privacyPolicy': 'Privacy Policy',
  'auth.signIn': 'Sign in to your account',
  'auth.signInWithFacebook': 'Continue with Facebook',
  'auth.signInWithGoogle': 'Continue with Google',
  'auth.signUp': 'Sign up',
  'auth.signUpLink': 'Create an account',
  'auth.signUpPrompt': 'Don’t have an account?',
  'auth.termsAndConditions': 'Terms and Conditions of use of the Platform',
  'auth.welcome': 'Welcome to Vivara',
  'authenticationError.invalidCredentials': 'The email or password is incorrect',
  'authenticationError.userAlreadyExists': 'The email is already in use',
  'common.accept': 'Accept',
  'common.actions': 'Actions',
  'common.address': 'Address',
  'common.and': 'and',
  'common.cancel': 'Cancel',
  'common.catastralReference': 'Catastral reference',
  'common.congratulations': 'Congratulations!',
  'common.continue': 'Continue',
  'common.date': 'Date',
  'common.delete': 'Delete',
  'common.floor': 'Floor',
  'common.month': 'month',
  'common.or': 'or',
  'common.property.availability.exact-date': 'I know the exact date',
  'common.property.availability.less-than-one-month': 'Less than 1 month',
  'common.property.availability.more-than-three-months': 'More than 3 months',
  'common.property.availability.one-to-three-months': 'One to 3 months',
  'common.property.bathrooms': 'Bathrooms',
  'common.property.bedrooms': 'Bedrooms',
  'common.property.indoorExtras.air-conditioning': 'Air conditioning',
  'common.property.indoorExtras.alarm': 'Alarm',
  'common.property.indoorExtras.dishwasher': 'Dishwasher',
  'common.property.indoorExtras.dryer': 'Dryer',
  'common.property.indoorExtras.fridge': 'Fridge',
  'common.property.indoorExtras.microwave': 'Microwave',
  'common.property.indoorExtras.oven': 'Oven',
  'common.property.indoorExtras.stove': 'Stove',
  'common.property.indoorExtras.washing-machine': 'Washing machine',
  'common.property.newProperty': 'New property',
  'common.property.outdoorExtras.balcony': 'Balcony',
  'common.property.outdoorExtras.communityGarage': 'Community garage',
  'common.property.outdoorExtras.lift': 'Lift',
  'common.property.outdoorExtras.privateGarage': 'Private garage',
  'common.property.outdoorExtras.storageRoom': 'Storage room',
  'common.property.outdoorExtras.terrace': 'Terrace',
  'common.property.state.good': 'Good',
  'common.property.state.needs-refurbish': 'For refurbishment',
  'common.property.state.new': 'New',
  'common.property.state.very-good': 'Very good',
  'common.property.status.in-progress': 'In progress',
  'common.property.status.in-visits': 'In visits',
  'common.property.status.listed': 'Listed',
  'common.property.status.registered': 'Registered',
  'common.property.status.rented': 'Rented',
  'common.property.status.reserved': 'Reserved',
  'common.property.type.attachedHouse': 'Attached House',
  'common.property.type.detachedHouse': 'Detached House',
  'common.property.type.duplex': 'Duplex',
  'common.property.type.flat': 'Flat',
  'common.property.type.penthouse': 'Penthouse',
  'common.rooms': 'Room',
  'common.roomsAbbreviation': 'rooms',
  'common.showLess': 'Show less',
  'common.showMore': 'Show more',
  'common.signIn': 'Sign in',
  'footer.copyright': 'Copyright © {0}. All rights reserved',
  'footer.registry': "Registre d'agents immobiliaris de Catalunya aicat 12678",
  'forms.accountHolder': 'Account holder',
  'forms.company': 'Company',
  'forms.companyPlaceholder': 'Name of your real estate agency',
  'forms.email': 'Email',
  'forms.emailPlaceholder': "example{'@'}mail.com",
  'forms.fileInputBrowse': 'browse',
  'forms.fileInputFile': 'Drag and drop files or',
  'forms.fileInputImage': 'Drag and drop images or',
  'forms.fileInputInvalidFileCount': 'Maximum number of files reached',
  'forms.fileInputInvalidFormat': 'Some files have an invalid format',
  'forms.fileInputInvalidSize': 'Some files exceed the maximum size',
  'forms.fileInputMax': 'files max',
  'forms.fileInputMaxSize': 'Maximum size {0}MB',
  'forms.fullName': 'Name and surname',
  'forms.fullNamePlaceholder': 'Your first and last name',
  'forms.name': 'Name',
  'forms.namePlaceholder': 'Your first name',
  'forms.password': 'Password',
  'forms.passwordPlaceholder': 'Password',
  'forms.phone': 'Phone number',
  'forms.phonePlaceholder': 'Your phone number',
  'forms.surname': 'Surname',
  'forms.surnamePlaceholder': 'Your last name',
  'genericError.unexpected': 'Something went wrong',
  'landlord.documents.description.id': '(identity document)',
  'landlord.documents.type.id': 'NIF/NIE',
  'landlord.documents.uploadInstructions': 'Supported formats: pdf, png, jpg.',
  'landlordBilling.invoice.subtitle': 'Liquidations and invoices generated for your properties.',
  'landlordBilling.invoice.title': 'Billing History',
  'landlordBilling.table.columns.amount': 'Amount',
  'landlordBilling.table.columns.date': 'Date',
  'landlordBilling.table.columns.document': 'Document',
  'landlordBilling.table.columns.property': 'Property',
  'landlordBilling.table.columns.type': 'Type',
  'landlordBilling.table.documentNotAvailable': 'Not available',
  'landlordBilling.table.emptyState.description':
    'You have not generated any invoices or liquidations for your properties yet.',
  'landlordBilling.table.emptyState.title': 'No billing information available',
  'landlordBilling.title': 'Billing',
  'landlordBilling.tooltip.invoice': 'Invoice: details only the services billed by Vivara.',
  'landlordBilling.tooltip.liquidation': 'Liquidation: includes income and all property expenses.',
  'landlordBilling.tooltip.title': 'Types of documents',
  'landlordBilling.type.invoice': 'Invoice',
  'landlordBilling.type.liquidation': 'Liquidation',
  'listings.applied': 'Property applied',
  'listings.idealistaLink': 'View on Idealista',
  'listings.instructions.step1.description': `To apply for a visit, select the property you are interested in and complete the application by uploading the required documentation to validate your financial solvency and tenant’s debt status.

Once we have verified all the documentation, we will get in touch with you to coordinate the visit.`,
  'listings.instructions.step1.title': 'How to apply for a property visit?',
  'listings.instructions.step2.description': `You can view the basic information of the property on the Vivara platform.

If you want the full details, go to the “Vivara Properties” section and click on “View on Idealista” for the property you are interested in. There, you will have access to all the available information.`,
  'listings.instructions.step2.title': 'Where can I find the property details?',
  'listings.request': 'Apply',
  'listings.title': 'Vivara Properties',
  'partner.commercialization': 'Commercialization',
  'partner.creationDate': 'Creation date',
  'partner.empty.description':
    'Grow your property portfolio by offering your clients our integrated management and start generating passive income with Vivara.',
  'partner.empty.title': 'You currently do not have any properties managed with Vivara.',
  'partner.landlord': 'Landlord',
  'partner.management': 'Management',
  'partner.monthlyPaymentNotDefined': 'Monthly payment',
  'partner.planNotSelected': 'Plan and/or Vivara Service',
  'partner.properties': 'property | properties',
  'partner.solvency': 'Solvency and morosity',
  'partner.sortedBy': 'Sorted by:',
  'partner.tenant': 'Tenant',
  'partner.tenantsEmpty': 'There are no tenants assigned to this property yet.',
  'partner.title': 'Properties',
  'partnerCandidature.list.columns.createdAt': 'Registered',
  'partnerCandidature.list.columns.property': 'Property',
  'partnerCandidature.list.columns.status': 'Status',
  'partnerCandidature.list.description': 'Managed properties and their status in the commercialization process.',
  'partnerCandidature.list.empty.description':
    'Here you will see the properties you have commercialized and their status in the process.',
  'partnerCandidature.list.empty.title': 'No properties being commercialized yet',
  'partnerCandidature.list.title': 'Properties being commercialized',
  'partnerCandidature.candidatures': 'property | properties',
  'partnerCandidature.sentDate': 'Creation date',
  'partnerCandidature.sortedBy': 'Sorted by:',
  'partnerCandidature.title': 'Candidatures',
  'partnerBilling.invoice.title': 'Billing History',
  'partnerBilling.invoice.subtitle':
    'Summary of income generated from your collaboration with Vivara. The displayed amounts do not include VAT.',
  'partnerBilling.table.columns.amount': 'Amount',
  'partnerBilling.table.columns.date': 'Date',
  'partnerBilling.table.columns.property': 'Property',
  'partnerBilling.table.columns.concept': 'Concept',
  'partnerBilling.table.columns.collaborationType': 'Collaboration type',
  'partnerBilling.table.documentNotAvailable': 'Not available',
  'partnerBilling.table.emptyState.description':
    'Here, you will see the earnings generated from your collaboration with Vivara. Once available, you will be able to view the details of each concept and its origin.',
  'partnerBilling.table.emptyState.title': 'No earnings yet',
  'partnerBilling.title': 'Billing',
  'partnerProperty.information.monthlyAndRent': 'Monthly rent',
  'partnerProperty.information.monthlyAndRentNotDefined': 'The landlord has not defined the monthly rent.',
  'partnerProperty.information.planOrService': 'Plan or Service',
  'partnerProperty.information.planOrServiceNotSelected': 'The landlord has not selected a plan.',
  'partnerProperty.information.rent': 'Rent',
  'partnerProperty.tabs.communication': 'Communication',
  'partnerProperty.tabs.contract': 'Contract and services',
  'partnerProperty.tabs.documentation': 'Documentation',
  'partnerProperty.tabs.information': 'Property',
  'partnerProperty.tabs.timeline': 'Property status',
  'partnerProperty.title': 'Detalle de la propiedad',
  'partnerReferral.callToAction.action': 'Send lead',
  'partnerReferral.callToAction.description':
    'Do you have a client interested in renting their property? Send us their details and we will offer them our services.',
  'partnerReferral.callToAction.title': 'Increase your earnings',
  'partnerReferral.list.columns.createdAt': 'Sent',
  'partnerReferral.list.columns.email': 'Email',
  'partnerReferral.list.columns.landlord': 'Landlord',
  'partnerReferral.list.columns.phone': 'Phone',
  'partnerReferral.list.columns.status': 'Status',
  'partnerReferral.list.description': 'Landlords sent to Vivara and their recruitment status.',
  'partnerReferral.list.empty.description':
    'Here you will see the landlords you send to Vivara and their status in the recruitment process. Send a lead to get started.',
  'partnerReferral.list.empty.title': 'You have not sent any leads yet',
  'partnerReferral.list.title': 'Lead status',
  'partnerReferral.referrals': 'lead | leads',
  'partnerReferral.send.action': 'Send lead',
  'partnerReferral.send.confirmation':
    'I confirm that I have the necessary permissions from the owner to share their information with Vivara and that it will be used exclusively to offer rental management services. *',
  'partnerReferral.send.description':
    "Provide your client's details and we will offer them our rental management services.",
  'partnerReferral.send.namePlaceholder': "Landlord's name",
  'partnerReferral.send.phonePlaceholder': '737689023',
  'partnerReferral.send.success.close': 'Understood',
  'partnerReferral.send.success.description':
    "We have received the landlord's information and our team will review it soon. You can track its status in the Leads section.",
  'partnerReferral.send.success.title': 'Lead sent successfully!',
  'partnerReferral.send.surnamePlaceholder': 'Surname',
  'partnerReferral.send.title': 'Send lead',
  'partnerReferral.sentDate': 'Sent date',
  'partnerReferral.sortedBy': 'Sorted by:',
  'partnerReferral.status.activated': 'Asigned',
  'partnerReferral.status.contacted': 'Contacted',
  'partnerReferral.status.discarded': 'Discarded',
  'partnerReferral.status.sent': 'Sent',
  'partnerReferral.title': 'Leads',
  'profile.help.contact': 'Contact us',
  'profile.help.description':
    "If you need assistance, we are just a click away. Contact us and we'll be happy to help you with whatever you need.",
  'profile.help.title': 'Help',
  'profile.password.changePassword': 'Change password',
  'profile.password.changePasswordConfirmation': 'Your password has been changed successfully.',
  'profile.password.confirmNewPassword': 'Confirm new password',
  'profile.password.confirmNewPasswordPlaceholder': 'Re-enter the password',
  'profile.password.currentPassword': 'Current password',
  'profile.password.currentPasswordPlaceholder': 'Enter your current password',
  'profile.password.description': 'Your new password must be different from previously used passwords.',
  'profile.password.invalidCurrentPassword':
    'The current password is incorrect. If you have forgotten your password, you can reset it logging out and clicking on "Have you forgotten your password?"',
  'profile.password.newPassword': 'New password',
  'profile.password.newPasswordPlaceholder': 'Add a new password',
  'profile.password.title': 'Change password',
  'profile.settings.deleteAccount': 'Delete your account',
  'profile.settings.deleteAccountConfirmation.bold': 'Deleting your account is irreversible.',
  'profile.settings.deleteAccountConfirmation.cancel': 'Cancel and return',
  'profile.settings.deleteAccountConfirmation.confirm': 'Delete account',
  'profile.settings.deleteAccountConfirmation.description':
    'This will delete all your data, and you will not be able to recover your account and associated information. If you are sure you want to continue, confirm below.',
  'profile.settings.email': 'Contact email',
  'profile.settings.phone': 'Contact phone number',
  'profile.settings.save': 'Save changes',
  'profile.settings.title': 'View my profile',
  'properties.accountSuccess.bold': 'Would you like to keep addding your property characteristics?',
  'properties.accountSuccess.main': 'Your account has been created successfully.',
  'properties.addProperty': 'Add a property',
  'properties.characteristicsSuccess.bold': 'Would you like to keep adding your property required documents?',
  'properties.characteristicsSuccess.main': 'Your property characteristics have been added successfully.',
  'properties.completeLater': 'Complete later',
  'properties.continueRegistration': 'Continue registration',
  'properties.creationDate': 'Creation date',
  'properties.infoBox':
    '<p>It would be good to prepare following documents for property onboarding:</p><ul><li>ID documents</li><li>Photographs of your home</li></ul><p>You can always save and continue later with your property onboarding.</p>',
  'properties.myProfile': 'My profile',
  'properties.onboardingCompleted.bold':
    'You have successfully completed the registration of your property and selected a Plan for your property.',
  'properties.onboardingCompleted.finish': 'Take me to my property page',
  'properties.onboardingCompleted.main':
    'We will now review the information and inform you when it is published and the next steps to follow.',
  'properties.properties': 'property | properties',
  'properties.sortedBy': 'Sorted by:',
  'properties.title': 'My Properties',
  'property.basic.pets': 'Are pets allowed in your property?',
  'property.basic.petsAllowed': 'Yes, the property accepts pets',
  'property.basic.petsNotAllowed': 'No, the property does not accept pets',
  'property.basic.roomInformation.bathroom': 'Bathroom specifications',
  'property.basic.roomInformation.bathroomDescription':
    'Toilets should be counted as a separate bathroom if they are located outside the main bathroom.',
  'property.basic.roomInformation.bedroom': 'Bedroom specifications',
  'property.basic.roomInformation.bedroomDescription':
    'According to the law, a room must be at least 5m2 to be considered a bedroom.',
  'property.basic.rooms': 'What is the number of rooms in your property',
  'property.basic.state': 'Select the option that best describes your property state',
  'property.basic.step': 'General information',
  'property.basic.title.bold': 'basic',
  'property.basic.title.main': 'Please, tell us about the {0} characteristics.',
  'property.basic.type': 'Choose the option that best describes the type of property',
  'property.characteristics.step': 'Property characteristics',
  'property.core.addReference': 'Add reference',
  'property.core.addressPlaceholder': 'Add the property address',
  'property.core.availabilityDate': 'Availability date',
  'property.core.availabilityTitle.bold': 'property will be available for rent.',
  'property.core.availabilityTitle.main': 'Please, let us know when your {0}',
  'property.core.availabilityType': 'Availability of the property',
  'property.core.availabilityTypePlaceholder': 'Select the availability',
  'property.core.catastralReferenceError': 'We couldn’t find the property with this catastral reference',
  'property.core.catastralReferenceInformation':
    'The catastral reference number is 20-figure code that you can find in Sede Electronica del Catastro',
  'property.core.catastralReferencePlaceholder': '1111111XXXXXXX1111XX',
  'property.core.firstRent': 'Property available for rent for the first time',
  'property.core.floorPlaceholder': 'Add the property floor',
  'property.core.manualAddressToggle': 'I don’t have Cadastral reference, I will enter details manually',
  'property.core.monthlyPayment': 'Maximum monthly rent',
  'property.core.rentTitle.bold': 'your monthly rent?',
  'property.core.rentTitle.main': 'How much would you like to charge for {0}',
  'property.core.step': 'Property details',
  'property.core.surface': 'Property surface',
  'property.core.surfacePlaceholder': 'Add the property surface',
  'property.core.title.bold': 'property information.',
  'property.core.title.main': 'Add your {0}',
  'property.core.usableSurface': 'Usable surface',
  'property.core.usableSurfacePlaceholder': 'Add the property usable surface',
  'property.core.usableSurfaceToggle': 'This is usable surface',
  'property.details.documents.description': 'List of documents related to the property.',
  'property.details.documents.document': 'Document',
  'property.details.documents.empty.description':
    'We do not yet have the documentation associated with this property. It will be displayed here as soon as it becomes available.',
  'property.details.documents.empty.title': 'Information not available',
  'property.details.documents.title': 'Documents',
  'property.details.rent': 'Rent',
  'property.details.solvency': 'Solvency and morosity',
  'property.details.tabs.communication': 'Communication',
  'property.details.tabs.contract': 'Contract and services',
  'property.details.tabs.documentation': 'Documentation',
  'property.details.tabs.information': 'Property',
  'property.details.tabs.timeline': 'Property status',
  'property.details.tenant': 'Tenant',
  'property.details.tenantsEmpty': 'There are no tenants assigned to this property yet.',
  'property.details.timeline.status.in-visits.description':
    'We are coordinating and conducting viewings with potential tenants interested in the property.',
  'property.details.timeline.status.in-visits.title': 'In visits',
  'property.details.timeline.status.listed.description':
    'The property is already visible on rental portals and in our network to attract potential tenants.',
  'property.details.timeline.status.listed.title': 'Published',
  'property.details.timeline.status.registered.description':
    'The property has been registered on our platform and we are preparing the necessary information to start the rental management.',
  'property.details.timeline.status.registered.title': 'Registered',
  'property.details.timeline.status.rented.description':
    'The property already has tenant/s and the rental contract has been successfully formalised.',
  'property.details.timeline.status.rented.title': 'Rented',
  'property.details.timeline.status.reserved.description':
    'The property has been reserved by a tenant and we are in the process of formalising the contract.',
  'property.details.timeline.status.reserved.title': 'Reserved',
  'property.details.timeline.title': 'Property status',
  'property.details.title': 'Property detail',
  'property.documents.description.energyCertificate': '',
  'property.documents.description.habitabilityCertificate': '',
  'property.documents.description.propertyExpenses':
    '(property tax, homeowners association fees, waste disposal fee, parking permit, etc.)',
  'property.documents.description.utilityBill': '(water, electricity, and gas)',
  'property.documents.step': 'Required documentation',
  'property.documents.title.bold': 'required documents.',
  'property.documents.title.main': 'Please, add the {0}',
  'property.documents.type.energyCertificate': 'Energy certificate',
  'property.documents.type.habitabilityCertificate': 'Habitability certificate',
  'property.documents.type.propertyExpenses': 'Property expenses',
  'property.documents.type.utilityBill': 'Utility bill',
  'property.documents.uploadInstructions': 'Supported formats: pdf, png, jpg.',
  'property.equipment.furnished': 'Furnished',
  'property.equipment.furnishedSubtitle': 'The property will be rented with furniture',
  'property.equipment.furnitureTitle.bold': 'furnishing of your property.',
  'property.equipment.furnitureTitle.main': 'Please, tell us about the',
  'property.equipment.indoorTitle.bold': 'indoor extras.',
  'property.equipment.indoorTitle.main': 'Add details about your {0}',
  'property.equipment.indoorToggle': 'I’m renting my property without extras of interior.',
  'property.equipment.notFurnished': 'Not furnished',
  'property.equipment.notFurnishedSubtitle': 'The property will be rented without furniture',
  'property.equipment.outdoorTitle.bold': 'outdoor extras.',
  'property.equipment.outdoorTitle.main': 'Add details about your {0}',
  'property.equipment.outdoorToggle': 'I’m renting my property without extras of exterior.',
  'property.equipment.step': 'Property equipment and extras',
  'property.images.professionalPhotoService': 'I’m interested in Vivara’s service of professional photography',
  'property.images.step': 'Image gallery',
  'property.images.title.bold': 'photos.',
  'property.images.title.main': 'Show us your entire property in current state by adding {0}',
  'property.images.uploadInstructions': 'Supported formats: png, jpg, jpeg.',
  'property.onboarding.continue': 'Continue',
  'property.onboarding.finish': 'Finish',
  'property.onboarding.saveAndExit': 'Save and exit',
  'property.onboarding.title': 'Register your property',
  'property.payment.basic.description': 'Rent your property with a simple, secure and worry-free process.',
  'property.payment.basic.name': 'Basic Plan',
  'property.payment.basic.price': '0,00€',
  'property.payment.comfort.description': 'We take care of everything: incidents, repairs, emergencies, etc.',
  'property.payment.comfort.name': 'Comfort Plan',
  'property.payment.comfort.price': '{percentage}% of the monthly rent',
  'property.payment.commercialization.description': 'We market your property and find your perfect tenant',
  'property.payment.commercialization.name': 'Perfect Tenant',
  'property.payment.commercialization.price': '1 month rent',
  'property.payment.commercialization.shortPrice': '1 month',
  'property.payment.features.bailManagement': 'Management of additional collateral and guarantees',
  'property.payment.features.digitalSignature': 'Assistance in contract formalization and electronic signature',
  'property.payment.features.documentManagement': 'Handling of required documentation (label, certificate, etc.)',
  'property.payment.features.guarantorRelease': 'Release of guarantors',
  'property.payment.features.incidentManagement': 'Incident and repair management',
  'property.payment.features.itpManagement': 'Attendance at the ITP presentation',
  'property.payment.features.monthlyPayments': 'Automatic monthly payments',
  'property.payment.features.nonPaymentInsurance': 'Assistance in taking out insurance against non-payment',
  'property.payment.features.professionalPhotos': 'Production of professional photographs',
  'property.payment.features.solvencyVerification':
    'Analysis of financial solvency and consultation in delinquency files.',
  'property.payment.features.tenantReplacement':
    'Tenant replacement at no additional charge within the first 12 months',
  'property.payment.features.tenantReview': 'Gathering information and reviewing profiles of potential tenants',
  'property.payment.features.utilityTransfers': 'Change of supplies',
  'property.payment.features.visitsManagement': 'Visits to the property',
  'property.payment.features.idealistaPublish': 'Publication of advertisements on platforms',
  'property.payment.free': 'Free',
  'property.payment.lessDetails': 'Less information',
  'property.payment.moreDetails': 'More information',
  'property.payment.nonPaymentCoverage.12months': '12 months',
  'property.payment.nonPaymentCoverage.18months': '18 months',
  'property.payment.nonPaymentCoverage.24months': '24 months',
  'property.payment.nonPaymentCoverage.description': 'non-payment coverage',
  'property.payment.peaceOfMind.description':
    'The plan that has it all. Non-payment insurance and integral management.',
  'property.payment.peaceOfMind.name': 'Peace of Mind Plan',
  'property.payment.peaceOfMind.price': '{percentage}% of monthly rent',
  'property.payment.plans': 'Our plans',
  'property.payment.plusIva': '(plus IVA)',
  'property.payment.popular': 'The most popular',
  'property.payment.secured.description':
    'More than a guarantee; an insurance. Get paid on the 7th of every month, no matter what.',
  'property.payment.secured.name': 'Secured Payment Plan',
  'property.payment.secured.price': '{percentage}% of the monthly rent',
  'property.payment.select': 'Select',
  'property.payment.services': 'Services',
  'property.payment.step': 'Choose your plan',
  'property.payment.summary.empty':
    'Select at least one plan or service to continue with the registration of your property.',
  'property.payment.summary.free': 'Free',
  'property.payment.summary.title': 'Summary',
  'property.payment.summary.total': 'Total',
  'property.payment.termsAndConditions.error': 'You must accept the Terms and conditions before finalizing the process',
  'property.payment.termsAndConditions.link': 'Terms and Conditions of Vivara Services.',
  'property.payment.termsAndConditions.readAndAccept': 'I have read and accept the',
  'property.payment.title.description':
    'Choose a plan, an additional service or just what you need. You decide how to manage your property!',
  'property.payment.title.main': 'Customize the management of your property',
  'property.payment.unselect': 'Remove',
  'propertyContract.collectionDate': 'Collection date',
  'propertyContract.contractualInformation': 'Contractual information',
  'propertyContract.depositBond': 'Deposit bond',
  'propertyContract.effectiveDate': 'Effective date',
  'propertyContract.empty.description':
    'We do not yet have the contractual information and services associated with this property. It will be displayed here as soon as it becomes available.',
  'propertyContract.empty.title': 'Information not available',
  'propertyContract.expirationDate': 'Contract expiry',
  'propertyContract.nextRentIncrease': 'Next rent increase',
  'propertyContract.nonPaymentCoverage': 'Non-payment insurance',
  'propertyContract.passedOnExpenses': 'Passed on expenses',
  'propertyContract.services': 'Services and coverages',
  'propertyContract.supplyChange': 'Change of supplies',
  'signin.recoverPassword.description': 'We will send you an email with a link so you can reset your password.',
  'signin.recoverPassword.link': 'Have you forgotten your password?',
  'signin.recoverPassword.send': 'Reset password',
  'signin.recoverPassword.success.description': `We have successfully received your request to reset your password. 
          
          You will shortly receive an email to complete the process. 
          
          Have you not received your email? Check your spam folder to make sure the email is not there.`,
  'signin.recoverPassword.success.title': 'Request made successfully',
  'signin.recoverPassword.title': 'Reset password',
  'signin.resetPassword.description': 'Your new password must be different from previously used passwords.',
  'signin.resetPassword.password': 'New password',
  'signin.resetPassword.passwordConfirmation': 'Confirm the new password',
  'signin.resetPassword.passwordConfirmationPlaceholder': 'Retype the password',
  'signin.resetPassword.passwordPlaceholder': 'Add a new password',
  'signin.resetPassword.save': 'Save the new password',
  'signin.resetPassword.title': 'Change password',
  'signup.account.commercial':
    'I want to receive commercial communications and offers from Vivara or third parties tailored to my interests.',
  'signup.account.landlord.signUpWithEmail': 'Sign up with email',
  'signup.account.landlord.subtitle': '',
  'signup.account.landlord.title.bold': 'account information.',
  'signup.account.landlord.title.main': 'Add your {0}',
  'signup.account.partner.signUpWithEmail': 'Create Your Account',
  'signup.account.partner.subtitle':
    'Make sure to use your business email address, as this is how we will initiate communication with you on Vivara.',
  'signup.account.partner.title.bold': '',
  'signup.account.partner.title.main': 'Create Your Account',
  'signup.account.signInPrompt': 'Already have an account?',
  'signup.account.step': 'Your account information',
  'signup.account.tenant.signUpWithEmail': 'Sign up with email',
  'signup.account.tenant.subtitle': '',
  'signup.account.tenant.title.bold': 'account information.',
  'signup.account.tenant.title.main': 'Add your {0}',
  'signup.account.terms': 'I agree to',
  'signup.account.termsEnd': '.',
  'signup.onboarding.title': 'Create account',
  'signup.personal.landlord.save': 'Continue',
  'signup.personal.landlord.title.bold': 'personal information.',
  'signup.personal.landlord.title.main': 'Add your {0}',
  'signup.personal.partner.save': 'Finish registration',
  'signup.personal.partner.title.bold': '',
  'signup.personal.partner.title.main': 'Complete your personal information',
  'signup.personal.step': 'Your personal information',
  'signup.personal.tenant.save': 'Continue',
  'signup.personal.tenant.title.bold': 'personal information.',
  'signup.personal.tenant.title.main': 'Add your {0}',
  'signup.property.step': 'Property information',
  'signup.type.aLandlord': 'A landlord',
  'signup.type.aLandlordSub': 'I am looking for tenant(s) for my property',
  'signup.type.aTenant': 'A tenant',
  'signup.type.aTenantSub': 'I am looking for a property to rent',
  'signup.type.step': 'User type',
  'signup.type.title.bold': 'landlord or a tenant?',
  'signup.type.title.main': 'Are you a {0}',
  'signup.welcome': 'Welcome to Vivara.',
  'tenant.add': 'Add another tenant',
  'tenant.coTenant': 'Co-tenant',
  'tenant.delete.bold': "Are you sure you want to delete this tenant's information?",
  'tenant.delete.cancel': 'Cancel and return',
  'tenant.delete.confirm': 'Delete tenant',
  'tenant.delete.description':
    'All information and documents uploaded for this tenant will be permanently deleted. If you are sure, click on “Delete tenant”. Otherwise, you can cancel this action and keep the data.',
  'tenant.delete.title': 'Delete tenant',
  'tenant.documentation': 'Documentation',
  'tenant.documents.employmentContract': 'Employment contract',
  'tenant.documents.id': 'DNI/NIE (both sides)',
  'tenant.documents.lastForm100': 'Last model 100',
  'tenant.documents.lastForm130': 'Last model 130',
  'tenant.documents.payslip': 'Last two payslips',
  'tenant.documents.pensionRevaluationCertificate': 'Pension revaluation certificate',
  'tenant.documents.uploadInstructions': 'Supported formats: pdf, png, jpg.',
  'tenant.employmentStatus': 'Employment Status',
  'tenant.employmentStatusDescription': 'Please, select your current employment status to view the required documents.',
  'tenant.employmentStatusNotSelected': 'Select your employment status to see the documents you need to upload.',
  'tenant.employmentStatuses.employed': 'Employed',
  'tenant.employmentStatuses.retired': 'Retired',
  'tenant.employmentStatuses.self-employed': 'Self-employed',
  'tenant.existingTenant.cancel': 'Change email',
  'tenant.existingTenant.confirm': 'Use existing data',
  'tenant.existingTenant.description':
    'The tenant you are trying to add already has a Vivara account with the email address {email}. We can use their existing account data to automatically fill in some of the information for the application.',
  'tenant.existingTenant.question':
    'If you prefer not to use existing data, you can enter a different email address to add this tenant.',
  'tenant.existingTenant.title': 'Tenant already registered',
  'tenant.exitWithoutSavingConfirmation': 'You have pending changes. Are you sure you want to exit?',
  'tenant.mainTenant': 'Main tenant',
  'tenant.partialSave.confirm': 'Exit without documentation',
  'tenant.partialSave.description':
    'If you decide to leave now, the documentation you have uploaded for all co-tenants will not be saved, but we will keep the relevant information for each one. When you return, you will need to re-upload the required documentation for each co-tenant.',
  'tenant.partialSave.question': 'Are you sure you want to exit the process and re-upload the co-tenant documentation?',
  'tenant.partialSave.title': 'Partial saving of information',
  'tenant.requiredDocumentation.employed': 'Required documentation for employees',
  'tenant.requiredDocumentation.retired': 'Required documentation for retirees',
  'tenant.requiredDocumentation.self-employed': 'Required documentation for self-employed',
  'validationError.formatEmail': 'Please, enter a valid email address',
  'validationError.formatPassword': 'Password must be between 6 and 20 characters in length',
  'validationError.formatPhone': 'Please, enter a valid phone number',
  'validationError.invalidDate': 'Please, enter a valid date',
  'validationError.passwordsDoNotMatch': 'Passwords do not match',
  'validationError.required': 'This information is required',
  'validationError.requiredEmail': 'Email is required',
  'validationError.requiredFullName': 'Name and surname are required',
  'validationError.requiredName': 'Name is required',
  'validationError.requiredPassword': 'Password is required',
  'validationError.requiredPhone': 'Phone number is required',
  'validationError.requiredSurname': 'Surname is required',
  'validationError.requiredTermsAndConditions': 'You must accept the Terms and conditions'
}
