export enum AnalyticsEvent {
  LandlordBillingInvoicesList,
  LandlordBillingInvoicesListDownloadInvoice,
  LandlordBillingInvoicesListDownloadLiquidation,
  LandlordDocumentationUploadBills,
  LandlordDocumentationUploadEnergyCertificate,
  LandlordDocumentationUploadHabitabilityCertificate,
  LandlordDocumentationUploadIBI,
  LandlordDocumentationUploadId,
  LandlordDocumentationUploadPropertyExpenses,
  LandlordOnboardingCompleted,
  LandlordOnboardingPropertiesList,
  LandlordProfile,
  LandlordProfileConfirmDeleteAccount,
  LandlordPropertiesAddNewProperty,
  LandlordPropertiesContinueRegistration,
  LandlordPropertiesPropertiesList,
  LandlordPropertyDetailContractualInformation,
  LandlordPropertyDetailDocuments,
  LandlordPropertyDetailDownloadDocument,
  LandlordPropertyDetailStatusTimeline,
  LandlordPropertyInformationCompleted,
  LandlordPropertyInformationContinue,
  LandlordPropertyInformationContinueLater,
  LandlordPropertyRegisterDeleteCatastralReference,
  LandlordPropertyRegisterEnterAddress,
  LandlordPropertyRegisterEnterAvailability,
  LandlordPropertyRegisterEnterBathrooms,
  LandlordPropertyRegisterEnterBedrooms,
  LandlordPropertyRegisterEnterCatastralReference,
  LandlordPropertyRegisterEnterMonthlyRent,
  LandlordPropertyRegisterErrorDocumentationFormat,
  LandlordPropertyRegisterErrorImageFormat,
  LandlordPropertyRegisterHoverInformation,
  LandlordPropertyRegisterSelectFurniture,
  LandlordPropertyRegisterSelectIndoorExtras,
  LandlordPropertyRegisterSelectOutdoorExtras,
  LandlordPropertyRegisterSelectPets,
  LandlordPropertyRegisterSelectPlan,
  LandlordPropertyRegisterSelectProfessionalImages,
  LandlordPropertyRegisterSelectPropertyState,
  LandlordPropertyRegisterSelectPropertyType,
  LandlordPropertyRegisterStartEquipmentExtras,
  LandlordPropertyRegisterStartGeneralData,
  LandlordPropertyRegisterStartImages,
  LandlordPropertyRegisterStartPlan,
  LandlordPropertyRegisterStartPropertyData,
  LandlordPropertyRegisterSubmitEquipmentExtras,
  LandlordPropertyRegisterSubmitGeneralData,
  LandlordPropertyRegisterSubmitImages,
  LandlordPropertyRegisterSubmitPlan,
  LandlordPropertyRegisterSubmitPropertyData,
  LandlordPropertyRegisterUploadImages,
  LandlordSignIn,
  LandlordSignupCompleted,
  LandlordSignupContinue,
  LandlordSignupContinueLater,
  LandlordSignupDeleteCatastralReference,
  LandlordSignupEnterAddress,
  LandlordSignupEnterAvailability,
  LandlordSignupEnterCatastralReference,
  LandlordSignupEnterMonthlyRent,
  LandlordSignupEnterName,
  LandlordSignupEnterPhone,
  LandlordSignupEnterSurname,
  LandlordSignupErrorAccountExists,
  LandlordSignupInitiateRegister,
  LandlordSignupRegisterWithEmail,
  LandlordSignupRegisterWithFacebook,
  LandlordSignupRegisterWithGoogle,
  LandlordSignupSelectCommercialCommunications,
  LandlordSignupSelectTermsAndConditions,
  LandlordSignupSelectUserType,
  LandlordSignupStartAccountInformation,
  LandlordSignupStartPersonalData,
  LandlordSignupStartPropertyData,
  LandlordSignupSubmitPersonalData,
  LandlordSignupSubmitPropertyData,
  LandlordStartDocumentation,
  LandlordSubmitDocumentation,
  PartnerBillingInvoicesList,
  PartnerLeadsCreateLeadBanner,
  PartnerLeadsCreateLeadButton,
  PartnerLeadsLeadCreated,
  PartnerLeadsLeadsList,
  PartnerPropertiesPropertiesList,
  PartnerPropertyCommercializationDetailConfirmDate,
  PartnerPropertyCommercializationDetailModifyDate,
  PartnerPropertyCommercializationDetailSelectDate,
  PartnerPropertyCommercializationDetailTasksCompleted,
  PartnerPropertyCommercializationDetailTasksList,
  PartnerPropertyCommercializationDetailVisitsList,
  PartnerPropertyCommercializationPropertiesList,
  PartnerPropertyDetailContractualInformation,
  PartnerPropertyDetailDocuments,
  PartnerPropertyDetailDownloadDocuments,
  PartnerPropertyDetailStatusTimeline,
  PartnerSignIn,
  PartnerSignupEnterName,
  PartnerSignupEnterPhone,
  PartnerSignupEnterSurname,
  PartnerSignupErrorAccountExists,
  PartnerSignupRegisterWithEmail,
  PartnerSignupSelectCommercialCommunications,
  PartnerSignupSelectTermsAndConditions,
  PartnerSignupStartAccountInformation,
  PartnerSignupStartPersonalData,
  PartnerSignupSubmitPersonalData,
  SignInStart,
  SignUpStart,
  TenantApplicationAddTenant,
  TenantApplicationApplicationsList,
  TenantApplicationCompleted,
  TenantApplicationCompletedApplicationsList,
  TenantApplicationDeleteTenant,
  TenantApplicationDocuments,
  TenantApplicationDontUseExistingCotenantInformation,
  TenantApplicationSaveDocuments,
  TenantApplicationSelectApplication,
  TenantApplicationSelectFotocasa,
  TenantApplicationSelectIdealista,
  TenantApplicationSendDocuments,
  TenantApplicationUseExistingCotenantInformation,
  TenantProfile,
  TenantProfileConfirmDeleteAccount,
  TenantPropertiesApply,
  TenantPropertiesPropertiesList,
  TenantPropertiesSelectIdealista,
  TenantSignIn,
  TenantSignupCompleted,
  TenantSignupContinue,
  TenantSignupContinueLater,
  TenantSignupEnterName,
  TenantSignupEnterPhone,
  TenantSignupEnterSurname,
  TenantSignupErrorAccountExists,
  TenantSignupInitiateRegister,
  TenantSignupRegisterWithEmail,
  TenantSignupRegisterWithFacebook,
  TenantSignupRegisterWithGoogle,
  TenantSignupSelectCommercialCommunications,
  TenantSignupSelectTermsAndConditions,
  TenantSignupSelectUserType,
  TenantSignupStartAccountInformation,
  TenantSignupStartPersonalData,
  TenantSignupSubmitPersonalData
}
