<template>
  <div class="flex column gap-1" v-if="model">
    <Title path="property.core.title" />
    <div data-test-id="catastralReferenceSelector">
      <CatastralReferenceField
        v-model="model.catastralReference"
        :disabled="manualAddressToggle"
        :trackEnterEvent="AnalyticsEvent.LandlordSignupEnterCatastralReference"
        :trackClearEvent="AnalyticsEvent.LandlordSignupDeleteCatastralReference"
        @address="onAddAddress"
      />
    </div>
    <Switch
      v-if="!model.catastralReference"
      :label="t('property.core.manualAddressToggle')"
      :modelValue="manualAddressToggle"
      @update:modelValue="toggleManualAddress"
    />
    <GeolocalizedInputField
      v-model="model.street"
      :disabled="!manualAddressToggle"
      :label="t('common.address')"
      :placeholder="t('property.core.addressPlaceholder')"
      :trackEnterEvent="AnalyticsEvent.LandlordSignupEnterAddress"
    />
    <div>
      <NumberField
        v-model="model.builtSize"
        :disabled="!manualAddressToggle"
        :label="t('property.core.surface')"
        :placeholder="t('property.core.surfacePlaceholder')"
      />
      <div class="flex align-center">
        <Checkbox v-model="usableSurfaceToggle" :disabled="!manualAddressToggle" />
        <p>{{ t('property.core.usableSurfaceToggle') }}</p>
      </div>
    </div>
    <NumberField
      v-if="!usableSurfaceToggle"
      v-model="model.usableSize"
      :disabled="!manualAddressToggle"
      :label="t('property.core.usableSurface')"
      :placeholder="t('property.core.usableSurfacePlaceholder')"
    />
    <InputField
      v-model="model.floor"
      :disabled="!manualAddressToggle"
      :label="t('common.floor')"
      :placeholder="t('property.core.floorPlaceholder')"
    />
    <Title class="mt-2" path="property.core.rentTitle" />
    <NumberField
      v-model="model.monthlyPayment"
      :label="t('property.core.monthlyPayment')"
      :placeholder="t('property.core.monthlyPayment')"
      :trackEnterEvent="AnalyticsEvent.LandlordSignupEnterMonthlyRent"
      data-test-id="rentInput"
    />
    <Switch v-model="firstRentToggle" :label="t('property.core.firstRent')" />
    <Title class="mt-2" path="property.core.availabilityTitle" />
    <SelectField
      v-model="model.availabilityType"
      :label="t('property.core.availabilityType')"
      :options="availabilityOptions"
      :placeholder="t('property.core.availabilityTypePlaceholder')"
      :trackEnterEvent="AnalyticsEvent.LandlordSignupEnterAvailability"
      data-test-id="availabilityTypeSelector"
    />
    <div ref="datePickerContainer">
      <DatePicker
        v-if="model.availabilityType === AvailabilityType.ExactDate"
        v-model="model.availabilityDate"
        :label="t('property.core.availabilityDate')"
      />
    </div>
  </div>
  <FormSkeleton v-else />
</template>
<script lang="ts" setup>
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import {
  CatastralReferenceField,
  Checkbox,
  DatePicker,
  FormSkeleton,
  GeolocalizedInputField,
  InputField,
  NumberField,
  SelectField,
  Switch,
  Title
} from '@/components'
import type { CatastralAddress } from '@/modules/property/domain/catastralAddress'
import { AvailabilityType, type PropertyCoreInformation } from '@/modules/property/domain/propertyCoreInformation'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const model = defineModel<PropertyCoreInformation>()

const { t } = useI18n()

const manualAddressToggle = ref(false)
const usableSurfaceToggle = ref(true)
const firstRentToggle = ref(false)
const datePickerContainer = ref<HTMLDivElement | null>(null)
const availabilityOptions = Object.values(AvailabilityType).map((value) => ({
  value,
  label: `common.property.availability.${value}`
}))

function toggleManualAddress(value: boolean) {
  manualAddressToggle.value = value
}

function onAddAddress(address: CatastralAddress) {
  if (!model.value) return

  model.value.street = address.street
  model.value.builtSize = address.builtSize ? parseInt(address.builtSize) : null
  model.value.floor = address.floor
}

watch(
  () => model.value?.availabilityType,
  (value) => {
    if (value === AvailabilityType.ExactDate && datePickerContainer.value) {
      datePickerContainer.value.scrollIntoView({ behavior: 'smooth' })
    }
  }
)
</script>
