import { apiEndpointsV2 as v2 } from '@/config/api/endpoints.v2'
import type { CreatePartnerReferralForm } from '@/modules/partnerReferral/domain/createPartnerReferralForm'
import type { PartnerReferralRepository } from '@/modules/partnerReferral/domain/partnerReferralRepository'
import { fromDto, type PartnerReferralDto } from '@/modules/partnerReferral/infrastructure/apiDto'
import type { ApiService } from '@/modules/shared/domain/api/apiService'
import type { Uuid } from '@/modules/shared/domain/uuid/uuid'

export function apiPartnerReferralRepositoryBuilder(apiV2Service: ApiService): PartnerReferralRepository {
  return {
    async create(partnerReferralId: Uuid, form: CreatePartnerReferralForm) {
      await apiV2Service.post(v2.partners().referrals().create(), { partnerReferralId, ...form })
    },
    async getAll() {
      const results = await apiV2Service.getList<PartnerReferralDto>(v2.partners().referrals().list())
      return results.map(fromDto)
    }
  }
}
