import type { Property } from '@/modules/property/domain/property'
import type { PropertyStatus } from '@/modules/property/domain/propertyStatus'
import type {
  PropertyBasicInformation,
  PropertyCondition,
  PropertyType
} from '@/modules/property/domain/propertyBasicInformation'
import type {
  AvailabilityType,
  CountryState,
  PropertyCoreInformation
} from '@/modules/property/domain/propertyCoreInformation'
import type { PropertyDocuments } from '@/modules/property/domain/propertyDocuments'
import {
  IndoorExtra,
  OutdoorExtra,
  type PropertyEquipmentInformation
} from '@/modules/property/domain/propertyEquipmentInformation'
import type { PropertyImages } from '@/modules/property/domain/propertyImages'
import type { Document } from '@/modules/shared/domain/document/document'
import {
  PricingPlan,
  type NonPaymentCoverageOption,
  type PropertyPaymentInformation
} from '../domain/propertyPaymentInformation'

export interface PropertyDto {
  id: string
  type: Nullable<string>
  address: {
    floor: Nullable<string>
    street: Nullable<string>
    city: Nullable<string>
    zipCode: Nullable<string>
  }
  size: {
    built: Nullable<number>
    usable: Nullable<number>
  }
  features: {
    numberOfBathrooms: Nullable<number>
    hasParking: Nullable<boolean>
    numberOfBedrooms: Nullable<number>
    hasStorageRoom: Nullable<boolean>
    hasBalcony: Nullable<boolean>
    hasLift: Nullable<boolean>
    hasTerrace: Nullable<boolean>
    hasFurniture: Nullable<boolean>
    equipment: Nullable<string[]>
  }
  houseRules: {
    allowPets: Nullable<boolean>
  }
  condition: Nullable<string>
  rent: {
    amount: number
    isFirstTime: boolean
    availability?: {
      type: string
      from: string
    }
  }
  monthlyPaymentAmount: Nullable<number>
  cadastralReference: Nullable<string>
  status: PropertyStatus
}

export interface PropertyImageDto {
  id: string
  name: string
  mimeType: 'image/jpg' | 'image/jpeg' | 'image/png'
}

export interface PropertyDocumentDto {
  id: string
  name: string
  mimeType: 'image/jpg' | 'image/jpeg' | 'image/png' | 'application/pdf'
  type: 'energy-cert' | 'habitability-cert' | 'property-expense-bill' | 'utility-bill'
}

export interface ServiceBundleDto {
  pricingPlan?: { type: string; insurancePeriodicity?: NonPaymentCoverageOption }
  extras: string[]
}

function dtoToPricingPlan(dto: { type: string }): Nullable<PricingPlan> {
  if (dto.type === 'secured') {
    return PricingPlan.Secured
  } else if (dto.type === 'peace-of-mind') {
    return PricingPlan.PeaceOfMind
  } else if (dto.type === 'basic') {
    return PricingPlan.Basic
  } else if (dto.type === 'comfort') {
    return PricingPlan.Comfort
  } else {
    return null
  }
}

export function toServiceBundleUpdateRequest(property: Property) {
  const extras: string[] = []

  if (property.paymentInformation.hasCommercialization) {
    extras.push('commercialization')
  }
  if (property.images.needsPhotoService) {
    extras.push('property-photos')
  }

  if (property.paymentInformation.plan === null) {
    return { extras }
  }

  let pricingPlanType: string = property.paymentInformation.plan
  if (property.paymentInformation.plan === PricingPlan.PeaceOfMind) {
    pricingPlanType = 'peace-of-mind'
  }

  return {
    pricingPlan: {
      type: pricingPlanType,
      insurancePeriodicity: property.paymentInformation.nonPaymentCoverage ?? undefined
    },
    extras
  }
}

function dtoToPropertyPaymentInformation(dto: Nullable<ServiceBundleDto>): PropertyPaymentInformation {
  return {
    hasCommercialization: dto?.extras.includes('commercialization') ?? null,
    nonPaymentCoverage: dto?.pricingPlan?.insurancePeriodicity ?? null,
    plan: dto?.pricingPlan ? dtoToPricingPlan(dto.pricingPlan) : null
  }
}

function dtoToPropertyCoreInformation(dto: PropertyDto, state: Nullable<CountryState>): PropertyCoreInformation {
  return {
    availabilityDate: dto.rent.availability?.from || null,
    availabilityType: (dto.rent.availability?.type as AvailabilityType) || null,
    builtSize: dto.size.built,
    catastralReference: dto.cadastralReference,
    city: dto.address.city,
    floor: dto.address.floor,
    monthlyPayment: dto.monthlyPaymentAmount,
    street: dto.address.street,
    state,
    usableSize: dto.size.usable,
    rent: dto.rent.amount,
    zipCode: dto.address.zipCode
  }
}

function dtoToPropertyBasicInformation(dto: PropertyDto): PropertyBasicInformation {
  return {
    bathrooms: dto.features.numberOfBathrooms,
    bedrooms: dto.features.numberOfBedrooms,
    pets: dto.houseRules.allowPets,
    propertyCondition: dto.condition as Nullable<PropertyCondition>,
    propertyType: dto.type as Nullable<PropertyType>
  }
}

function dtoToPropertyEquipmentInformation(dto: PropertyDto): PropertyEquipmentInformation {
  const outdoorExtras: OutdoorExtra[] = []
  if (dto.features.hasBalcony) outdoorExtras.push(OutdoorExtra.Balcony)
  if (dto.features.hasLift) outdoorExtras.push(OutdoorExtra.Lift)
  if (dto.features.hasParking) outdoorExtras.push(OutdoorExtra.PrivateGarage)
  if (dto.features.hasStorageRoom) outdoorExtras.push(OutdoorExtra.StorageRoom)
  if (dto.features.hasTerrace) outdoorExtras.push(OutdoorExtra.Terrace)

  return {
    furniture: dto.features.hasFurniture,
    indoorExtras: dto.features.equipment === null ? [] : (dto.features.equipment as IndoorExtra[]),
    outdoorExtras
  }
}

function dtoToPropertyImages(
  propertyId: string,
  imagesDtos: PropertyImageDto[],
  serviceBundleDto: Nullable<ServiceBundleDto>
): PropertyImages {
  const extensions = {
    'image/jpg': 'jpg',
    'image/jpeg': 'jpeg',
    'image/png': 'png'
  }
  return {
    needsPhotoService: serviceBundleDto?.extras.includes('property-photos') ?? false,
    files: imagesDtos.map((dto) => ({
      id: dto.id,
      name: dto.name,
      source: null,
      uri: `${import.meta.env.VITE_FILE_STORAGE_BASE_URL}/properties/${propertyId}/images/${dto.id}.${extensions[dto.mimeType]}`
    }))
  }
}

function dtoToDocument(dto: PropertyDocumentDto): Document {
  return { id: dto.id, name: dto.name, source: null, uri: null }
}

function dtoToPropertyDocuments(dtos: PropertyDocumentDto[]): PropertyDocuments {
  return {
    energyCertificate: dtos.filter(({ type }) => type === 'energy-cert').map(dtoToDocument),
    habitabilityCertificate: dtos.filter(({ type }) => type === 'habitability-cert').map(dtoToDocument),
    propertyExpenses: dtos.filter(({ type }) => type === 'property-expense-bill').map(dtoToDocument),
    utilityBill: dtos.filter(({ type }) => type === 'utility-bill').map(dtoToDocument)
  }
}

export function fromDto(
  dto: PropertyDto,
  completed: boolean,
  imagesDtos: PropertyImageDto[],
  documentsDtos: PropertyDocumentDto[],
  serviceBundleDto: Nullable<ServiceBundleDto>,
  state: Nullable<CountryState>
): Property {
  return {
    basicInformation: dtoToPropertyBasicInformation(dto),
    coreInformation: dtoToPropertyCoreInformation(dto, state),
    equipmentInformation: dtoToPropertyEquipmentInformation(dto),
    documents: dtoToPropertyDocuments(documentsDtos),
    id: dto.id,
    completed,
    images: dtoToPropertyImages(dto.id, imagesDtos, serviceBundleDto),
    paymentInformation: dtoToPropertyPaymentInformation(serviceBundleDto),
    status: dto.status
  }
}

export function toPropertyUpdateRequest(property: Property) {
  const availability = property.coreInformation.availabilityType
    ? {
        type: property.coreInformation.availabilityType,
        from: property.coreInformation.availabilityDate
      }
    : null

  return {
    id: property.id,
    type: property.basicInformation.propertyType,
    address: {
      floor: property.coreInformation.floor,
      street: property.coreInformation.street,
      city: property.coreInformation.city,
      zipCode: property.coreInformation.zipCode
    },
    size: {
      built: property.coreInformation.builtSize,
      usable: property.coreInformation.usableSize
    },
    features: {
      numberOfBathrooms: property.basicInformation.bathrooms,
      hasParking: property.equipmentInformation.outdoorExtras.includes(OutdoorExtra.PrivateGarage),
      numberOfBedrooms: property.basicInformation.bedrooms,
      hasStorageRoom: property.equipmentInformation.outdoorExtras.includes(OutdoorExtra.StorageRoom),
      hasBalcony: property.equipmentInformation.outdoorExtras.includes(OutdoorExtra.Balcony),
      hasLift: property.equipmentInformation.outdoorExtras.includes(OutdoorExtra.Lift),
      hasTerrace: property.equipmentInformation.outdoorExtras.includes(OutdoorExtra.Terrace),
      hasFurniture: property.equipmentInformation.furniture,
      equipment: property.equipmentInformation.indoorExtras
    },
    houseRules: {
      allowPets: property.basicInformation.pets
    },
    condition: property.basicInformation.propertyCondition,
    rent: {
      amount: property.coreInformation.rent,
      isFirstTime: false,
      availability
    },
    monthlyPaymentAmount: property.coreInformation.monthlyPayment,
    cadastralReference: property.coreInformation.catastralReference
  }
}
