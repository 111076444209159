<template>
  <div class="user-layout">
    <div class="navbar">
      <img class="toggle" :src="icons.menu" @click="opened = true" />
      <div class="flex gap-1 align-center">
        <LanguageSelector />
        <ProfileDropdown :user="user" />
      </div>
    </div>
    <div class="sidebar">
      <RouterLink :to="{ name: 'home' }">
        <img class="logo" :src="images.vivaraLogoDark" />
        <img class="logo-small" :src="images.vivaraLogoSmallDark" />
      </RouterLink>
      <NavigationLinks class="mt-4" :user="user" is-sidebar />
      <div class="tablet-handle" @click="opened = true"></div>
      <PartnerReferralCallToAction v-if="isPartner(user.type)" />
    </div>
    <div class="mobile-sidebar-backdrop" v-if="opened" @click="opened = false"></div>
    <div class="mobile-sidebar" :class="{ opened }">
      <div class="flex justify-end">
        <img class="close" :src="icons.close" @click="opened = false" />
      </div>
      <NavigationLinks class="mt-2" :user="user" />
    </div>
    <div class="main">
      <div class="content"><slot /></div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { LanguageSelector, PartnerReferralCallToAction, ProfileDropdown } from '@/components'
import { icons } from '@/config/assets/icons'
import { images } from '@/config/assets/images'
import { type User, isPartner } from '@/modules/user/domain/user'
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import NavigationLinks from './NavigationLinks.vue'

defineProps<{ user: User }>()

const route = useRoute()

const opened = ref(false)

watch(route, () => (opened.value = false))
</script>
<style lang="sass" scoped>
@use "@/assets/styles/responsive.sass" as *

$navbar-height: 4rem
$sidebar-width: 16rem
$sidebar-width-small: 5rem

@keyframes backdrop-appear
  from
    opacity: 0
  to
    opacity: 1

.user-layout
  display: flex
  flex-direction: column
  background-color: #F9F9F9

  .sidebar
    display: none

  @include md
    height: 100vh
    display: grid
    grid-template-columns: $sidebar-width-small auto
    grid-template-rows: $navbar-height auto
    grid-template-areas: "sidebar navbar" "sidebar content"
    grid-template-columns: $sidebar-width-small auto
    grid-template-rows: $navbar-height auto

    .sidebar
      display: block
      grid-area: sidebar

  @include lg
    grid-template-columns: $sidebar-width auto

  .navbar
    height: $navbar-height
    display: flex
    justify-content: space-between
    align-items: center
    padding: 0rem 1rem
    gap: 1rem
    grid-area: navbar
    background-color: white
    border-bottom: 1px solid #E6E6E6
    box-sizing: border-box
    background-color: white

    .toggle
      cursor: pointer
      padding: 1rem
      margin-left: -1rem

    @include lg
      justify-content: flex-end

      .toggle
        display: none

  .sidebar, .mobile-sidebar
    text-align: center
    padding: 1rem
    background-color: #F6F3A0

    .logo
      display: none

  .sidebar
    position: relative
    box-shadow: 0px 1px 4px 0px #0B0B0B26
    z-index: 10

    a
      position: relative
      z-index: 2

    :deep(.link)
      justify-content: center
      width: 3rem

      span
        display: none

    @include md
      .tablet-handle
        position: absolute
        top: 0
        left: 0
        height: 100%
        width: 100%
        z-index: 1

    @include lg
      .logo
        display: inline-block

      .logo-small
        display: none

      .tablet-handle
        display: none

      :deep(.link)
        justify-content: flex-start
        width: auto

        span
          display: inline

  .mobile-sidebar
    transform: translateX(-100%)
    position: fixed
    z-index: 21
    left: 0
    top: 0
    height: 100vh
    width: $sidebar-width
    transition: transform 0.2s

    &.opened
      transform: translateX(0)

    .close
      cursor: pointer

  .mobile-sidebar-backdrop
    position: fixed
    z-index: 20
    left: 0
    top: 0
    height: 100vh
    width: 100vw
    background-color: rgba(0, 0, 0, 0.5)
    animation: backdrop-appear 0.2s

  .main
    grid-area: content
    height: calc(100vh - #{$navbar-height})
    display: flex
    flex-direction: column

    .content
      flex-grow: 1
      overflow-y: scroll
</style>
