<template>
  <div class="card">
    <div class="timeline">
      <h3 class="mb-2">{{ t('property.details.timeline.title') }}</h3>
      <div>
        <div
          v-for="({ status, icon }, index) in timeline"
          class="status flex gap-1"
          :class="{ active: timelineIndex === index, done: timelineIndex > index }"
        >
          <div class="flex column align-center">
            <div class="icon" :class="status">
              <img :src="timelineIndex > index ? icons.checkWhite : icon" />
            </div>
            <div class="line"></div>
          </div>
          <div class="pb-2">
            <p class="title">{{ t(`property.details.timeline.status.${status}.title`) }}</p>
            <p>{{ t(`property.details.timeline.status.${status}.description`) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { icons } from '@/config/assets/icons'
import { PropertyStatus } from '@/modules/property/domain/propertyStatus'
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { useAnalytics } from '@/hooks/useAnalytics'
import { UserType } from '@/modules/user/domain/user'
import { findCurrentUser } from '@/modules/user/application'

const props = defineProps<{
  status: PropertyStatus
}>()

const { t } = useI18n()
const { track } = useAnalytics()

const trackByUserType = (userType: UserType) => {
  switch (userType) {
    case UserType.Landlord:
      track(AnalyticsEvent.LandlordPropertyDetailStatusTimeline)
      break
    case UserType.Partner:
      track(AnalyticsEvent.PartnerPropertyDetailStatusTimeline)
      break
    default:
      break
  }
}

const timeline = [
  { status: PropertyStatus.Registered, icon: icons.propertyRegistered },
  { status: 'listed', icon: icons.propertyListed },
  { status: PropertyStatus.InVisits, icon: icons.propertyInVisits },
  { status: PropertyStatus.Reserved, icon: icons.propertyReserved },
  { status: PropertyStatus.Rented, icon: icons.propertyRented }
]

const timelineIndex = computed(() => timeline.findIndex((step) => step.status === props.status))

onMounted(async () => {
  const { type } = await findCurrentUser()
  trackByUserType(type)
})
</script>
<style scoped lang="sass">
@use "@/assets/styles/responsive.sass" as *

.timeline
  padding: 0.5rem

  @include md
    padding-bottom: 0rem

.status
  max-width: 30rem

  .icon
    flex-shrink: 0
    width: 2.5rem
    height: 2.5rem
    background-color: #E6E6E6
    border-radius: 1.25rem
    display: flex
    justify-content: center
    align-items: center

  img, p, .line
      opacity: 0.4

  &.active
    .icon
      &.registered
        background-color: #F6F3A0
      &.in-visits
        background-color: #9ABBD2
      &.reserved
        background-color: #FFB99B
      &.rented
        background-color: #F1C5F9

    img, p
      opacity: 1

  &.done
    .icon
      background-color: #0B0B0B

    img, p, .line
      opacity: 1

  .line
    width: 1px
    background-color: #0B0B0B
    height: 100%
    margin-bottom: 0.25rem
    margin-top: 0.25rem

  &:last-child .line
    display: none
</style>
