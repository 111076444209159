<template>
  <div
    class="tooltip-container"
    @mouseenter="isVisible = true"
    @mouseleave="isVisible = false"
    @focusin="isVisible = true"
    @focusout="isVisible = false"
  >
    <div class="tooltip-trigger" tabindex="0">
      <slot name="handle"></slot>
    </div>

    <transition name="tooltip">
      <div v-if="isVisible" class="tooltip-content" :class="position" :style="tooltipStyle">
        <div class="tooltip-arrow"></div>
        <div class="tooltip-inner">
          <slot name="content"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'

const props = defineProps({
  position: {
    type: String,
    default: 'top',
    validator: (value: string) => ['top', 'bottom', 'left', 'right'].includes(value)
  },
  width: {
    type: String,
    default: 'auto'
  }
})

const isVisible = ref(false)

const tooltipStyle = computed(() => ({ width: props.width }))
</script>

<style lang="sass" scoped>
.tooltip-container
  position: relative
  display: inline-block

.tooltip-trigger
  display: flex

.tooltip-content
  position: absolute
  z-index: 9999
  background-color: $grey500
  border: 1px solid #e2e8f0
  border-radius: 0.75rem
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15)
  padding: 0.75rem 1rem
  max-width: 250px
  pointer-events: none

  *
    color: white !important


  &.top
    bottom: calc(100% + 10px)
    left: 50%
    transform: translateX(-50%)

  &.bottom
    top: calc(100% + 10px)
    left: 50%
    transform: translateX(-50%)

  &.left
    right: calc(100% + 10px)
    top: 50%
    transform: translateY(-50%)

  &.right
    left: calc(100% + 10px)
    top: 50%
    transform: translateY(-50%)

.tooltip-arrow
  position: absolute
  width: 8px
  height: 8px
  background-color: $grey500
  border: 1px solid #e2e8f0
  transform: rotate(45deg)

  .top &
    bottom: -5px
    left: calc(50% - 4px)
    border-top: none
    border-left: none

  .bottom &
    top: -5px
    left: calc(50% - 4px)
    border-bottom: none
    border-right: none

  .left &
    top: calc(50% - 4px)
    right: -5px
    border-left: none
    border-bottom: none

  .right &
    top: calc(50% - 4px)
    left: -5px
    border-right: none
    border-top: none

.tooltip-enter-active,
.tooltip-leave-active
  transition: opacity 0.2s ease, transform 0.2s ease

.tooltip-enter-from,
.tooltip-leave-to
  opacity: 0

  &.top
    transform: translate(-50%, -5px)

  &.bottom
    transform: translate(-50%, 5px)

  &.left
    transform: translate(-5px, -50%)

  &.right
    transform: translate(5px, -50%)
</style>
